export const SAMPLE_MENUS = [
  {
    id: 10,
    item_name: 'Admin',
    url: 'admin',
    icon: null,
    parent_menu_item_id: null,
    order_index: null,
    child_menus: [
      {
        id: 4,
        item_name: 'Menu Items',
        icon: '',
        parent_menu_item_id: 10,
        order_index: 0,
        child_menus: [],
        url: '/admin/menu-items',
      },
    ],
  },
  {
    id: 1,
    item_name: 'Dashboard',
    icon: '',
    parent_menu_item_id: null,
    order_index: 0,
    child_menus: [],
    url: '/dashboard',
  },
  {
    id: 2,
    item_name: 'Regions',
    icon: '',
    parent_menu_item_id: null,
    order_index: 0,
    child_menus: [],
    url: '/regions',
  },
  {
    id: 3,
    item_name: 'Locations',
    icon: '',
    parent_menu_item_id: null,
    order_index: 0,
    child_menus: [],
    url: '/regions',
  },
  {
    id: 6,
    item_name: 'Archived',
    icon: '',
    parent_menu_item_id: null,
    order_index: 0,
    child_menus: [],
    url: '/archived',
  },
  {
    id: 7,
    item_name: 'Settings',
    icon: '',
    parent_menu_item_id: null,
    order_index: 0,
    child_menus: [],
    url: '/settings',
  },
  {
    id: 8,
    item_name: 'Workflow',
    icon: '',
    parent_menu_item_id: null,
    order_index: 0,
    child_menus: [],
    url: '/workflow',
  },
  {
    id: 9,
    item_name: '- Incident Investigation',
    icon: '',
    parent_menu_item_id: null,
    order_index: 0,
    child_menus: [],
    url: '/executions/incident-investigation',
  },
  {
    id: 10,
    item_name: '- Example Test',
    icon: '',
    parent_menu_item_id: null,
    order_index: 0,
    child_menus: [],
    url: '/executions/example-test',
  },
  {
    id: 11,
    item_name: 'Repository',
    icon: '',
    parent_menu_item_id: null,
    order_index: 0,
    child_menus: [],
    url: '/repository',
  },
  {
    id: 12,
    item_name: 'Companies',
    icon: '',
    parent_menu_item_id: null,
    order_index: 0,
    child_menus: [],
    url: '/companies',
  },
];
