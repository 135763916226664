import classes from './Card.module.scss';

const CardFooter = ({ left, leftIcon, right }) => {
  return (
    <div className={classes.footer}>
      {!!left && (
        <span className={classes.footerLeft}>
          {leftIcon}
          <span>{left}</span>
        </span>
      )}
      {right && <span className={classes.footerRight}>{right}</span>}
    </div>
  );
};

export default CardFooter;
