import React from 'react';
import { ReactComponent as ArchivedSvg } from 'src/assets/icons/Nav/Archived.svg';
import { ReactComponent as ArchivedActiveSvg } from 'src/assets/icons/Nav/Archived_Active.svg';
import { ReactComponent as DashboardSvg } from 'src/assets/icons/Nav/Dashboard.svg';
import { ReactComponent as DashboardActiveSvg } from 'src/assets/icons/Nav/Dashboard_Active.svg';
import { ReactComponent as RegionsSvg } from 'src/assets/icons/Nav/Regions.svg';
import { ReactComponent as RegionsActiveSvg } from 'src/assets/icons/Nav/Regions_Active.svg';
import { ReactComponent as SettingsSvg } from 'src/assets/icons/Nav/Settings1.svg';
import { ReactComponent as SettingsActiveSvg } from 'src/assets/icons/Nav/Settings_Active.svg';

export const REGION_MENU_NAME = 'Regions';
export const LOCATION_MENU_NAME = 'Locations';
export const MENU_ACTION_KEYS = {
  VIEW: 'view',
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};
export const MENU_ICON_OPTIONS = [
  {
    value: 'dashboard',
    label: (
      <>
        <span>Dashboard</span>
        <DashboardSvg />
      </>
    ),
  },
  {
    value: 'setting',
    label: (
      <>
        <span>Setting</span>
        <SettingsSvg />
      </>
    ),
  },
  {
    value: 'archived',
    label: (
      <>
        <span>Archived</span>
        <ArchivedSvg />
      </>
    ),
  },
  {
    value: 'region',
    label: (
      <>
        <span>Regions</span>
        <RegionsSvg />
      </>
    ),
  },
];

const MENU_ITEMS = [
  {
    id: 2,
    name: 'Regions',
    actions: ['view', 'edit', 'delete'],
  },
];
const MENU_ROLES = [
  {
    id: 1,
    name: 'Role 1',
    menu_items: MENU_ITEMS,
  },
  {
    id: 2,
    name: 'Role 2',
    menu_items: [],
  },
];
export const MENU_LOCATIONS = [
  {
    id: 1,
    name: 'Location 1',
    roles: MENU_ROLES,
  },
  {
    id: 2,
    name: 'Location 2',
    roles: [MENU_ROLES[1]],
  },
];
const MENU_REGIONS = [
  {
    id: 1,
    name: 'Region 1',
    locations: MENU_LOCATIONS,
  },
];
const MENU_COMPANIES = [
  {
    id: 1,
    name: 'Company 1',
    regions: MENU_REGIONS,
  },
];
export const DYNAMIC_MENUS = {
  companies: MENU_COMPANIES,
};
const MENU_DROPDOWN = [];
MENU_COMPANIES.forEach((company) => {
  company.regions.forEach((region) => {
    region.locations.forEach((location) => {
      MENU_DROPDOWN.push({
        value: `${company.id}_${region.id}_${location.id}`,
        label: `${company.name} > ${region.name} > ${location.name}`,
      });
    });
  });
});

export { MENU_DROPDOWN };
