/* eslint-disable no-restricted-imports */
// Styles
import 'react-big-calendar-like-google/lib/css/react-big-calendar.css';
// Packages
import moment from 'moment';
import React from 'react';
// Components
import BigCalendar from 'react-big-calendar-like-google';
import EventModal from '../components/modals/EventModal';
// Contexts
import { useEventContext } from '../contexts/EventContext';

let allViews = Object.keys(BigCalendar.Views).map((k) => BigCalendar.Views[k]);

moment.locale('en');
BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

export default function Calender() {
  const { events, handleClickOpen, init } = useEventContext();
  const handleNavigate = async (newDate, view) => {
    // Calculate the visible date range based on the view
    let startDate, endDate;

    if (view === 'month') {
      startDate = moment(newDate).startOf('month').toDate();
      endDate = moment(newDate).endOf('month').toDate();
    } else if (view === 'week') {
      startDate = moment(newDate).startOf('week').toDate();
      endDate = moment(newDate).endOf('week').toDate();
    } else if (view === 'day') {
      startDate = moment(newDate).startOf('day').toDate();
      endDate = moment(newDate).endOf('day').toDate();
    }

    // Make an API call with the date range
    try {
      // const response = await fetch('/api/events', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ startDate, endDate }),
      // });

      // if (response.ok) {
      //   const data = await response.json();
      //   setEvents(data.events); // Update the calendar events
      // } else {
      //   console.error('Failed to fetch events');
      // }
      init({
        mode: 'attention',
        view,
        startDate,
        endDate,
      });
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };
  // const event=events[0]
  // if(!event) return
  // var newObj = {
  //   title: "All Day Event very long title",
  //   bgColor: "#ff7f50",
  //   allDay: true,
  //   start: new Date(Date.parse(event.startDate)),
  //   end:  new Date(Date.parse(event.startDate))
  // };
  // // newObj["_id"] = event._id;
  // // newObj["title"] = event.workflow.information.taskTitle;
  // // newObj["bgColor"] = event.bgColor;
  // // newObj["description"] = event.workflow.information.taskDescription;
  // // // newObj["hours"] = [1]; // event.hours;
  // // newObj["start"] = new Date(Date.parse(event.startDate))
  // //   .toISOString()
  // //   .slice(0, 16);
  // // newObj["end"] = new Date(Date.parse(event.startDate)).toISOString().slice(0, 16);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col p-2">
            <EventModal />
          </div>
        </div>
      </div>
      {/* {JSON.stringify(events, null, 2)} */}
      <BigCalendar
        views={allViews}
        popup
        selectable
        events={events}
        defaultView="month"
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={new Date()}
        onNavigate={handleNavigate}
        onSelectEvent={(event) => {
          // const data = {
          //   _id: event._id,
          //   title: event.title,
          //   description: event.description,
          //   bgColor: event.bgColor,
          //   start: event.start,
          //   end: event.end
          // };
          handleClickOpen(event);
        }}
        onSelectSlot={(slotInfo) => {
          const data = {
            start: slotInfo.start,
            end: slotInfo.end,
          };
          handleClickOpen(data);
        }}
      />
    </div>
  );
}
