import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/tasks';

const initialState = {
  tasks: {
    ...defaultObj,
  },
  startTask: {
    ...defaultObj,
  },
};

const TasksReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_TASKS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          tasks: {
            ...prevState.tasks,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          tasks: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          tasks: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default TasksReducer;
