export const actionCb = (cb, errCb) => (res, err) => {
  if (res) {
    if (cb) cb(res);
  } else {
    if (errCb) errCb(err);
  }
};

export const callAction = async (fn) => {
  try {
    const res = await fn();
    return res;
  } catch (error) {
    return { isSuccess: false, data: {} };
  }
};
