import {
  apiCreateExecution,
  apiDeleteExecution,
  apiGetExecutionDetails,
  apiGetExecutions,
  apiUpdateExecution,
} from 'src/api';
import Types from '../types/execution.js';

export const getExecutions = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_EXECUTIONS,
    asyncCall: () => {
      return apiGetExecutions(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getExecutionDetails = (id, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_EXECUTION_DETAILS,
    asyncCall: () => {
      return apiGetExecutionDetails(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const createExecution = (data, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_EXECUTION,
    asyncCall: () => {
      return apiCreateExecution(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const updateExecution = (data, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.UPDATE_EXECUTION,
    asyncCall: () => {
      return apiUpdateExecution(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const deleteExecution = (id, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.DELETE_EXECUTION,
    asyncCall: () => {
      return apiDeleteExecution(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
