import { apiDeleteMenu } from 'src/api';
import * as menuActions from 'src/store/actions/menu';
import { useReducerData, useStoreActions } from 'src/store/hooks';
import { callAction } from 'src/utils/actions';

export const useIndexData = () => {
  return {
    menusData: useReducerData('menu', 'menus.data', {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...menuActions,
  });
};

export const onDeleteMenuItem = async (id) => {
  const res = await callAction(() => {
    return apiDeleteMenu(id);
  });
  return res;
};
