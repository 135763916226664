import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/shared';

const initialState = {
  scopeFilterLocations: {
    ...defaultObj,
  },
};

const SharedReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_UPDATE_SCOPE_FILTER_LOCATIONS: {
      return {
        ...state,
        scopeFilterLocations: {
          ...state.scopeFilterLocations,
          data: { ...payload },
        },
      };
    }
    default:
      return state;
  }
};

export default SharedReducer;
