import Request from './request';

export const apiGetRegions = async (params) => {
  return Request.call({
    url: '/regions',
    method: 'GET',
    params,
  });
};
export const apiGetRegionDetails = async (id) => {
  return Request.call({
    url: `/regions/${id}`,
    method: 'GET',
  });
};
export const apiCreateRegion = async (data) => {
  return Request.call({
    url: '/regions',
    method: 'POST',
    data,
  });
};
export const apiUpdateRegion = async (data) => {
  const { id, ...other } = data;
  return Request.call({
    url: `/regions/${id}`,
    method: 'PUT',
    data: other,
  });
};
export const apiDeleteRegion = async (id) => {
  return Request.call({
    url: `/regions/${id}`,
    method: 'DELETE',
    data: {},
  });
};
