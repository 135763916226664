import cn from 'classnames';
import { useRef, useState } from 'react';
import { Popover } from 'reactstrap';
import useClickOutside from 'src/helper/hooks/useClickOutside';
import classes from './Dropdown.module.scss';

const Dropdown = ({
  className,
  children,
  items,
  overlayClassName,
  placement,
}) => {
  const tRef = useRef(null);
  const popoverRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen((open) => !open);
  };
  const tItems = items || [];
  useClickOutside(popoverRef, () => {
    setOpen(false);
  });

  return (
    <>
      <button className={cn(classes.wrapper, className)} ref={tRef}>
        {children}
      </button>
      <Popover
        placement={placement || 'bottom'}
        target={tRef}
        isOpen={isOpen}
        toggle={toggle}
        innerRef={popoverRef}
        className={cn(overlayClassName, {
          [classes.hideDropdown]: tItems.length === 0,
        })}
      >
        <div className={classes.items}>
          {tItems.map((item) => {
            return (
              <div
                key={item.key}
                onClick={() => {
                  if (item.onClick) item.onClick(item);
                  setOpen(false);
                }}
              >
                <span>{item.label}</span>
              </div>
            );
          })}
        </div>
      </Popover>
    </>
  );
};

export default Dropdown;
