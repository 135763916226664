import cn from 'classnames';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { forwardRef, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import { FormFeedback, Label } from 'reactstrap';
import { ReactComponent as TickCircleSvg } from 'src/assets/icons/tick-circle.svg';
import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import Checkbox from 'src/components/FormFields/Checkbox';
import RequiredMark from 'src/components/RequiredMark';
import {
  INPUT_HIGHLIGHT_ERROR_MESSAGE,
  SELECT_CREATE_OPTION,
} from 'src/helper/constants';
import classes from './Select.module.scss';

const MultiValueContainer = (props) => {
  const selectedValue = get(props, 'data.value', '');
  const selectedLabel = get(props, 'data.label', '');
  const selectedValues = get(props, 'selectProps.value') || [];
  const multiLabel = get(props, 'selectProps.multiLabel', '');
  const isFirst = findIndex(selectedValues, { value: selectedValue }) === 0;
  const valuesLength = selectedValues.length;
  if (valuesLength === 1) {
    return <span className={classes.selectedValue}>{selectedLabel}</span>;
  }
  return (
    <span
      className={!isFirst ? classes.hide : ''}
    >{`${valuesLength} ${multiLabel}`}</span>
  );
};
const SingleValue = ({ children, ...props }) => {
  const { data } = props;
  const { obj = {} } = data;
  const { icon } = obj;
  return (
    <components.SingleValue {...props}>
      {children} {icon || ''}
    </components.SingleValue>
  );
};
const MenuList = (props) => {
  const { selectProps } = props || {};
  const hasSelectAll = selectProps?.hasSelectAll;
  const menuListRef = useRef(null);
  useEffect(() => {
    if (menuListRef.current) {
      menuListRef.current.querySelector('div').onscroll = () => {
        // TODO: handle onScroll
      };
    }
  }, [menuListRef]);

  return (
    <div className={classes.menuWithCheckbox} ref={menuListRef}>
      {hasSelectAll && (
        <div
          className={classes.menuSelectAllWrapper}
          onClick={(e) => {
            if (selectProps?.onCheckedAllChange) {
              selectProps.onCheckedAllChange(!selectProps?.checkedAll);
            }
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Checkbox
            variant="primary"
            id="select-select-all-checkbox"
            className={classes.checkbox}
            label={selectProps?.checkedAll ? 'Deselect All' : 'Select All'}
            checked={!!selectProps?.checkedAll}
            onChange={() => {}}
          />
        </div>
      )}
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </div>
  );
};
const OptionSelectedWithCheck = (props) => {
  const { label } = props;
  return (
    <components.Option {...props}>
      {props.isSelected ? <TickCircleSvg /> : ''}
      <span>{label}</span>
    </components.Option>
  );
};
const OptionSelect = (props) => {
  const { label } = props;
  return <components.Option {...props}>{label}</components.Option>;
};
const customStyles = (props) => {
  const { hideArrow, isPagination, error, activeShadow } = props;
  return {
    control: (bases, state) => {
      return {
        ...bases,
        borderColor: error
          ? '#fb6340'
          : state.isFocused
          ? '#000000cc'
          : '#0003',
        boxShadow:
          state.isFocused && activeShadow
            ? '0px 0px 0px 2px #09624233'
            : 'none !important',
        minHeight: '44px',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#09090B',
        fontWeight: 400,
        background:
          state.isDisabled && isPagination ? 'transparent' : bases.background,
        '&:hover': {
          borderColor: error
            ? '#fb6340'
            : state.isFocused
            ? '#000000cc'
            : '#0003',
        },
        border: isPagination ? '0' : undefined,
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        cursor: state.isDisabled ? 'default' : 'pointer',
        background: state.isFocused || state.isSelected ? '#E6EFE8' : undefined,
        fontWeight: state?.data?.value === SELECT_CREATE_OPTION ? 400 : 500,
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '12px 16px',
        fontSize: '14px',
        '&:hover': {
          background: '#E6EFE8',
        },
      };
    },
    menu: (provided) => {
      return {
        ...provided,
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        padding: 0,
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        '& > svg': {
          marginLeft: '6px',
          position: 'relative',
          top: '-2px',
        },
      };
    },
    noOptionsMessage: (bases) => {
      return {
        ...bases,
        '&:before': {
          display: 'none !important',
        },
        '&:after': {
          display: 'none !important',
        },
      };
    },
    clearIndicator: (bases) => {
      return {
        ...bases,
        cursor: 'pointer',
      };
    },
    dropdownIndicator: (bases) => {
      return {
        ...bases,
        display: hideArrow ? 'none' : undefined,
        padding: isPagination ? '0' : '8px',
      };
    },
    indicatorSeparator: () => ({ display: 'none' }),
    menuPortal: (base) => ({ ...base, zIndex: 1060 }),
  };
};

const ReSelect = forwardRef((props, ref) => {
  const {
    isCreate,
    isValueHasIcon,
    selectedWithCheck,
    label,
    className,
    value,
    options,
    error,
    required,
    ...other
  } = props;
  let tValue = value;
  const isValidValue = other.isMulti
    ? !value || (isArray(value) && value.every((obj) => !!obj?.value))
    : !value || !!value?.value;
  if (!isValidValue) {
    tValue = options.find((obj) => obj.value === value);
  }
  let selectComponents = {
    MultiValueContainer,
    MenuList,
    Option: OptionSelect,
  };
  if (isValueHasIcon) {
    selectComponents = {
      ...selectComponents,
      SingleValue,
    };
  }
  if (selectedWithCheck) {
    selectComponents = {
      ...selectComponents,
      Option: OptionSelectedWithCheck,
    };
  }
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isError]: !!error,
        },
        className
      )}
      key={other.key}
    >
      {!!label && (
        <Label className={classes.label}>
          {label}
          {required && <RequiredMark />}
        </Label>
      )}
      {isCreate ? (
        <Creatable
          ref={ref}
          hideSelectedOptions={false}
          menuPosition="fixed"
          className={classes.wrapper}
          styles={customStyles(props)}
          components={selectComponents}
          value={tValue}
          options={options}
          menuPortalTarget={document.body}
          {...other}
        />
      ) : (
        <Select
          ref={ref}
          hideSelectedOptions={false}
          // closeMenuOnSelect={false}
          menuPosition="fixed"
          className={classes.wrapper}
          styles={customStyles(props)}
          components={selectComponents}
          value={tValue}
          options={options}
          menuPortalTarget={document.body}
          {...other}
        />
      )}
      {error && error !== INPUT_HIGHLIGHT_ERROR_MESSAGE && (
        <FormFeedback className={classes.error}>
          <WarningSvg />
          <span>{error}</span>
        </FormFeedback>
      )}
    </div>
  );
});

export default ReSelect;
