export const TABLE_PAGINATION_SIZE = [
  {
    value: 10,
    label: 'View 10 items',
  },
  {
    value: 20,
    label: 'View 20 items',
  },
  {
    value: 50,
    label: 'View 50 items',
  },
];
