import * as repositoryActions from 'src/store/actions/repository';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    documents: useReducerData('repository', 'documents.data', {}),
    repositoryLoading: useReducerData(
      'repository',
      'repository.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...repositoryActions,
  });
};
