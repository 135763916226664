import cn from 'classnames';
import PageWrapper from 'src/components/Page/PageWrapper';
import classes from './Wrapper.module.scss';

const GenericCreateWrapper = ({ className, children }) => {
  return (
    <PageWrapper className={cn(classes.wrapper, className)}>
      {children}
    </PageWrapper>
  );
};

export default GenericCreateWrapper;
