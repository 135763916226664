import React, { useEffect, useState } from 'react';

const PDFViewer = ({ documentId, slug }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/repository/document/${documentId}/${slug}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/pdf',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch PDF');
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setPdfUrl(blobUrl);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPDF();
  }, [documentId, slug]);

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      {pdfUrl ? (
        <iframe
          src={pdfUrl}
          title="PDF Viewer"
          style={{ width: '100%', height: '100%', border: 'none' }}
        ></iframe>
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default PDFViewer;
