import cn from 'classnames';
import Button from 'src/components/Button';
import classes from './ActionButtons.module.scss';

const GenericActionButtons = ({ className }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <Button variant="primary" type="button" onClick={() => {}}>
        Button 1
      </Button>
      <Button variant="primary" type="button" onClick={() => {}}>
        Button 2
      </Button>
    </div>
  );
};

export default GenericActionButtons;
