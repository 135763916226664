import { apiGetMenuDetails, apiGetMenus, apiLoadMenusData } from 'src/api';
import Types from '../types/menu';
import { syncSetLoginMenu } from './auth';

export const loadMenusData = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.LOAD_MENUS_DATA,
    asyncCall: () => {
      return apiLoadMenusData(params);
    },
    afterSuccessCall: (dispatch, response) => {
      if (cb) cb(response);
      dispatch(syncSetLoginMenu(response?.data?.menus || []));
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getMenus = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_MENUS,
    asyncCall: () => {
      return apiGetMenus(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getAllMenus = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_ALL_MENUS,
    asyncCall: () => {
      return apiGetMenus({
        ...params,
        page: 1,
        per_page: 99999,
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getMenuDetails = (id, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_MENU_DETAILS,
    asyncCall: () => {
      return apiGetMenuDetails(id, {});
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
