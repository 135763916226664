import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
// eslint-disable-next-line no-restricted-imports
import classes from '../../CreateWorkflow.module.scss';
import ScheduleForm from '../ScheduleForm';

const SchedulesForm = ({
  workflow,
  roles,
  informSchedulesChanged,
  workflowInfoChanged,
}) => {
  // Initialize schedules with unique IDs if not already present
  const [schedules, setSchedules] = useState(
    !workflow.schedules || workflow.schedules.length === 0
      ? [{ whenType: 'manual', id: Date.now() }] // Adding a unique ID (timestamp) if not present
      : workflow.schedules.map((schedule, index) => ({
          ...schedule,
          id: schedule.id || Date.now() + index, // Ensures each schedule has a unique ID
        }))
  );
  const schedulesRef = useRef(schedules);

  useEffect(() => {
    schedulesRef.current = schedules;
  }, [schedules]);

  useEffect(() => {
    const newSchedules =
      !workflow.schedules || workflow.schedules.length === 0
        ? [{ whenType: 'manual', id: Date.now() }] // Adding a unique ID (timestamp) if not present
        : workflow.schedules.map((schedule, index) => ({
            ...schedule,
            id: schedule.id || Date.now() + index, // Ensures each schedule has a unique ID
          }));
    schedulesRef.current = newSchedules;
    setSchedules(newSchedules);
    setValue('scheduleDescription', workflow?.information?.scheduleDescription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow]);

  const formSchema = yup.object().shape({
    scheduleDescription: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
    address: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  });

  const defaultValues = {
    scheduleDescription: workflow?.information?.scheduleDescription,
  };
  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      ...defaultValues,
    },
  });

  const informScheduleChanged = useCallback((schedule, id) => {
    const currentSchedules = schedulesRef.current;
    const newSchedules = currentSchedules.map(
      (sch) => (sch.id === id ? schedule : sch) // Replace the schedule with matching ID
    );
    if (JSON.stringify(newSchedules) !== JSON.stringify(currentSchedules)) {
      setSchedules(newSchedules);
      informSchedulesChanged(newSchedules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id) => {
    const newSchedules = schedules.filter((schedule) => schedule.id !== id); // Delete the schedule with matching ID
    setSchedules(newSchedules);
  };

  if (!workflow) return null;

  return (
    <>
      <br />
      <div>
        <FormGroup>
          <FormInput
            label="Workflow Schedule Description"
            name="scheduleDescription"
            placeholder="Short description of the workflow schedule, e.g. Executed every 6 month by every technitian"
            control={control}
            type="textarea"
            radius="md"
            variant="primary"
            fontSize="sm"
            className={classes.descriptionInput}
            onChange={(w) =>
              workflowInfoChanged('scheduleDescription', w.target.value)
            }
          />
        </FormGroup>
      </div>

      {schedules.map((schedule) => (
        <div key={schedule.id}>
          {' '}
          {/* Use unique `id` as the key */}
          <ScheduleForm
            schedule={schedule}
            informScheduleChanged={(schedule) =>
              informScheduleChanged(schedule, schedule.id)
            }
            roles={roles}
          />
          {schedule.id !== schedules[0].id && ( // Ensure only the first item doesn't show delete button
            <button
              onClick={() => handleDelete(schedule.id)} // Delete using the schedule's unique `id`
            >
              Delete
            </button>
          )}
        </div>
      ))}
      <br />
      <button
        onClick={
          () =>
            setSchedules([...schedules, { whenType: 'manual', id: Date.now() }]) // Add a new schedule with a unique ID
        }
      >
        Add another schedule
      </button>
    </>
  );
};

export default SchedulesForm;
