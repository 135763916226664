import cn from 'classnames';
import Button from 'src/components/Button';
import EmergencyContact from './Contact';
import classes from './Emergency.module.scss';
import EmergencyPlan from './Plan';

const Emergency = ({ className, title }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span>{title}</span>
        <div>
          <Button
            variant="primary"
            onClick={() => {}}
            fontSize="sm"
            size="medium"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.contacts}>
          <EmergencyContact
            title="Alarm Company"
            items={[
              {
                id: 1,
                label: 'Point of Contact:',
                value: 'XXXXX',
              },
              {
                id: 2,
                label: 'Phone Number:',
                value: '1-XXX-XXX-XXXX',
              },
              {
                id: 3,
                label: 'Website:',
                value: '',
              },
              {
                id: 4,
                label: 'Additional Details',
                value: '',
              },
            ]}
          />
          <EmergencyContact
            title="Ammonia Spill Contacts"
            items={[
              {
                id: 1,
                label: 'Point of Contact:',
                value: 'XXXXX',
              },
              {
                id: 2,
                label: 'Phone Number:',
                value: '1-XXX-XXX-XXXX',
              },
              {
                id: 3,
                label: 'Website:',
                value: '',
              },
              {
                id: 4,
                label: 'Additional Details',
                value: '',
              },
            ]}
          />
          <EmergencyContact
            title="Emergency Contacts"
            items={[
              {
                id: 1,
                label: 'Point of Contact:',
                value: 'XXXXX',
              },
              {
                id: 2,
                label: 'Phone Number:',
                value: '1-XXX-XXX-XXXX',
              },
              {
                id: 3,
                label: 'Website:',
                value: '',
              },
              {
                id: 4,
                label: 'Additional Details',
                value: '',
              },
            ]}
          />
          <EmergencyContact
            title="Representatives"
            items={[
              {
                id: 1,
                label: 'Point of Contact:',
                value: 'XXXXX',
              },
              {
                id: 2,
                label: 'Phone Number:',
                value: '1-XXX-XXX-XXXX',
              },
              {
                id: 3,
                label: 'Website:',
                value: '',
              },
              {
                id: 4,
                label: 'Additional Details',
                value: '',
              },
            ]}
          />
        </div>
        <div className={classes.plans}>
          <EmergencyPlan
            title="Emergency Procedures"
            btnText="Add Procedure"
            items={[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]}
          />
          <EmergencyPlan
            title="Evacuation Plans"
            btnText="Add Plan"
            items={[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]}
          />
        </div>
      </div>
    </div>
  );
};

export default Emergency;
