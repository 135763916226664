import Types from '../types/shared';

export const syncUpdateScopeFilterLocations = (data) => (dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_SCOPE_FILTER_LOCATIONS,
    payload: {
      selectedLocations: data,
    },
  });
};
