import Request from './request';

export const apiLoadMenusData = async (params) => {
  return Request.call({
    url: '/load-menus-data',
    method: 'GET',
    params,
  });
};
export const apiGetMenus = async (params) => {
  return Request.call({
    url: '/menu-items',
    method: 'GET',
    params,
  });
};
export const apiGetMenuDetails = async (id, params) => {
  return Request.call({
    url: `/menu-items/${id}`,
    method: 'GET',
    params,
  });
};
export const apiCreateMenu = async (data) => {
  return Request.call({
    url: '/menu-items',
    method: 'POST',
    data,
  });
};
export const apiUpdateMenu = async (id, data) => {
  return Request.call({
    url: `/menu-items/${id}`,
    method: 'PUT',
    data,
  });
};
export const apiDeleteMenu = async (id) => {
  return Request.call({
    url: `/menu-items/${id}`,
    method: 'DELETE',
    data: {},
  });
};
