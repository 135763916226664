import uniq from 'lodash/uniq';
import { useReducerData } from 'src/store/hooks';

const getLocationMenus = (location) => {
  const menus = [];
  (location?.roles || []).forEach((role) => {
    if (role.menu_items) {
      role.menu_items.forEach((menu) => {
        const foundIndex = menus.findIndex((obj) => obj.id === menu.id);
        if (foundIndex >= 0) {
          menus[foundIndex].actions = uniq([
            ...menus[foundIndex].actions,
            ...menu.actions,
          ]);
        } else {
          menus.push(menu);
        }
      });
    }
  });
  return menus;
};

const useDynamicMenu = () => {
  const menusData = useReducerData('auth', 'login.data.menus_data', []);
  const scopeFilteredMenus = [];
  menusData.forEach((company) => {
    company.regions.forEach((region) => {
      region.locations.forEach((location) => {
        // const menus = getLocationMenus(location);
        scopeFilteredMenus.push({
          value: `${company.id}_${region.id}_${location.id}`,
          label: `${company.name} > ${region.name} > ${location.name}`,
          // menus,
        });
      });
    });
  });

  return { scopeFilteredMenus };
};

export default useDynamicMenu;
