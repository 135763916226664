import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
// import HomeMarkerPng from 'src/assets/img/home_marker.png';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import Input from 'src/components/FormFields/Input';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import { WEEK_DAY, WEEK_DAYS } from 'src/helper/constants/date';
import useRegionOptions from 'src/helper/hooks/useRegionOptions';
import useCurrentPath from 'src/helper/hooks/userCurrentPath';
import { getFullAddress, getGoogleAddressObj } from 'src/utils/address';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams, getRelativePathWithParams } from 'src/utils/routes';
import classes from './CreateLocation.module.scss';
import OperatingHours from './components/OperatingHours';
import { useActions } from './selectorData';
import { getLocationPayload } from './utils';

const formSchema = yup.object().shape({
  name: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  // description: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  // overview: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  address: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  region_id: yup.mixed().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
});

const DEFAULT_LOCATION = {
  lat: 44.295894,
  lng: -114.736846,
};

const CreateLocation = () => {
  const { regionOptions } = useRegionOptions();
  const params = useParams();
  const locationId = String(params.id || '');
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const notificationAlertRef = useRef(null);
  const { getLocationDetails, createLocation, updateLocation } = useActions();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [createLocationLoading, setCreateLocationLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [sourceSearchResult, setSourceSearchResult] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(DEFAULT_LOCATION);
  const [locationAddress, setLocationAddress] = useState('');
  const isEdit = currentPath === '/locations/edit/:id';
  const breadcrumbs = [
    {
      link: `/locations`,
      label: 'Locations',
    },
    {
      label: isEdit ? 'Edit Location' : 'Create Location',
    },
  ];
  const backUrl = urlParams?.from || '/locations';
  const defaultValues = {
    name: '',
    description: '',
    overview: '',
    address: '',
    region_id: urlParams?.['pre-region-id']
      ? Number(urlParams['pre-region-id'])
      : null,
    addressObj: {},
  };
  WEEK_DAYS.map((day) => {
    defaultValues[`${day}_day`] =
      day !== WEEK_DAY.SUNDAY && day !== WEEK_DAY.SATURDAY;
  });
  const { handleSubmit, control, watch, setValue, formState, reset, trigger } =
    useForm({
      resolver: yupResolver(formSchema),
      defaultValues: {
        ...defaultValues,
      },
    });
  const notify = (type, title, description, settings) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings,
    });
  };
  const onSubmit = async (values) => {
    if (!submitLoading) {
      setSubmitLoading(true);
      const tAction = isEdit ? updateLocation : createLocation;
      const tPayload = getLocationPayload(values);
      if (isEdit) {
        tPayload.id = Number(locationId);
      }
      tAction(tPayload, (res) => {
        setSubmitLoading(false);
        if (res) {
          navigate(
            getRelativePathWithParams(backUrl, {
              [isEdit ? 'updateSuccess' : 'createSuccess']: 'true',
            })
          );
        } else {
          notify(
            'error',
            `Error`,
            `Location ${isEdit ? 'updating' : 'creating'} failed`,
            { place: 'tr' }
          );
        }
      });
    }
  };
  const onLoadSource = (autocomplete) => {
    setSourceSearchResult(autocomplete);
  };
  const onSourceChanged = () => {
    if (sourceSearchResult != null) {
      const place = sourceSearchResult.getPlace();
      setCurrentLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setLocationAddress(place.formatted_address);
      setValue('address', place.formatted_address);
      setValue('addressObj', getGoogleAddressObj(place.address_components));
    }
  };

  useEffect(() => {
    if (isEdit && locationId) {
      setDetailsLoading(true);
      getLocationDetails(locationId, (res) => {
        const tLocation = res?.data || {};
        if (tLocation.id) {
          const tFullAddress = getFullAddress(tLocation.address);
          const editObj = {
            name: tLocation.name,
            address: tFullAddress,
            addressObj: tLocation.address,
            region_id: tLocation.region_id,
          };
          reset(editObj);
          setLocationAddress(tFullAddress);
        }
        setDetailsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {detailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <PageTitle
          className={classes.pageTitle}
          subTitle={
            <>
              Locations for categorizing the different physical locations of
              your company. Sub-systems,
              <br />
              equipment and documents are generally tied to a specific location
              and can be added at a later time.
            </>
          }
        >
          {isEdit ? 'Edit Location' : 'Create Location'}
        </PageTitle>
        <form
          role="form"
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.formInner}>
            <div>
              <FormGroup>
                <FormInput
                  label="Location Name"
                  name="name"
                  placeholder="Enter location name"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  autoFocus
                  required
                  activeShadow
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  label="Location High Level Description"
                  name="description"
                  placeholder="Short description of the location that appears on the overview"
                  control={control}
                  type="textarea"
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  className={classes.descriptionInput}
                  // required
                  activeShadow
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  label="Location System Overview"
                  name="overview"
                  placeholder="A detailed description of the location, where it’s located and info about the sub-systems"
                  control={control}
                  type="textarea"
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  className={classes.overviewInput}
                  // required
                  activeShadow
                />
              </FormGroup>
              <div className={classes.locationWrapper}>
                <Autocomplete
                  onPlaceChanged={onSourceChanged}
                  onLoad={onLoadSource}
                >
                  <Input
                    label="Location Address"
                    required
                    name="address"
                    placeholder="Enter location address"
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    value={locationAddress}
                    activeShadow
                    onChange={(e) => {
                      setValue('address', e.target.value);
                      setLocationAddress(e.target.value);
                      trigger('address');
                    }}
                  />
                </Autocomplete>
                <div className={classes.map}>
                  <GoogleMap
                    center={currentLocation}
                    zoom={15}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    options={{
                      zoomControl: true,
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false,
                    }}
                    // onLoad={(map) => setMap(map)}
                  >
                    {/* <Marker position={currentLocation} icon={HomeMarkerPng} /> */}
                    <Marker position={currentLocation} />
                    {/* {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )} */}
                  </GoogleMap>
                </div>
              </div>
            </div>
            <div>
              <OperatingHours
                control={control}
                className={classes.operatingHours}
              />
              <FormGroup>
                <FormSelect
                  options={regionOptions}
                  required
                  label="Region"
                  name="region_id"
                  placeholder="Select region"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  activeShadow
                />
              </FormGroup>
            </div>
          </div>
          <div className={classes.formFooter}>
            <Button
              variant="primary"
              isLink
              type="button"
              onClick={() => {
                navigate(backUrl);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!formState.isValid || detailsLoading}
              loading={submitLoading}
            >
              {isEdit ? 'Edit Location' : 'Create Location'}
            </Button>
          </div>
        </form>
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default CreateLocation;
