import Request from './request';

export const apiLogin = async (data) => {
  return Request.call({
    url: '/login',
    method: 'POST',
    data,
  });
};
export const apiGenerate2FA = async (token) => {
  return Request.call({
    url: '/2fa',
    method: 'GET',
    token,
  });
};
export const apiVerify2FACode = async (data, token) => {
  return Request.call({
    url: '/2fa/verify',
    method: 'POST',
    data,
    token,
  });
};
export const apiForgotPassword = async (data) => {
  return Request.call({
    url: '/forgot-password',
    method: 'POST',
    data,
  });
};
export const apiResetPassword = async (data) => {
  return Request.call({
    url: '/reset-password',
    method: 'POST',
    data,
  });
};
export const apiGetCurrentUser = async () => {
  return Request.call({
    url: '/profile',
    method: 'GET',
  });
};
