import * as tasksActions from 'src/store/actions/tasks';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    tasks: useReducerData('tasks', 'tasks.data', {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...tasksActions,
  });
};
