import React from 'react';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import StatusTag from 'src/components/Tag/Status';

export const getColumns1 = () => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: (val, obj) => 'Sub-system 1',
      sortable: true,
      width: 317,
    },
    {
      key: 'archiveReason',
      label: 'Status',
      render: (val) => <StatusTag value="active" />,
      sortable: true,
    },
    {
      key: 'archivedDate',
      label: 'Last Inspection',
      render: (val) => 'Nov 26, 2024',
      sortable: true,
      width: 250,
    },
    {
      key: 'updated',
      label: 'Updated',
      render: (val) => 'Nov 26, 2024',
      sortable: true,
      width: 200,
    },
    {
      key: 'archivedBy',
      label: 'Updated By',
      render: (val) => 'Shane McGonigal',
      sortable: true,
      width: 180,
    },
    {
      key: 'documents',
      label: 'Documents',
      render: (val) => '4 Documents',
      sortable: true,
      width: 180,
    },
    {
      key: 'action',
      width: 72,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'action',
                label: 'Action',
                onClick: () => {},
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};

export const getColumns2 = () => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: (val, obj) => 'Equipment 1',
      sortable: true,
      width: 317,
    },
    {
      key: 'archiveReason',
      label: 'Status',
      render: (val) => <StatusTag value="active" />,
      sortable: true,
    },
    {
      key: 'sub',
      label: 'Sub-System',
      render: (val) => <span>Sub-system 1</span>,
      sortable: true,
    },
    {
      key: 'last',
      label: 'Last Maint.',
      render: (val) => <span>Nov 5, 2024</span>,
      sortable: true,
    },
    {
      key: 'last1',
      label: 'Maint. by',
      render: (val) => <span>Roman K.</span>,
      sortable: true,
    },
    {
      key: 'action',
      width: 72,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'action',
                label: 'Action',
                onClick: () => {},
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};

export const getColumns3 = () => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: (val, obj) => 'Henry Waite',
      sortable: true,
      width: 317,
    },
    {
      key: 'archiveReason',
      label: 'Last session',
      render: (val) => <span>Nov 26, 2024 8:32 AM</span>,
      sortable: true,
    },
    {
      key: 'sub',
      label: 'Role',
      render: (val) => <span>Facility Manager</span>,
    },
    {
      key: 'last',
      label: 'Phone',
      render: (val) => <span>208-000-0000</span>,
    },
    {
      key: 'last1',
      label: 'Email',
      render: (val) => <span>henrywaite@company.com</span>,
    },
    {
      key: 'action',
      width: 72,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'action',
                label: 'Action',
                onClick: () => {},
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};

export const getColumns4 = () => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: (val, obj) => 'Ammonia (NH₃)',
      sortable: true,
      width: 317,
    },
    {
      key: 'archiveReason',
      label: 'CAS Number',
      render: (val) => <span>7664-41-7</span>,
      sortable: true,
    },
    {
      key: 'sub',
      label: 'tier 2 reportable',
      render: (val) => <span>Yes</span>,
      sortable: true,
    },
    {
      key: 'last',
      label: 'LAst updated',
      render: (val) => <span>Oct 2, 2024</span>,
      sortable: true,
    },
    {
      key: 'last1',
      label: 'Quantity',
      render: (val) => <span>1,000 gls</span>,
      sortable: true,
    },
    {
      key: 'action',
      width: 72,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'action',
                label: 'Action',
                onClick: () => {},
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};
