import cn from 'classnames';
import { forwardRef } from 'react';
import { FormFeedback, Label, Input as ReactstrapInput } from 'reactstrap';
import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import RequiredMark from 'src/components/RequiredMark';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import InputGroup from '../InputGroup';
import classes from './Input.module.scss';

const Input = forwardRef(
  (
    {
      className,
      error,
      label,
      radius,
      variant,
      onChange,
      type,
      fontSize,
      required,
      activeShadow,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <InputGroup className={cn('input-group-merge', classes.inputWrapper)}>
          {!!label && (
            <Label className={classes.label}>
              {label}
              {required && <RequiredMark />}
            </Label>
          )}
          <ReactstrapInput
            className={cn(
              classes.wrapper,
              classes[`radius_${radius}`],
              classes[`variant_${variant}`],
              classes[`fontSize_${fontSize}`],
              {
                [classes.hasVariant]: !!variant,
                [classes.activeShadow]: activeShadow,
              },
              className
            )}
            ref={ref}
            invalid={!!error}
            onChange={(e) => {
              if (
                type !== 'number' ||
                (type === 'number' && /^-?\d*(\.\d*)?$/.test(e.target.value))
              ) {
                if (onChange) onChange(e);
              }
            }}
            type={type === 'number' ? 'text' : type}
            {...props}
          />
          {error && error !== INPUT_HIGHLIGHT_ERROR_MESSAGE && (
            <FormFeedback className={classes.error}>
              <WarningSvg />
              <span>{error}</span>
            </FormFeedback>
          )}
        </InputGroup>
      </>
    );
  }
);

export default Input;
