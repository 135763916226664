import classes from './FakeAutocompleteField.module.scss';

const FakeAutocompleteField = () => {
  return (
    <>
      <input type="text" name="username" className={classes.input} />
      <input type="text" name="email" className={classes.input} />
      <input type="password" name="password" className={classes.input} />
    </>
  );
};

export default FakeAutocompleteField;
