import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import {
  INPUT_HIGHLIGHT_ERROR_MESSAGE,
  SELECT_CREATE_OPTION,
} from 'src/helper/constants';
import useOptions from 'src/helper/hooks/useOptions';
import useCurrentPath from 'src/helper/hooks/userCurrentPath';
import CreateLocationModal from 'src/pages/CreateRegion/components/CreateLocationModal';
import SelectLocation from 'src/pages/CreateRegion/components/SelectLocation';
import { actionCb } from 'src/utils/actions';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import classes from './CreateRegion.module.scss';
import { useActions, useIndexData } from './selectorData';
import { getLocationPayload, getRegionPayload } from './utils';

const formSchema = yup.object().shape({
  company: yup.mixed().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  name: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  description: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
});
let addedLocationsCount = 0;

const CreateRegion = () => {
  const {
    createRegion,
    createLocation,
    getAllLocations,
    updateRegion,
    getRegionDetails,
    deleteLocation,
  } = useActions();
  const { allLocations, allLocationsLoading } = useIndexData();
  const { companyOptions } = useOptions();
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const regionId = Number(params.id || 0);
  const notificationAlertRef = useRef(null);
  const [isAddLocation, setIsAddLocation] = useState(false);
  const [createRegionLoading, setCreateRegionLoading] = useState(false);
  const [tempLocations, setTempLocations] = useState([]);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const assignedLocations = allLocations?.data || [];
  const isEdit = currentPath === '/regions/edit/:id';
  const locationOptions = [
    ...(isEdit ? assignedLocations : tempLocations).map((obj) => ({
      value: obj.id,
      label: obj.name,
    })),
    {
      value: SELECT_CREATE_OPTION,
      label: 'Create New Location',
    },
  ];
  const breadcrumbs = [
    {
      link: '/regions',
      label: 'Regions',
    },
    {
      label: 'Create Region',
    },
  ];
  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      company: null,
      name: '',
      description: '',
      locations: [],
      addedLocations: [],
    },
  });
  const wSelectedLocations = watch('locations');
  const selectedLocations = wSelectedLocations || [];
  const wAddedLocations = watch('addedLocations');
  const addedLocations = wAddedLocations || [];
  const notify = (type, title, description, settings) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings,
    });
  };
  const addLocationsToRegion = (tRegionId, locations, cb) => {
    addedLocationsCount = 0;
    locations.map((location) => {
      if (location.actionType === 'removed') {
        deleteLocation(location.id, () => {
          addedLocationsCount = addedLocationsCount + 1;
          if (addedLocationsCount === locations.length && cb) {
            cb();
          }
        });
      } else {
        createLocation(getLocationPayload(location, tRegionId), () => {
          addedLocationsCount = addedLocationsCount + 1;
          if (addedLocationsCount === locations.length && cb) {
            cb();
          }
        });
      }
    });
  };
  const onSubmit = async (values) => {
    if (!createRegionLoading) {
      setCreateRegionLoading(true);
      const tAction = isEdit ? updateRegion : createRegion;
      const tPayload = getRegionPayload(values);
      if (isEdit) {
        tPayload.id = regionId;
      }
      const tAddedLocations = values.addedLocations || [];
      const addedNewLocations = tAddedLocations
        .filter((obj) => !obj.remoteId)
        .map((obj) => ({
          ...obj,
          actionType: 'added',
        }));
      const removedLocations = assignedLocations
        .filter(
          (obj) =>
            tAddedLocations.findIndex((item) => item.remoteId === obj.id) < 0
        )
        .map((obj) => ({
          ...obj,
          actionType: 'removed',
        }));
      tAction(
        tPayload,
        actionCb(
          (res) => {
            setCreateRegionLoading(false);
            navigate(
              `/regions?${isEdit ? 'updateSuccess' : 'createSuccess'}=true`
            );
            // const newRegionId = isEdit ? regionId : res?.data?.id;
            // if (
            //   newRegionId &&
            //   (addedNewLocations.length > 0 || removedLocations.length > 0)
            // ) {
            //   addLocationsToRegion(
            //     newRegionId,
            //     [...addedNewLocations, ...removedLocations],
            //     () => {
            //       setCreateRegionLoading(false);
            //       navigate(
            //         `/regions?${
            //           isEdit ? 'updateSuccess' : 'createSuccess'
            //         }=true`
            //       );
            //     }
            //   );
            // } else {
            //   setCreateRegionLoading(false);
            //   navigate(
            //     `/regions?${isEdit ? 'updateSuccess' : 'createSuccess'}=true`
            //   );
            // }
          },
          () => {
            notify(
              'error',
              'Failed',
              `Region ${isEdit ? 'editing' : 'creating'} failed`
            );
            setCreateRegionLoading(false);
          }
        )
      );
    }
  };
  const handleCreateLocation = async (values) => {
    const newLocation = {
      ...values,
      id: new Date().getTime(),
    };
    setTempLocations((prev) => [...prev, newLocation]);
    setValue('addedLocations', [...addedLocations, newLocation]);
    setIsAddLocation(false);
  };

  useEffect(() => {
    if (isEdit && regionId) {
      setDetailsLoading(true);
      getRegionDetails(regionId, (res) => {
        const tRegion = res?.data || {};
        if (tRegion.id) {
          const editObj = {
            name: tRegion.name,
            description: tRegion.description,
            addedLocations: [],
            company: tRegion.company_id || null,
          };
          reset(editObj);
        }
        setDetailsLoading(false);
        getAllLocations(
          {
            'filters[region_id]': regionId,
          },
          (res) => {
            if (setValue) {
              const tLocations = res?.data || [];
              setValue(
                'addedLocations',
                tLocations.map((obj) => ({
                  ...obj,
                  remoteId: obj.id,
                }))
              );
            }
          }
        );
      });
    }
    if (urlParams?.updateSuccess === 'true') {
      notify('success', 'Success!', `Region updated successfully`, {
        place: 'bc',
      });
      navigate(`/regions/edit/${regionId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {(detailsLoading || allLocationsLoading) && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <PageTitle
          className={classes.pageTitle}
          subTitle={
            <>
              Regions allow you to categorize facilities for more effective
              management. This is an optional level of organization that can be
              toggled from within settings.
            </>
          }
        >
          {isEdit ? 'Edit' : 'Create'} Region
        </PageTitle>
        <form
          role="form"
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.formInner}>
            <FormGroup>
              <FormSelect
                options={companyOptions}
                label="Company"
                name="company"
                placeholder="Select company"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                autoFocus
                activeShadow
                required
              />
            </FormGroup>
            <FormGroup>
              <FormInput
                label="Region Name"
                name="name"
                placeholder="Enter region name"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                activeShadow
                required
              />
            </FormGroup>
            <FormGroup>
              <FormInput
                label="Description"
                name="description"
                placeholder="Enter region description"
                control={control}
                type="textarea"
                radius="md"
                variant="primary"
                fontSize="sm"
                activeShadow
                required
              />
            </FormGroup>
            {isEdit && (
              <SelectLocation
                control={control}
                selectedLocations={selectedLocations}
                addedLocations={addedLocations}
                setValue={setValue}
                onCreateLocation={() => {
                  setIsAddLocation(true);
                }}
                options={locationOptions}
                hideAdd
                onEdit={(val) => {
                  navigate(
                    `/locations/edit/${
                      val?.id
                    }?from=${`/regions/edit/${regionId}`}`
                  );
                }}
              />
            )}
          </div>
          <div className={classes.formFooter}>
            <Button
              variant="primary"
              isLink
              type="button"
              onClick={() => {
                navigate('/regions');
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!formState.isValid || detailsLoading}
              loading={createRegionLoading}
            >
              {isEdit ? 'Save Changes' : 'Create Region'}
            </Button>
          </div>
        </form>
      </PageWrapper>
      {isAddLocation && (
        <CreateLocationModal
          isOpen
          onClose={() => {
            setIsAddLocation(false);
          }}
          onSubmit={handleCreateLocation}
          loading={false}
        />
      )}
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default CreateRegion;
