import TableActionDropdown from 'src/components/Table/ActionDropdown';
import StatusTag from 'src/components/Tag/Status';
import { renderDateColumn, renderNormalColumn } from 'src/utils/table';

export const getColumns = ({
  onView,
  onEdit,
  onDelete,
  getSingleLocationOption,
}) => {
  return [
    {
      key: 'sub_system_name',
      label: 'Name',
      render: renderNormalColumn,
      sortable: true,
      width: 317,
      defaultSort: 'asc',
    },
    {
      key: 'location_id',
      label: 'Location',
      render: (val) => getSingleLocationOption(val)?.label || '-',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Description',
      render: renderNormalColumn,
      sortable: true,
    },
    {
      key: 'status',
      label: 'Status',
      render: (val) => <StatusTag value={val} />,
      sortable: true,
    },
    {
      key: 'created_at',
      label: 'Created At',
      render: renderDateColumn,
      sortable: true,
    },
    {
      key: 'updated_at',
      label: 'Updated At',
      render: renderDateColumn,
      sortable: true,
    },
    {
      key: 'updated_by',
      label: 'Updated by',
      render: (val) => val?.name || '-',
      sortable: true,
    },
    {
      key: 'action',
      width: 72,
      render: (_, obj) => {
        return (
          <TableActionDropdown
            options={[
              {
                key: 'view',
                label: 'View',
                onClick: () => {
                  if (onView) onView(obj);
                },
              },
              {
                key: 'edit',
                label: 'Edit',
                onClick: () => {
                  if (onEdit) onEdit(obj);
                },
              },
              {
                key: 'delete',
                label: 'Delete',
                onClick: () => {
                  if (onDelete) onDelete(obj);
                },
              },
            ]}
          />
        );
      },
    },
  ];
};
