import Request from './request';

export const apiGetLocations = async (params) => {
  return Request.call({
    url: '/locations',
    method: 'GET',
    params,
  });
};
export const apiGetLocationDetails = async (id) => {
  return Request.call({
    url: `/locations/${id}`,
    method: 'GET',
  });
};
export const apiCreateLocation = async (data) => {
  return Request.call({
    url: '/locations',
    method: 'POST',
    data,
  });
};
export const apiUpdateLocation = async (data) => {
  const { id, ...other } = data;
  return Request.call({
    url: `/locations/${id}`,
    method: 'PUT',
    data: other,
  });
};
export const apiDeleteLocation = async (id) => {
  return Request.call({
    url: `/locations/${id}`,
    method: 'DELETE',
    data: {},
  });
};
