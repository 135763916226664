import {
  apiCreateWorkflow,
  apiDeleteWorkflow,
  apiGetWorkflowDetails,
  apiGetWorkflows,
  apiGetWorkflowsDetails,
} from 'src/api';
import Types from '../types/workflow.js';

export const getWorkflows = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_WORKFLOWS,
    asyncCall: () => {
      return apiGetWorkflows(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getWorkflowDetails = (id, locationId, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_WORKFLOW_DETAILS,
    asyncCall: () => {
      return apiGetWorkflowDetails(id, locationId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getWorkflowsDetails = (id, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_WORKFLOW_DETAILS,
    asyncCall: () => {
      return apiGetWorkflowsDetails(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const createWorkflow = (data, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_WORKFLOW,
    asyncCall: () => {
      return apiCreateWorkflow(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const updateWorkflow = (data, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.UPDATE_WORKFLOW,
    asyncCall: () => {
      return apiCreateWorkflow(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const deleteWorkflow = (id, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.DELETE_WORKFLOW,
    asyncCall: () => {
      return apiDeleteWorkflow(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
