import Breadcrumb from 'src/components/Breadcrumb';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';

const Admin = () => {
  const breadcrumbs = [
    {
      link: '/admin',
      label: 'Admin',
    },
  ];
  return (
    <PageWrapper>
      <Breadcrumb items={breadcrumbs} />
      <PageTitle>Admin</PageTitle>
    </PageWrapper>
  );
};

export default Admin;
