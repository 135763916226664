/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import classes from '../../CreateWorkflow.module.scss';

const events = ['recruitment', 'role assignment'];

const ScheduleForm = ({ schedule, informScheduleChanged, roles }) => {
  const roleOptions = roles
    ? roles.map((r) => {
        return { id: r.id, name: r.name };
      })
    : [];
  const defaultValues = { ...schedule };

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(formSchema),
    defaultValues: {
      ...defaultValues,
    },
  });
  // eslint-disable-next-line no-console
  const onSubmit = (data) => console.log(data);

  const periodicStartType = watch('periodicStartType');
  const whenType = watch('whenType');
  const taskBeforeStart = watch('taskBeforeStart');

  const formData = watch(); // Watch for any changes in form data

  useEffect(() => {
    // This will run whenever the form data changes
    // console.log("Form data has changed:", formData);
    informScheduleChanged(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]); // Triggers on any change in form data

  return (
    <form role="form" onSubmit={handleSubmit(onSubmit)}>
      <hr />
      <h2>When</h2>
      <p>When should this workflow be executed?</p>

      <div>
        <label>
          <input
            type="radio"
            name="whenType"
            value="manual"
            {...register('whenType', {
              required: 'Please select when to execute',
            })}
          />{' '}
          Manually started
        </label>
        <div className={classes.infoBox}>
          ℹ️ For workflows that are not tasked automatically by the system, but
          initiated menually
        </div>
        <br />
        <label>
          <input
            type="radio"
            name="whenType"
            value="periodic"
            {...register('whenType')}
          />{' '}
          Periodically
        </label>
        <div className={classes.infoBox}>
          ℹ️ For workflows that are tasked automatically by the system, every
          constant time interval
        </div>
        <br />
        <label>
          <input
            type="radio"
            name="whenType"
            value="onEvent"
            {...register('whenType')}
          />{' '}
          On event
        </label>
        <div className={classes.infoBox}>
          ℹ️ For workflows that are tasked by the system once, following an
          event, like a new role assignment
        </div>
        <br />
        {errors.whenType && <span>{errors.whenType.message}</span>}
      </div>

      {whenType === 'periodic' && (
        <div>
          <hr />
          <div>
            <h4>Period</h4>
            <label htmlFor="interval">Interval:</label>
            <br />
            Every{' '}
            <input
              type="number"
              id="interval"
              placeholder="e.g., 1, 2, 3"
              {...register('interval', {
                required: 'Please specify the interval',
              })}
            />
            {errors.interval && <span>{errors.interval.message}</span>}
            <label htmlFor="unit">&nbsp;Unit:&nbsp;</label>
            <select
              id="unit"
              {...register('unit', { required: 'Please select a unit' })}
            >
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
            {errors.unit && <span>{errors.unit.message}</span>}
          </div>
          <br />
          <h3>Periodic Start Options</h3>
          <div>
            <label>
              <input
                type="radio"
                name="periodicStartType"
                value="setDate"
                {...register('periodicStartType', {
                  required: 'Please select a start type',
                })}
              />{' '}
              From a set date
            </label>
            {periodicStartType === 'setDate' && (
              <>
                <br />
                <div>
                  <label htmlFor="periodicStartDate">Start Date:</label>
                  <br />
                  <input
                    type="date"
                    id="periodicStartDate"
                    {...register('periodicStartDate', {
                      required: 'Start date is required',
                    })}
                  />
                  {errors.periodicStartDate && (
                    <span>{errors.periodicStartDate.message}</span>
                  )}
                </div>
              </>
            )}
          </div>
          <br />

          <label>
            <input
              type="radio"
              name="periodicStartType"
              value="event"
              {...register('periodicStartType')}
            />{' '}
            From an event
          </label>
          {periodicStartType === 'event' && (
            <div>
              <label htmlFor="periodicEvent">Event:</label>
              <br />
              <select
                id="periodicEvent"
                {...register('periodicEvent', {
                  required: 'Please select an event',
                })}
              >
                {events.map((event) => (
                  <option key={event} value={event}>
                    {event}
                  </option>
                ))}
              </select>
              {errors.periodicEvent && (
                <span>{errors.periodicEvent.message}</span>
              )}
            </div>
          )}
        </div>
      )}

      {whenType === 'onEvent' && (
        <div>
          <label htmlFor="onEvent">Event:</label>
          <br />
          <select
            id="onEvent"
            {...register('onEvent', { required: 'Please select an event' })}
          >
            {events.map((event) => (
              <option key={event} value={event}>
                {event}
              </option>
            ))}
          </select>
          {errors.onEvent && <span>{errors.onEvent.message}</span>}
        </div>
      )}

      {whenType !== 'manual' && (
        <>
          {whenType === 'periodic' && (
            <>
              <hr />
              <h2>Who</h2>
              <p>Which role holders should execute this workflow?</p>

              <div>
                <label htmlFor="roles">Select role(s):</label>
                <br />
                <select
                  id="roles"
                  multiple
                  {...register('roles', {
                    required: 'Please select at least one role',
                  })}
                >
                  {roleOptions.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
                {errors.roles && <span>{errors.roles.message}</span>}
              </div>

              <div>
                <br />
                <div>
                  <label>
                    <input
                      type="radio"
                      name="executionCondition"
                      value="all"
                      {...register('executionCondition', {
                        required: 'Please select a condition',
                      })}
                    />{' '}
                    All matching users should execute the workflow
                  </label>
                  <div className={classes.infoBox}>
                    ℹ️ Suitable for courses, tests, and other workflows that
                    should be executed by every employee with one of the
                    designated roles.
                  </div>
                </div>
                <br />
                <div>
                  <label>
                    <input
                      type="radio"
                      name="executionCondition"
                      value="any"
                      {...register('executionCondition')}
                    />{' '}
                    One of the selected roles should execute the workflow
                  </label>
                  <div className={classes.infoBox}>
                    ℹ️ Suitable for audits, incident investigations, and other
                    workflows that should only be performed once, by one of the
                    role holders
                  </div>
                </div>
                {errors.executionCondition && (
                  <span>{errors.executionCondition.message}</span>
                )}
              </div>
            </>
          )}

          <hr />
          <h2>Tasking</h2>
          <p>Specify task scheduling around the start date:</p>

          {whenType === 'periodic' && (
            <>
              <div>
                <label htmlFor="createBeforeStart">
                  Create task before start date (in days):
                </label>
                <br />
                <input
                  type="number"
                  id="createBeforeStart"
                  {...register('createBeforeStart', {
                    required: 'This field is required',
                  })}
                />
                {errors.createBeforeStart && (
                  <span>{errors.createBeforeStart.message}</span>
                )}
              </div>

              <div>
                <label>
                  <input
                    type="checkbox"
                    name="taskBeforeStart"
                    {...register('taskBeforeStart')}
                  />{' '}
                  Allow task completion before start date
                </label>
                {taskBeforeStart && (
                  <div>
                    <label htmlFor="completionBeforeStart">
                      Allowed execution period before start date (in days):
                    </label>
                    <br />
                    <input
                      type="number"
                      id="completionBeforeStart"
                      {...register('completionBeforeStart', {
                        required: 'Specify the period if allowed',
                      })}
                    />
                    {errors.completionBeforeStart && (
                      <span>{errors.completionBeforeStart.message}</span>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          <div>
            <label htmlFor="completionAfterStart">
              Expected completion period after original start date (in days):
            </label>
            <br />
            <input
              type="number"
              id="completionAfterStart"
              {...register('completionAfterStart', {
                required: 'This field is required',
              })}
            />
            {errors.completionAfterStart && (
              <span>{errors.completionAfterStart.message}</span>
            )}
          </div>
        </>
      )}

      <br />
      {/* <button type="submit">Submit</button> */}
    </form>
  );
};

export default ScheduleForm;
