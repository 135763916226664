import * as locationActions from 'src/store/actions/location';
import * as regionActions from 'src/store/actions/region';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    regionDetails: useReducerData('region', 'regionDetails.data', {}),
    regionDetailsLoading: useReducerData(
      'region',
      'regionDetails.loading',
      false
    ),
    regionLocations: useReducerData('region', 'regionLocations.data.data', []),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...regionActions,
    ...locationActions,
  });
};
