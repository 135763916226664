export const getSelectValue = (obj) => {
  if (!obj) return '';
  return typeof obj.value === 'string' || typeof obj.value === 'number'
    ? obj.value
    : typeof obj === 'string' || typeof obj === 'number'
    ? obj
    : '';
};
export const getSelectValues = (arr) => {
  return (arr || []).map((obj) => getSelectValue(obj));
};
