import cn from 'classnames';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import NotificationAlert from 'react-notification-alert';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import Dropdown from 'src/components/Dropdown';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import Table from 'src/components/Table';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import { useActions, useIndexData } from '../ExecuteWorkflow/selectorData';
import TaskDialog from '../Tasks/TaskDialog';
import classes from './Executions.module.scss';

const Executions = ({ className }) => {
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [checkedRows, setCheckedRows] = useState([]);
  const [workflowId, setWorkflowId] = useState(params.id);
  const [executionsLoading, setExecutionsLoading] = useState(false);
  const [executions, setExecutions] = useState();
  const { availableMenus, selectedLocationIds } = useContext(MenuContext);
  const [showTaskDialog, setShowTaskDialog] = useState(false);

  const { getExecutions } = useActions();
  // const { getExecutions } = useActions();
  // const { executions } = useIndexData();
  const breadcrumbs = [
    {
      link: '/executions',
      label: 'Executions',
    },
    {
      label: workflowId,
    },
  ];

  useEffect(() => {
    if (workflowId) {
      setExecutionsLoading(true);
      getExecutions(workflowId, (res) => {
        const tExecutions = res.result || [];
        if (tExecutions) {
          setExecutions(tExecutions);
        }
        setExecutionsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowId]);

  useEffect(() => {
    setWorkflowId(location.pathname.split('/').pop());
  }, [location.pathname]);

  const getColumns = () => {
    return [
      {
        key: 'status',
        label: 'Status',
        render: (val) => val,
        sortable: true,
      },
      {
        key: 'startedAt',
        label: 'Start Date',
        render: (val) => val && moment(val).format('MM/DD/yyyy'),
        sortable: true,
        width: 200,
      },
      {
        key: 'createdAt',
        label: 'Created Date',
        render: (val) => moment(val).format('MM/DD/yyyy'),
        sortable: true,
        width: 200,
      },
      // {
      //   key: 'updatedAt',
      //   label: 'Update Date',
      //   render: (val) => val,
      //   sortable: true,
      //   width: 200,
      // },
      {
        key: 'startedBy',
        label: 'User',
        render: (val, obj) => val, //JSON.stringify(obj),
        sortable: true,
        width: 180,
      },
      {
        key: '_id',
        label: '',
        render: (val, obj) => {
          return (
            <span className={classes.executionsColInner}>
              <Button
                className={classes.executionsColInner}
                variant={'default'}
                onClick={() =>
                  navigate(`/workflows/execute/${workflowId}/${val}`)
                }
              >
                ▶️ Open
              </Button>
              {/* <span>{val}</span> */}
            </span>
          );
        },
        sortable: false,
        width: 317,
      },
      {
        key: 'action',
        width: 72,
        render: (_, obj) => {
          return (
            <Dropdown
              items={[
                {
                  key: 'review',
                  label: 'Review',
                },
                {
                  key: 'delete',
                  label: 'Delete',
                },
              ]}
            >
              <MoreSvg />
            </Dropdown>
          );
        },
      },
    ];
  };

  const columns = getColumns();
  const data = executions || []; // [{ key: 1, id: 1 }];

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {executionsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <div className={classes.executionss}>
          {/* {JSON.stringify(executions)} - {workflowId} */}
          <Button
            className={classes.actionButton}
            variant="primary"
            type="button"
            onClick={() => {
              navigate('/workflows/edit/' + workflowId);
            }}
          >
            Edit Workflow
          </Button>
          <Button
            className={classes.actionButton}
            variant="primary"
            type="button"
            disabled={selectedLocationIds.length > 1}
            onClick={() => {
              navigate('/workflows/execute/' + workflowId);
            }}
          >
            Execute Workflow
          </Button>
          <Button
            className={classes.actionButton}
            variant="primary"
            type="button"
            // disabled={selectedLocationIds.length > 1}
            onClick={() => {
              setShowTaskDialog(true);
            }}
          >
            Task Workflow
          </Button>
          <div className={cn(classes.wrapper, className)}>
            <br />
            <br />
            <Table
              columns={columns}
              data={data}
              checkable={{
                checkedRows,
                onChange: (cRows) => {
                  setCheckedRows(cRows);
                },
              }}
            />
          </div>
          {/* <Flow /> */}
        </div>
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {!!showTaskDialog && (
        <TaskDialog
          workflowId={workflowId}
          onClose={() => setShowTaskDialog(false)}
          formType={'add'}
        />
      )}
    </>
  );
};

export default Executions;
