import cn from 'classnames';
import { forwardRef } from 'react';
import { FormFeedback, Label, Input as ReactstrapInput } from 'reactstrap';
import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import RequiredMark from 'src/components/RequiredMark';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import InputGroup from '../InputGroup';
import classes from './Select.module.scss';

const SelectMultiple = forwardRef(
  (
    {
      className,
      error,
      label,
      radius,
      variant,
      onChange,
      type,
      fontSize,
      required,
      options,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <InputGroup
          className={cn('input-group-merge', classes.selectMultipleWrapper)}
        >
          {!!label && (
            <Label className={classes.label}>
              {label}
              {required && <RequiredMark />}
            </Label>
          )}
          <ReactstrapInput
            className={cn(
              classes.selectMultipleInputWrapper,
              classes[`radius_${radius}`],
              classes[`variant_${variant}`],
              classes[`fontSize_${fontSize}`],
              {
                [classes.hasVariant]: !!variant,
              },
              className
            )}
            ref={ref}
            invalid={!!error}
            {...props}
            onChange={(e) => {
              const selectedOptions = Array.from(e.target.selectedOptions).map(
                (option) => option.value
              );
              if (onChange) onChange(selectedOptions);
            }}
            multiple
            type="select"
          >
            {(options || []).map((option) => {
              return (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              );
            })}
          </ReactstrapInput>
          {error && error !== INPUT_HIGHLIGHT_ERROR_MESSAGE && (
            <FormFeedback className={classes.error}>
              <WarningSvg />
              <span>{error}</span>
            </FormFeedback>
          )}
        </InputGroup>
      </>
    );
  }
);

export default SelectMultiple;
