export const getLocationPayload = (values, regionId) => {
  return {
    name: values?.name || '',
    region_id: regionId || '',
    address: {
      address_1: values?.address_1 || '',
      address_2: values?.address_2 || '',
      city: values?.city || '',
      state: values?.state || '',
      zip: values?.zipCode || '',
    },
  };
};
export const getRegionPayload = (values) => {
  return {
    company_id: values?.company?.value || values.company || '',
    name: values?.name || '',
    description: values?.description || '',
    // location_ids: (values?.addedLocations || []).map((obj) => obj.value),
  };
};
