import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import * as resourceActions from 'src/store/actions/resource';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    usersData: useReducerData(
      'resource',
      `resources.data.${RESOURCE_DATA_KEY.USERS}`,
      {}
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...resourceActions,
  });
};
