/* eslint-disable no-restricted-imports */
import { Button } from '@material-ui/core';
import { FaCalendarPlus } from 'react-icons/fa';
import { useEventContext } from '../../contexts/EventContext';
import UpdateEventForm from '../forms/UpdateEventForm';

export default function EventModal() {
  const { open, handleClickOpen, handleClose, formType, setFormType } =
    useEventContext();
  const chooseFormType = (props) => {
    if (props.type === 'add') {
      return <UpdateEventForm />;
    } else if (props.type === 'show') {
      return <UpdateEventForm />;
    } else if (props.type === 'update') {
      return <UpdateEventForm />;
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={() => {
          handleClickOpen();
          setFormType('add');
        }}
      >
        <FaCalendarPlus />
      </Button>

      {chooseFormType({ type: formType })}
    </div>
  );
}
