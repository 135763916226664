import { useRef, useState } from 'react';
import NotificationAlert from 'react-notification-alert';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import classes from './Generic.module.scss';
import GenericActionButtons from './components/ActionButtons';
import GenericContent from './components/Content';
import GenericTable from './components/Table';
import { getColumns } from './constants';

const Generic = () => {
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [checkedRows, setCheckedRows] = useState([]);
  const loading = false;
  const breadcrumbs = [
    {
      label: 'Generic',
    },
  ];
  const handleView = () => {
    navigate('/generic/create?id=1&view=true');
  };
  const handleEdit = () => {
    navigate('/generic/create?id=1');
  };

  const columns = getColumns({ onView: handleView, onEdit: handleEdit });
  const data = [{ key: 1, id: 1 }];

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {loading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <PageTitle subTitle={<span>Selected menu option subtitle</span>}>
          Selected menu option title
        </PageTitle>
        <GenericContent>
          <GenericActionButtons />
          <GenericTable
            onAdd={() => {
              navigate('/generic/create');
            }}
            columns={columns}
            title="List of all components, regions, locations, etc..."
          />
        </GenericContent>
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default Generic;
