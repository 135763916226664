import { MENU_ACTION_KEYS } from 'src/helper/constants/menu';

export const getIsMenuAction = (menuObj, actionKey) => {
  // hardcode for now, will fix later
  return true;
  // return (menuObj?.actions || []).includes(actionKey);
};

export const getMenusByItemType = (menusData, type) => {
  let matches = [];
  menusData.map((c) =>
    c.regions.map((r) =>
      r.locations.map((l) =>
        l.roles.map((rl) =>
          rl.menu_items.map((m) => {
            if (!matches.find((f) => f.id == m.id)) matches.push(m);
          })
        )
      )
    )
  );
  matches = matches.filter((f) => f.item_type == type);
  return matches;
};

export const getMenuName = (obj) => obj?.item_name || obj?.name || '';
export const getMenuActionsArr = (obj) => {
  const arr = [];
  [
    MENU_ACTION_KEYS.VIEW,
    MENU_ACTION_KEYS.EDIT,
    MENU_ACTION_KEYS.ADD,
    MENU_ACTION_KEYS.DELETE,
  ].forEach((key) => {
    if (obj?.[key]) {
      arr.push(key);
    }
  });
  return arr;
};
