/* eslint-disable max-lines */
import WorkflowNode from './WorkflowNode';

export const initialNodes = [
  {
    id: 'a',
    type: 'Workflow-node',
    position: { x: -141.24858342846287, y: -849.165271145647 },
    data: {
      label: 'Form 1',
      subType: 'dnode_form',
      ui_group: '',
      task_data: [],
      description: '',
    },
    measured: { width: 150, height: 94 },
    selected: false,
    dragging: false,
  },
];

export const nodeTypes = {
  'Workflow-node': WorkflowNode,
  // Add any of your custom nodes here!
};
