import cn from 'classnames';
import { ReactComponent as EmergencyCircleSvg } from 'src/assets/icons/emergency-circle.svg';
import Button from 'src/components/Button';
import classes from './Emergency.module.scss';

const EmergencyPlan = ({ className, title, items, btnText }) => {
  return (
    <div className={cn(classes.emergencyPlan, className)}>
      <div>
        <span>{title}</span>
        <div>
          <Button
            variant="primary"
            onClick={() => {}}
            fontSize="sm"
            size="medium"
          >
            {btnText}
          </Button>
        </div>
      </div>
      <div>
        <ul>
          {items.map((item) => {
            return (
              <li key={item.id}>
                <EmergencyCircleSvg />
                <span>Ammonia Spill Notification Procedures</span>
                <Button
                  variant="default"
                  onClick={() => {}}
                  size="medium"
                  fontSize="xs"
                >
                  View
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default EmergencyPlan;
