import cn from 'classnames';
import { useController } from 'react-hook-form';
import FormCheckbox from 'src/components/FormFields/Checkbox/FormCheckbox';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSwitch from 'src/components/FormFields/Switch/FormSwitch';
import classes from './OperatingHours.module.scss';

const TimeInput = ({ control, namePrefix, day }) => {
  const { field } = useController({
    name: `${namePrefix}day`,
    control,
  });
  const isChecked = !!field.value;
  return (
    <li
      className={cn({
        [classes.isTimeDisable]: !isChecked,
      })}
    >
      <div>
        <FormSwitch name={`${namePrefix}day`} control={control} />
        <span>{day}</span>
      </div>
      <div>
        <FormCheckbox
          name={`${namePrefix}24`}
          variant="primary"
          control={control}
        />
        <label htmlFor={`${namePrefix}24`}>Open 24 HRS</label>
      </div>
      <div>
        <span>from</span>
        <FormInput
          name={`${namePrefix}from`}
          placeholder="9:00 AM"
          control={control}
          radius="md"
          variant="primary"
          fontSize="sm"
          className={cn({
            [classes.timeInputIsActive]: isChecked,
          })}
        />
        <span>to</span>
        <FormInput
          name={`${namePrefix}to`}
          placeholder="5:00 PM"
          control={control}
          radius="md"
          variant="primary"
          fontSize="sm"
          className={cn({
            [classes.timeInputIsActive]: isChecked,
          })}
        />
      </div>
    </li>
  );
};

export default TimeInput;
