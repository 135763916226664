import cloneDeep from 'lodash/cloneDeep';
import { ReactComponent as RegionsSvg } from 'src/assets/icons/Nav/Regions.svg';
import { ReactComponent as RegionsActiveSvg } from 'src/assets/icons/Nav/Regions_Active.svg';
import { ReactComponent as SettingsSvg } from 'src/assets/icons/Nav/Settings1.svg';
import { ReactComponent as SettingsActiveSvg } from 'src/assets/icons/Nav/Settings1_Active.svg';
import { SAMPLE_MENUS } from 'src/layout/Main/data';
import routes from 'src/routes';
import { useReducerData } from 'src/store/hooks';
import { getMenuName } from 'src/utils/menu';

const SIDEBAR_MENU_ICONS = {
  region: {
    icon: <RegionsSvg />,
    iconActive: <RegionsActiveSvg />,
  },
};

const groupMenus = (menus) => {
  if (!menus?.length) return [];
  const tMenus = [];
  menus.forEach((menu) => {
    const foundIndex = tMenus.findIndex(
      (obj) => obj.url !== '' && obj.url === menu.url
    );
    if (foundIndex >= 0) {
      tMenus[foundIndex] = {
        ...tMenus[foundIndex],
        name: `${getMenuName(tMenus[foundIndex])} & ${getMenuName(menu)}`,
        item_name: `${getMenuName(tMenus[foundIndex])} & ${getMenuName(menu)}`,
      };
    } else {
      tMenus.push(menu);
    }
  });
  return tMenus;
};
function parseMenu(menus) {
  if (!menus?.length) return [];
  return groupMenus(menus).map((menu) => {
    const tUrl = menu.url || '';
    const foundRoute = routes.find((obj) => obj.path === menu.url);
    const mappingRouteIcon = SIDEBAR_MENU_ICONS[menu.icon];
    const returnObj = {
      name: getMenuName(menu),
      item_name: getMenuName(menu),
      path: tUrl.startsWith('/') ? tUrl : '',
      icon: foundRoute?.icon || mappingRouteIcon?.icon || <SettingsSvg />,
      iconActive: foundRoute?.iconActive || mappingRouteIcon?.iconActive || (
        <SettingsActiveSvg />
      ),
    };
    if (menu.child_menus?.length) {
      returnObj.collapse = true;
      returnObj.state = getMenuName(menu);
      returnObj.views = parseMenu(menu.child_menus);
    }
    return returnObj;
  });
}

const useSidebarMenus = () => {
  const isUseStaticData = false;
  const storeRawMenus = useReducerData('auth', 'login.data.menus', []);
  const staticRawMenus = cloneDeep(SAMPLE_MENUS);
  const rawMenus = isUseStaticData ? staticRawMenus : storeRawMenus;
  const rawParsedMenus = parseMenu(
    rawMenus.filter((obj) => obj.url !== '/dashboard')
  );
  const menus = rawParsedMenus.filter((obj) => obj.path !== '/settings');
  const settingsMenu = rawParsedMenus.find((obj) => obj.path === '/settings');
  if (settingsMenu) {
    menus.push({
      ...settingsMenu,
      borderTop: true,
      labelTop: 'Settings',
    });
  }
  return { menus };
};

export default useSidebarMenus;
