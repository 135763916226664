import { useState } from 'react';
import Button from 'src/components/Button';
import Input from 'src/components/FormFields/Input';
import Modal from 'src/components/Modal';
import classes from './Modal.module.scss';

const ConfirmModal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  children,
  submitText,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      hideClose
      centered
      className={classes.confirmModalWrapper}
    >
      <div className={classes.confirmModalContent}>{children}</div>
      <div className={classes.confirmModalFooter}>
        <Button
          variant="primary"
          type="button"
          fontSize="sm"
          onClick={onClose}
          isLink
        >
          Cancel
        </Button>
        <Button
          variant="red"
          type="button"
          fontSize="sm"
          loading={loading}
          onClick={() => {
            if (onSubmit) {
              setLoading(true);
              onSubmit(() => {
                setLoading(false);
              });
            }
          }}
        >
          {submitText}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
