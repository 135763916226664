import moment from 'moment';

export const getMomentDate = (val, isToday) => {
  if (!val && !isToday) return '';
  if (!val && !!isToday) return moment();
  return moment(val);
};
export const getFormatDate = (val, fmStr) => {
  if (!val || !fmStr) return '';
  return getMomentDate(val).format(fmStr);
};
