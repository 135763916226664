import React from 'react';
import { getSelectValue } from 'src/utils/form';

export const getCreateUserPayload = (values) => {
  const returnObj = {
    ...values,
    supervisor_id: Number(getSelectValue(values.supervisor_id)) || null,
  };
  return returnObj;
};
export const getAssignedLocationsRolesPayload = (items) => {
  return (items || []).map((obj) => {
    return {
      location_id: obj.id,
      roles: (obj.roles || []).map((v) => Number(v)),
    };
  });
};
export const getTitle = ({ isViewDetails, isEdit, userDetails }) => {
  if (isViewDetails) return `View Details - ${userDetails?.name}`;
  return isEdit ? `Edit User - ${userDetails?.name}` : 'Create User';
};
export const getSubTitle = () => {
  return (
    <>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum
      <br />
      has been the industry's standard dummy text ever since the 1500s, when an
      unknown printer
      <br />
      took a galley of type and scrambled it to make a type specimen book.
    </>
  );
};
export const getBreadcrumbText = ({ isViewDetails, isEdit }) => {
  if (isViewDetails) return 'User View Details';
  return isEdit ? 'User Edit' : 'User Create';
};
