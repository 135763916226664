import cn from 'classnames';
import { ReactComponent as CloseCircleSvg } from 'src/assets/icons/close-circle.svg';
import classes from './AddedLocation.module.scss';

const EditTag = ({ onClick, className }) => {
  return (
    <span onClick={onClick} className={cn(classes.editTag, className)}>
      Edit
    </span>
  );
};

const AddedLocation = ({ className, onRemove, label, description, onEdit }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div>
        <span>{label}</span>
        <EditTag onClick={onEdit} />
        {/* <CloseCircleSvg onClick={onEdit} /> */}
      </div>
      <div>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default AddedLocation;
