import React from 'react';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import classes from './LocationTable.module.scss';

export const getColumns = ({ onUnarchive }) => {
  return [
    {
      key: 'name',
      label: 'Location Name',
      render: (val, obj) => {
        return (
          <span className={classes.locationColInner}>
            <span>Americold Logistics Boise</span>
            <span>123 S Main St. Boise, ID 83702</span>
          </span>
        );
      },
      sortable: true,
      width: 317,
    },
    {
      key: 'archiveReason',
      label: 'Archive reason',
      render: (val) =>
        'This location was shut down as we transferred all operaitions over to the new facility in meridian',
      sortable: true,
    },
    {
      key: 'archivedDate',
      label: 'Archived Date',
      render: (val) => 'Nov 26, 2024',
      sortable: true,
      width: 200,
    },
    {
      key: 'archivedBy',
      label: 'Archived by',
      render: (val) => 'Shane McGonigal',
      sortable: true,
      width: 180,
    },
    {
      key: 'action',
      width: 72,
      render: (_, obj) => {
        return (
          <Dropdown
            items={[
              {
                key: 'unarchive',
                label: 'Unarchive',
                onClick: () => {
                  if (onUnarchive) onUnarchive(obj);
                },
              },
            ]}
          >
            <MoreSvg />
          </Dropdown>
        );
      },
    },
  ];
};
