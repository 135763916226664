export const ALL_OPTION = {
  value: 'all',
  label: 'All',
};
export const NONE_OPTION = {
  value: 'none',
  label: 'None',
};
export const MENU_ITEM_DEFAULT_TYPE_OPTION = {
  value: 'process',
  label: 'Process',
};
export const MENU_ITEM_TYPE_OPTIONS = [
  MENU_ITEM_DEFAULT_TYPE_OPTION,
  {
    value: 'workflow',
    label: 'Workflow',
  },
  {
    value: 'menu group',
    label: 'Menu Group',
  },
  {
    value: 'repository folder',
    label: 'Repository Folder',
  },
];
