import BaseAxios from 'axios';
import get from 'lodash/get';
import { store } from 'src/store';
import { logOutUser } from 'src/store/actions/auth';

class Request {
  axios;

  constructor() {
    // Timeout is 60 * 60000 = 60x60s = 60m = 1hour
    this.axios = BaseAxios.create({ timeout: 3600000 });
  }

  async call(config) {
    try {
      const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const state = store.getState();
      const token = get(state, 'auth.login.data.token', '');
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization:
          token || config.token ? `Bearer ${token || config.token}` : undefined,
      };
      const res = await this.axios.request({
        baseURL: serverBaseUrl,
        headers,
        ...config,
      });
      const isApiSuccess = [200, 201, 204].includes(res?.status);
      if (!isApiSuccess) {
        return {
          isSuccess: false,
          data: res,
        };
      } else {
        return {
          ...res.data,
          isSuccess: isApiSuccess,
        };
      }
    } catch (error) {
      const errorStatuscode = error?.response?.status;
      const errorMessage = error?.response?.data?.message;
      // Handle invalid token error
      if (
        errorStatuscode === 401 &&
        errorMessage === 'Unauthorized: Invalid token'
      ) {
        if (window && window.stop) {
          window.stop();
        }
        store.dispatch(logOutUser());
      }
      return {
        isSuccess: false,
        data: error,
      };
    }
  }
}

export default new Request();
