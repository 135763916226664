import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Loading from 'src/components/Loading';
import { NONE_OPTION } from 'src/helper/constants/option';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import useSubSystemOptions from 'src/helper/hooks/useSubSystemOptions';
import Field from 'src/pages/GenericCreate/components/Field';
import GenericCreateFooter from 'src/pages/GenericCreate/components/Footer';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import { useActions, useIndexData } from './selectorData';
import { getCreatePartPayload } from './utils';

const formSchema = yup.object().shape({
  part_name: yup.string().required('Name is required!'),
  sub_system_id: yup.mixed().required('Sub System is required!'),
});

const PartsCreate = () => {
  const { onCreateResource, onEditResource } = useResourceActions();
  const { getResourceDetails } = useActions();
  const { partDetails } = useIndexData();
  const { subSystemOptions } = useSubSystemOptions();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const navigate = useNavigate();
  const partId = Number(urlParams.id || 0);
  const isEdit = !!partId;
  const isView = urlParams.view === 'true' && isEdit;
  const notificationAlertRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const getTitle = () => {
    if (isView) return `View Details - ${partDetails?.part_name}`;
    return isEdit ? `Edit Part - ${partDetails?.part_name}` : 'Create Part';
  };
  const getSubTitle = () => {
    if (isEdit) return partDetails?.description || '-';
    return (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum
        <br />
        has been the industry's standard dummy text ever since the 1500s, when
        an unknown printer
        <br />
        took a galley of type and scrambled it to make a type specimen book.
      </>
    );
  };
  const getBreadcrumbText = () => {
    if (isView) return 'Part View Details';
    return isEdit ? 'Part Edit' : 'Part Create';
  };
  const breadcrumbs = [
    {
      link: '/admin/parts',
      label: 'Parts',
    },
    {
      label: getBreadcrumbText(),
    },
  ];
  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      part_name: '',
      quantity: '',
      description: '',
      supplier: '',
      sub_system_id: null,
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const reloadDetailsData = (cb) => {
    setDetailsLoading(true);
    getResourceDetails(RESOURCE_DATA_KEY.PARTS, partId, (res) => {
      setDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const onSubmit = async (values) => {
    if (!submitLoading) {
      setSubmitLoading(true);
      const tPayload = getCreatePartPayload(values);
      let res = null;
      if (partId) {
        tPayload.status = partDetails?.status;
        res = await onEditResource(RESOURCE_DATA_KEY.PARTS, partId, tPayload);
      } else {
        tPayload.status = 'active';
        res = await onCreateResource(RESOURCE_DATA_KEY.PARTS, tPayload);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        navigate('/admin/parts');
      } else {
        notify('error', 'Error', `${partId ? 'Edit' : 'Create'} Part failed!`);
      }
    }
  };

  useEffect(() => {
    if (partId) {
      reloadDetailsData((res) => {
        if (!isView && !!isEdit) {
          const editObj = res?.data;
          if (editObj?.id) {
            reset({
              part_name: editObj.part_name,
              quantity: editObj.quantity,
              description: editObj.description,
              supplier: editObj.supplier,
              sub_system_id: editObj.sub_system_id,
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partId]);

  return (
    <>
      <GenericCreateWrapper>
        {detailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle subTitle={getSubTitle()}>
          <span>{getTitle()}</span>
        </GenericCreateTitle>
        <form role="form" className="s-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="s-form-inner">
            {isView ? (
              <>
                <Field label="Name" value={partDetails?.part_name || '-'} />
                <Field
                  label="Sub System"
                  value={partDetails?.sub_system_name || '-'}
                />
                <Field label="Quantity" value={partDetails?.quantity || '-'} />
                <Field
                  label="Description"
                  value={partDetails?.description || '-'}
                />
                <Field label="Supplier" value={partDetails?.supplier || '-'} />
              </>
            ) : (
              <>
                <FormGroup>
                  <FormInput
                    required
                    label="Name"
                    name="part_name"
                    placeholder="Enter name"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    autoFocus
                  />
                </FormGroup>
                <FormGroup>
                  <FormSelect
                    options={subSystemOptions}
                    required
                    label="Sub System"
                    name="sub_system_id"
                    placeholder="Select sub system"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Quantity"
                    name="quantity"
                    placeholder="Enter quantity"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    type="number"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Supplier"
                    name="supplier"
                    placeholder="Enter supplier"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Description"
                    name="description"
                    placeholder="Enter description"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    type="textarea"
                  />
                </FormGroup>
              </>
            )}
          </div>
          {!isView && (
            <GenericCreateFooter>
              <Button
                variant="primary"
                isLink
                type="button"
                onClick={() => {
                  navigate('/admin/parts');
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!formState.isValid}
                loading={submitLoading}
              >
                {isEdit ? 'Save Changes' : 'Create'}
              </Button>
            </GenericCreateFooter>
          )}
        </form>
      </GenericCreateWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default PartsCreate;
