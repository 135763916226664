import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { useController } from 'react-hook-form';
import SelectMultiple from 'src/components/FormFields/Select/Multiple';
import useDynamicMenu from 'src/helper/hooks/useDynamicMenu';
import {
  getRealLocationId,
  getRealLocationName,
} from 'src/helper/providers/utils';
import classes from './LocationRoles.module.scss';

const Row = ({ data, onChange, value, options }) => {
  const tName = getRealLocationName(data?.label);
  const tValue = value || [];
  const tOptions = options || [];
  return (
    <tr>
      <td>{data?.label}</td>
      <td>
        <SelectMultiple options={tOptions} value={tValue} onChange={onChange} />
      </td>
    </tr>
  );
};

function LocationRoles({ className, onChange, control, name, menus, roles }) {
  const { scopeFilteredMenus } = useDynamicMenu();
  const tRoles = roles || [];
  const roleOptions = tRoles.map((obj) => {
    return {
      value: obj.id,
      label: obj.role_name,
    };
  });
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const value = field.value || [];
  const tMenus = menus || [];
  const renderRows = (arr) => {
    return (arr || []).map((tData) => {
      const tLocationId = getRealLocationId(tData.value);
      return (
        <React.Fragment key={tLocationId}>
          <Row
            data={tData}
            options={roleOptions}
            value={value.find((obj) => obj.id == tLocationId)?.roles || []}
            onChange={(tVal) => {
              const newValue = cloneDeep(value);
              const foundIndex = newValue.findIndex(
                (obj) => obj.id === tLocationId
              );
              if (foundIndex >= 0) {
                newValue[foundIndex].roles = [...tVal];
              } else {
                newValue.push({
                  id: tLocationId,
                  roles: [...tVal],
                });
              }
              if (onChange) onChange(newValue);
            }}
          />
        </React.Fragment>
      );
    });
  };

  return (
    <div className={cn(classes.wrapper, className)}>
      <table>
        <colgroup>
          <col style={{ width: '70%' }} />
          <col style={{ width: '30%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>Location</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>{renderRows(scopeFilteredMenus)}</tbody>
      </table>
    </div>
  );
}

export default LocationRoles;
