import cn from 'classnames';
import React from 'react';
import classes from './PageWrapper.module.scss';

const PageWrapper = ({ className, children, gap }) => {
  return (
    <div className={cn(classes.wrapper, classes[`gap_${gap}`], className)}>
      {children}
    </div>
  );
};

export default PageWrapper;
