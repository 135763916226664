import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Loading from 'src/components/Loading';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import Field from 'src/pages/GenericCreate/components/Field';
import GenericCreateFooter from 'src/pages/GenericCreate/components/Footer';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import { useActions, useIndexData } from './selectorData';
import { getCreateSubSystemPayload } from './utils';

const formSchema = yup.object().shape({
  sub_system_name: yup.string().required('Name is required!'),
  location_id: yup.mixed().required('Location is required!'),
});
let cacheLocationChanged = 0;

const SubSystemsCreate = () => {
  const { selectedLocations, getSingleLocationOption } =
    useContext(MenuContext);
  const { onCreateResource, onEditResource } = useResourceActions();
  const { getResourceDetails } = useActions();
  const { subSystemDetails } = useIndexData();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const navigate = useNavigate();
  const subSystemId = Number(urlParams.id || 0);
  const isEdit = !!subSystemId;
  const isView = urlParams.view === 'true' && isEdit;
  const notificationAlertRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const getTitle = () => {
    if (isView) return `View Details - ${subSystemDetails?.sub_system_name}`;
    return isEdit
      ? `Edit Sub System - ${subSystemDetails?.sub_system_name}`
      : 'Create Sub System';
  };
  const getSubTitle = () => {
    if (isEdit) return subSystemDetails?.description || '-';
    return (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum
        <br />
        has been the industry's standard dummy text ever since the 1500s, when
        an unknown printer
        <br />
        took a galley of type and scrambled it to make a type specimen book.
      </>
    );
  };
  const getBreadcrumbText = () => {
    if (isView) return 'Sub System View Details';
    return isEdit ? 'Sub System Edit' : 'Sub System Create';
  };
  const breadcrumbs = [
    {
      link: '/admin/sub-systems',
      label: 'Sub System',
    },
    {
      label: getBreadcrumbText(),
    },
  ];
  const { handleSubmit, control, watch, setValue, formState, reset, trigger } =
    useForm({
      resolver: yupResolver(formSchema),
      defaultValues: {
        sub_system_name: '',
        location_id: selectedLocations?.[0],
        description: '',
      },
    });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const reloadDetailsData = (cb) => {
    setDetailsLoading(true);
    getResourceDetails(RESOURCE_DATA_KEY.SUB_SYSTEMS, subSystemId, (res) => {
      setDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const onSubmit = async (values) => {
    if (!submitLoading) {
      setSubmitLoading(true);
      const tPayload = getCreateSubSystemPayload(values);
      let res = null;
      if (subSystemId) {
        delete tPayload.location_id;
        res = await onEditResource(
          RESOURCE_DATA_KEY.SUB_SYSTEMS,
          subSystemId,
          tPayload
        );
      } else {
        tPayload.status = 'active';
        res = await onCreateResource(RESOURCE_DATA_KEY.SUB_SYSTEMS, tPayload);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        navigate('/admin/sub-systems');
      } else {
        notify(
          'error',
          'Error',
          `${subSystemId ? 'Edit' : 'Create'} Sub System failed!`
        );
      }
    }
  };

  useEffect(() => {
    cacheLocationChanged = 0;
    if (subSystemId) {
      reloadDetailsData((res) => {
        if (!isView && !!isEdit) {
          const editObj = res?.data;
          if (editObj?.id) {
            reset({
              sub_system_name: editObj.sub_system_name || '',
              location_id: getSingleLocationOption(editObj.location_id) || null,
              description: editObj.description || '',
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subSystemId]);
  useEffect(() => {
    cacheLocationChanged = cacheLocationChanged + 1;
    if (cacheLocationChanged > 1 && !isEdit) {
      setValue('location_id', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedLocations)]);

  return (
    <>
      <GenericCreateWrapper>
        {detailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle subTitle={getSubTitle()}>
          <span>{getTitle()}</span>
        </GenericCreateTitle>
        <form role="form" className="s-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="s-form-inner">
            {isView ? (
              <>
                <Field
                  label="Name"
                  value={subSystemDetails?.sub_system_name || '-'}
                />
                <Field
                  label="Location"
                  value={
                    getSingleLocationOption(subSystemDetails?.location_id)
                      ?.label || '-'
                  }
                />
                <Field
                  label="Description"
                  value={subSystemDetails?.description || '-'}
                />
              </>
            ) : (
              <>
                <FormGroup>
                  <FormSelect
                    options={selectedLocations}
                    label="Location"
                    name="location_id"
                    placeholder="Select location"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    autoFocus
                    required
                    isDisabled={isEdit}
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    required
                    label="Name"
                    name="sub_system_name"
                    placeholder="Enter name"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    label="Description"
                    name="description"
                    placeholder="Enter description"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    type="textarea"
                  />
                </FormGroup>
              </>
            )}
          </div>
          {!isView && (
            <GenericCreateFooter>
              <Button
                variant="primary"
                isLink
                type="button"
                onClick={() => {
                  navigate('/admin/sub-systems');
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!formState.isValid || !formState.isDirty}
                loading={submitLoading}
              >
                {isEdit ? 'Save Changes' : 'Create'}
              </Button>
            </GenericCreateFooter>
          )}
        </form>
      </GenericCreateWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default SubSystemsCreate;
