import * as documentActions from 'src/store/actions/repository';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    documents: useReducerData('document', 'document.data', {}),
    repositoryLoading: useReducerData('document', 'document.loading', false),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...documentActions,
  });
};
