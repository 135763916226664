import cn from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from 'src/components/Loading';
import { MENU_ACTION_KEYS } from 'src/helper/constants/menu';
import ArchiveLocationModal from 'src/pages/RegionDetails/components/ArchiveLocationModal';
import EmptyState from 'src/pages/Regions/components/EmptyState';
import LocationCard from 'src/pages/Regions/components/LocationCard';
import { getIsMenuAction } from 'src/utils/menu';
import classes from './Locations.module.scss';
import { useIndexData } from './selectorData';

const LocationsContent = ({ notify, menuObj, onDelete }) => {
  const [archiveLocationObj, setArchiveLocationObj] = useState(null);
  const navigate = useNavigate();
  const { locations, locationsLoading } = useIndexData();
  const locationsList = locations?.data || [];
  const isNotFound = locations?.meta?.total === 0;

  return (
    <>
      <div className={cn(classes.locationsContentWrapper)}>
        {locationsLoading && <Loading isPage />}
        {isNotFound ? (
          <EmptyState
            entity="location"
            onCreate={() => {
              navigate('/locations/create-location');
            }}
          />
        ) : (
          <div className={classes.locations}>
            {locationsList.map((location, i) => {
              return (
                <LocationCard
                  key={i}
                  onDelete={
                    !getIsMenuAction(menuObj, MENU_ACTION_KEYS.DELETE)
                      ? undefined
                      : () => {
                          setArchiveLocationObj(location);
                        }
                  }
                  onEdit={
                    !getIsMenuAction(menuObj, MENU_ACTION_KEYS.EDIT)
                      ? undefined
                      : () => {
                          navigate(`/locations/edit/${location.id}`);
                        }
                  }
                  data={location}
                  onView={() => {
                    navigate(`/locations/${location.id}`);
                  }}
                  hideActions={
                    !getIsMenuAction(menuObj, MENU_ACTION_KEYS.DELETE) &&
                    !getIsMenuAction(menuObj, MENU_ACTION_KEYS.EDIT)
                  }
                />
              );
            })}
          </div>
        )}
      </div>
      {archiveLocationObj && (
        <ArchiveLocationModal
          isOpen
          onClose={() => {
            setArchiveLocationObj(null);
          }}
          onSubmit={(val, cb) => {
            onDelete(archiveLocationObj.id, () => {
              if (cb) cb();
              setArchiveLocationObj(null);
              notify('success', 'Success', 'Location archived successfully', {
                place: 'bc',
              });
            });
            // TODO: delete location with reason as val
          }}
        />
      )}
    </>
  );
};

export default LocationsContent;
