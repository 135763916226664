import get from 'lodash/get';
import queryString from 'query-string';

export const getAllUrlParams = (search = '') => {
  const searchUrl = search;
  return queryString.parse(searchUrl.slice(1)) || {};
};
export const getUrlParamValueByKey = (
  search = '',
  key = '',
  isGetAll = false
) => {
  const values = getAllUrlParams(search);
  if (isGetAll) {
    return values;
  }
  return get(values, key, '');
};
export const getRelativePathWithParams = (val, params) => {
  if (!val || !params) return '';

  const relativePath = val;
  const baseUrl = 'https://example.com';
  const url = new URL(relativePath, baseUrl);
  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });
  const updatedRelativePath = url.pathname + url.search;
  return updatedRelativePath;
};
export const decodeToCleanUrl = (val) => {
  if (!val) return '';
  return decodeURIComponent(val);
};
