import { ReactComponent as ArchivedSvg } from 'src/assets/icons/Nav/Archived.svg';
import { ReactComponent as ArchivedActiveSvg } from 'src/assets/icons/Nav/Archived_Active.svg';
import { ReactComponent as DashboardSvg } from 'src/assets/icons/Nav/Dashboard.svg';
import { ReactComponent as DashboardActiveSvg } from 'src/assets/icons/Nav/Dashboard_Active.svg';
import { ReactComponent as RegionsSvg } from 'src/assets/icons/Nav/Regions.svg';
import { ReactComponent as RegionsActiveSvg } from 'src/assets/icons/Nav/Regions_Active.svg';
import { ReactComponent as SettingsSvg } from 'src/assets/icons/Nav/Settings1.svg';
import { ReactComponent as SettingsActiveSvg } from 'src/assets/icons/Nav/Settings1_Active.svg';
import ForgotPassword from 'src/pages/Auth/ForgotPassword';
import Login from 'src/pages/Auth/Login';
import ResetPassword from 'src/pages/Auth/ResetPassword';
import CreateLocation from 'src/pages/CreateLocation';
import CreateRegion from 'src/pages/CreateRegion';
import Dashboard from 'src/pages/Dashboard';
import LocationDetails from 'src/pages/LocationDetails';
import Locations from 'src/pages/Locations';
import RegionDetails from 'src/pages/RegionDetails';
import Regions from 'src/pages/Regions';
import Repository from 'src/pages/Repository';
import Settings from 'src/pages/Settings';
import Admin from './pages/Admin';
import AdminCompanies from './pages/Admin/Companies';
import AdminCompaniesCreate from './pages/Admin/CompaniesCreate';
import AdminMenuItems from './pages/Admin/MenuItems';
import AdminMenuItemsCreate from './pages/Admin/MenuItemsCreate';
import AdminParts from './pages/Admin/Parts';
import AdminPartsCreate from './pages/Admin/PartsCreate';
import AdminRoles from './pages/Admin/Roles';
import AdminRolesCreate from './pages/Admin/RolesCreate';
import AdminSubSystems from './pages/Admin/SubSystems';
import SubSystemsCreate from './pages/Admin/SubSystemsCreate';
import AdminUsers from './pages/Admin/Users';
import AdminUsersCreate from './pages/Admin/UsersCreate';
import Archived from './pages/Archived';
import CreateWorkflow from './pages/CreateWorkflow';
import ExecuteWorkflow from './pages/ExecuteWorkflow';
import Executions from './pages/Executions';
import Generic from './pages/Generic';
import GenericCreate from './pages/GenericCreate';
import NotFound from './pages/NotFound';
import DocumentCreate from './pages/Repository/DocumentCreate';
import Workflow from './pages/Workflow';

const adminRoutes = [
  {
    path: '/admin',
    name: 'Admin',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Admin,
    layout: 'private',
  },
  {
    path: '/admin/menu-items',
    name: 'Menu Items',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminMenuItems,
    layout: 'private',
  },
  {
    path: '/admin/menu-items/create',
    name: 'Menu Items Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminMenuItemsCreate,
    layout: 'private',
  },
  {
    path: '/admin/companies',
    name: 'Companies',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminCompanies,
    layout: 'private',
  },
  {
    path: '/admin/companies/create',
    name: 'Companies Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminCompaniesCreate,
    layout: 'private',
  },
  {
    path: '/admin/roles',
    name: 'Roles',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminRoles,
    layout: 'private',
  },
  {
    path: '/admin/roles/create',
    name: 'Roles Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminRolesCreate,
    layout: 'private',
  },
  {
    path: '/admin/users',
    name: 'Users',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminUsers,
    layout: 'private',
  },
  {
    path: '/admin/users/create',
    name: 'Users Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminUsersCreate,
    layout: 'private',
  },
  {
    path: '/admin/parts',
    name: 'Parts',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminParts,
    layout: 'private',
  },
  {
    path: '/admin/parts/create',
    name: 'Parts Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminPartsCreate,
    layout: 'private',
  },
  {
    path: '/admin/sub-systems',
    name: 'SubSystems',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: AdminSubSystems,
    layout: 'private',
  },
  {
    path: '/admin/sub-systems/create',
    name: 'SubSystems Create',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: SubSystemsCreate,
    layout: 'private',
  },
];

const routes = [
  ...adminRoutes,
  {
    path: '/404-not-found',
    name: 'NotFound',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: NotFound,
    layout: 'private',
  },
  {
    path: '/',
    name: 'Home',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: Dashboard,
    layout: 'private',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: Dashboard,
    layout: 'private',
  },
  {
    path: '/repository',
    name: 'Repository',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: Repository,
    layout: 'private',
  },
  {
    path: '/repository/*',
    name: 'Repository',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: Repository,
    layout: 'private',
  },
  {
    path: '/repository/create',
    name: 'Create Repository Item',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: DocumentCreate,
    layout: 'private',
  },
  {
    path: '/repository/edit',
    name: 'Edit Repository Item',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: DocumentCreate,
    layout: 'private',
  },
  {
    path: '/repository/view',
    name: 'View Repository Item',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: DocumentCreate,
    layout: 'private',
  },
  {
    path: '/regions/:id',
    name: 'Region Details',
    icon: '',
    iconActive: '',
    component: RegionDetails,
    layout: 'private',
  },
  {
    path: '/regions/edit/:id',
    name: 'Edit Region',
    icon: '',
    iconActive: '',
    component: CreateRegion,
    layout: 'private',
  },
  {
    path: '/regions/create-region',
    name: 'Create Region',
    icon: '',
    iconActive: '',
    component: CreateRegion,
    layout: 'private',
  },
  {
    path: '/locations',
    name: 'Locations',
    component: Locations,
    layout: 'private',
    icon: <RegionsSvg />,
    iconActive: <RegionsActiveSvg />,
  },
  {
    path: '/locations/:id',
    name: 'Location Details',
    icon: '',
    iconActive: '',
    component: LocationDetails,
    layout: 'private',
  },
  {
    path: '/locations/edit/:id',
    name: 'Edit Location',
    icon: '',
    iconActive: '',
    component: CreateLocation,
    layout: 'private',
  },
  {
    path: '/locations/create-location',
    name: 'Create Location',
    icon: '',
    iconActive: '',
    component: CreateLocation,
    layout: 'private',
  },
  {
    path: '/regions',
    name: 'Regions',
    icon: <RegionsSvg />,
    iconActive: <RegionsActiveSvg />,
    component: Regions,
    layout: 'private',
  },
  {
    path: '/archived',
    name: 'Archived',
    icon: <ArchivedSvg />,
    iconActive: <ArchivedActiveSvg />,
    component: Archived,
    layout: 'private',
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Settings,
    layout: 'private',
  },
  {
    path: '/workflow',
    name: 'Workflow',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Workflow,
    layout: 'private',
  },
  {
    path: '/executions/incident-investigation',
    name: ' - Incident Investigation',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Executions,
    layout: 'private',
  },
  {
    path: '/executions/example-test',
    name: ' - Example Test',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Executions,
    layout: 'private',
  },
  {
    path: '/workflows/edit/:id',
    name: 'Edit Workflow',
    icon: '',
    iconActive: '',
    component: CreateWorkflow,
    layout: 'private',
  },
  {
    path: '/workflows/execute/:id',
    name: 'Execute Workflow',
    icon: '',
    iconActive: '',
    component: ExecuteWorkflow,
    layout: 'private',
  },
  {
    path: '/workflows/execute/:id/:executionId',
    name: 'Execute Workflow Run',
    icon: '',
    iconActive: '',
    component: ExecuteWorkflow,
    layout: 'private',
  },
  {
    path: '/workflows/create-workflow',
    name: 'Create Workflow',
    icon: '',
    iconActive: '',
    component: CreateWorkflow,
    layout: 'private',
  },
  {
    path: '/executions/:id',
    name: 'Executions',
    icon: '',
    iconActive: '',
    component: Executions,
    layout: 'private',
  },
  {
    path: '/generic',
    name: 'Generic',
    icon: '',
    iconActive: '',
    component: Generic,
    layout: 'private',
  },
  {
    path: '/generic/create',
    name: 'GenericCreate',
    icon: '',
    iconActive: '',
    component: GenericCreate,
    layout: 'private',
  },
  // {
  //   path: '/executions/edit/:id',
  //   name: 'Edit Execution',
  //   icon: '',
  //   iconActive: '',
  //   component: CreateExecution,
  //   layout: 'private',
  // },
  // {
  //   path: '/executions/create-execution',
  //   name: 'Create Execution',
  //   icon: '',
  //   iconActive: '',
  //   component: CreateExecution,
  //   layout: 'private',
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: 'auth',
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    layout: 'auth',
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
    layout: 'auth',
  },
];

export default routes;
