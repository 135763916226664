import {
  apiAssignResourceLocationsRoles,
  apiAssignResourceMenuItems,
  apiCreateResource,
  apiDeleteResource,
  apiGetResourceLocationsRoles,
  apiGetResourceMenuItems,
  apiGetResources,
  apiUpdateResource,
} from 'src/api';
import { callAction } from 'src/utils/actions';

const useResourceActions = () => {
  const onGetResources = async (key, params) => {
    const res = await callAction(() => {
      return apiGetResources(key, params);
    });
    return res;
  };
  const onCreateResource = async (key, payload) => {
    const res = await callAction(() => {
      return apiCreateResource(key, payload);
    });
    return res;
  };
  const onEditResource = async (key, id, payload) => {
    const res = await callAction(() => {
      return apiUpdateResource(key, id, payload);
    });
    return res;
  };
  const onDeleteResource = async (key, id) => {
    const res = await callAction(() => {
      return apiDeleteResource(key, id);
    });
    return res;
  };
  const onAssignResourceMenuItem = async (key, id, data) => {
    const res = await callAction(() => {
      return apiAssignResourceMenuItems(key, id, data);
    });
    return res;
  };
  const onGetResourceMenuItem = async (key, id) => {
    const res = await callAction(() => {
      return apiGetResourceMenuItems(key, id);
    });
    return res;
  };
  const onAssignResourceLocationsRoles = async (key, id, data) => {
    const res = await callAction(() => {
      return apiAssignResourceLocationsRoles(key, id, data);
    });
    return res;
  };
  const onGetResourceLocationsRoles = async (key, id) => {
    const res = await callAction(() => {
      return apiGetResourceLocationsRoles(key, id);
    });
    return res;
  };

  return {
    onCreateResource,
    onEditResource,
    onDeleteResource,
    onGetResources,
    onAssignResourceMenuItem,
    onGetResourceMenuItem,
    onAssignResourceLocationsRoles,
    onGetResourceLocationsRoles,
  };
};

export default useResourceActions;
