import { useContext, useEffect, useRef, useState } from 'react';
import NotificationAlert from 'react-notification-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowDownSvg } from 'src/assets/icons/arrow-down.svg';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import { LOCATIONS_SCOPE_ATTR_NAME } from 'src/helper/constants';
import { LOCATION_MENU_NAME } from 'src/helper/constants/menu';
import useInfiniteScroll from 'src/helper/hooks/useInfiniteScroll';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import LocationsContent from './Content';
import classes from './Locations.module.scss';
import { useActions, useIndexData } from './selectorData';

let locationsPage = 1;

const Locations = () => {
  const { availableMenus, selectedLocationIds } = useContext(MenuContext);
  const selectedLocationIdsString = selectedLocationIds.join(',');
  const locationMenuObj = availableMenus.find(
    (obj) => obj.name === LOCATION_MENU_NAME
  );
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const notificationAlertRef = useRef(null);
  const navigate = useNavigate();
  const [isLazyLoading, setLazyLoading] = useState(false);
  const { locations } = useIndexData();
  const locationsMeta = locations.meta || null;
  const { getLocations, deleteLocation } = useActions();
  const breadcrumbs = [
    {
      label: 'Locations',
    },
  ];
  const notify = (type, title, description, settings) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings,
    });
  };
  const reloadLocations = (locationIdsString, cb) => {
    if (locationIdsString) {
      getLocations(
        {
          page: locationsPage,
          per_page: 10,
          [LOCATIONS_SCOPE_ATTR_NAME]: locationIdsString,
        },
        cb
      );
    }
  };
  const loadMore = async () => {
    setLazyLoading(true);
    locationsPage = locationsMeta.current_page + 1;
    reloadLocations(selectedLocationIdsString, () => {
      setLazyLoading(false);
    });
  };
  const handleDeleteLocation = (tId, cb) => {
    deleteLocation(tId, (res) => {
      if (res) {
        locationsPage = 1;
        reloadLocations(selectedLocationIdsString);
        if (cb) cb();
      } else {
        notify('error', 'Error', 'Archive Location failed!');
      }
    });
  };
  useInfiniteScroll({
    elementId: 'root',
    onLoadMore: loadMore,
    shouldLoadMore:
      !isLazyLoading &&
      locationsMeta &&
      locationsMeta.current_page < locationsMeta.last_page,
  });

  useEffect(() => {
    locationsPage = 1;
    if (
      urlParams?.createSuccess === 'true' ||
      urlParams?.updateSuccess === 'true'
    ) {
      notify(
        'success',
        'Success',
        `Region ${
          urlParams?.createSuccess === 'true' ? 'created' : 'updated'
        } successfully`,
        {
          place: 'bc',
        }
      );
      navigate('/locations');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedLocationIdsString) {
      reloadLocations(selectedLocationIdsString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationIdsString]);

  return (
    <>
      <PageWrapper className={classes.wrapper} gap="sm">
        <Breadcrumb items={breadcrumbs} />
        <PageTitle
          gap="sm"
          subTitle={
            <span>Select a location to view details or make adjustments</span>
          }
        >
          <span>Locations</span>
          <div className={classes.titleButtons}>
            <Button
              variant="primary"
              onClick={() => {
                navigate('/locations/create-location');
              }}
              type="button"
              fontSize="sm"
              weight="md"
            >
              Add New
            </Button>
          </div>
        </PageTitle>
        <LocationsContent
          notify={notify}
          menuObj={locationMenuObj}
          onDelete={handleDeleteLocation}
        />
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default Locations;
