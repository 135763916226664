import isArray from 'lodash/isArray';

export const getFullAddress = (obj, enterNewLine = false) => {
  const address = [
    obj?.address_1 || '',
    obj?.address_2 || '',
    enterNewLine ? `\n${obj?.city || ''}` : obj?.city || '',
  ]
    .filter((v) => !!v)
    .join(' ');
  const address1 = [obj?.state, obj?.zip].filter((v) => !!v).join(' ');
  return [address, address1].filter((v) => !!v).join(', ');
};
export const getGoogleAddressObj = (arr) => {
  if (arr?.length && !isArray(arr)) return {};
  const zip = arr.find((obj) =>
    obj.types.some((v) => v === 'postal_code')
  )?.short_name;
  const state = arr.find((obj) =>
    obj.types.some((v) => v === 'administrative_area_level_1')
  )?.short_name;
  const city = arr.find((obj) =>
    obj.types.some((v) => v === 'locality')
  )?.short_name;
  const streetName = arr.find((obj) =>
    obj.types.some((v) => v === 'route')
  )?.short_name;
  const streetNumber = arr.find((obj) =>
    obj.types.some((v) => v === 'street_number')
  )?.short_name;
  return {
    address_1: [streetNumber, streetName].filter((v) => !!v).join(' '),
    city,
    state,
    zip,
  };
};
