import Request from './request';

export const apiGetResources = async (key, params) => {
  return Request.call({
    url: `/${key}`,
    method: 'GET',
    params,
  });
};
export const apiGetResource = async (key, id, params) => {
  return Request.call({
    url: `/${key}/${id}`,
    method: 'GET',
    params,
  });
};
export const apiCreateResource = async (key, data) => {
  return Request.call({
    url: `/${key}`,
    method: 'POST',
    data,
  });
};
export const apiUpdateResource = async (key, id, data) => {
  return Request.call({
    url: `/${key}/${id}`,
    method: 'PUT',
    data,
  });
};
export const apiDeleteResource = async (key, id) => {
  return Request.call({
    url: `/${key}/${id}`,
    method: 'DELETE',
    data: {},
  });
};
export const apiAssignResourceMenuItems = async (key, id, data) => {
  return Request.call({
    url: `/${key}/${id}/assign-menu-items`,
    method: 'POST',
    data,
  });
};
export const apiGetResourceMenuItems = async (key, id) => {
  return Request.call({
    url: `/${key}/${id}/menu-items`,
    method: 'GET',
  });
};
export const apiAssignResourceLocationsRoles = async (key, id, data) => {
  return Request.call({
    url: `/${key}/${id}/assign-locations-roles`,
    method: 'POST',
    data,
  });
};
export const apiGetResourceLocationsRoles = async (key, id) => {
  return Request.call({
    url: `/${key}/${id}/locations-roles`,
    method: 'GET',
  });
};
