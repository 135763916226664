import { NONE_OPTION } from 'src/helper/constants/option';
import { getRealLocationId } from 'src/helper/providers/utils';
import { getSelectValues } from 'src/utils/form';

export const getCreateMenuPayload = (values) => {
  const tLocationIds = getSelectValues(values.locations).map((id) =>
    getRealLocationId(id)
  );
  const parentMenuId = values.parent_menu?.value;
  const returnObj = {
    parent_menu_item_id:
      !!parentMenuId && parentMenuId !== NONE_OPTION.value
        ? parentMenuId
        : null,
    item_name: values.name,
    item_type: values.type?.value || 'process',
    url: values.url,
    description: values.description || null,
    icon: values.icon || null,
    location_ids: tLocationIds,
    order_index: values.order_index || 0,
  };
  return returnObj;
};
