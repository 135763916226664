import cn from 'classnames';
import classes from './Score.module.scss';

const ScoreChart = ({ title, className, scores }) => {
  return (
    <div className={cn(classes.scoreChart, className)}>
      {!!title && <span>{title}</span>}
      <div className={classes.scores}>
        {(scores || []).map((score, index) => {
          return (
            <span
              key={index}
              style={{
                height: `${score}%`,
                background:
                  score < 50 ? '#DD302F' : score < 80 ? '#EFA34E' : '#45B942',
              }}
            ></span>
          );
        })}
      </div>
    </div>
  );
};

export default ScoreChart;
