import { createTypes } from './createTypes';

export default createTypes(
  'GET_DOCUMENTS',
  'GET_DOCUMENT_GROUPS',
  'GET_DOCUMENT_DETAILS',
  'CREATE_DOCUMENT',
  'UPDATE_DOCUMENT',
  'DELETE_DOCUMENT'
);
