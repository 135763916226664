export const getActionCheckedLength = (key, value) => {
  return (value || []).filter((obj) => (obj.actions || []).includes(key))
    .length;
};
export function buildNestedMenu(items, parentId = null) {
  return (items || [])
    .filter((item) => item.parent_menu_item_id === parentId)
    .map((item) => ({
      ...item,
      children: buildNestedMenu(items, item.id), // Recursively find children
    }));
}
