// import { useState } from 'react';
// import { ReactComponent as DangerSvg } from 'src/assets/icons/danger.svg';
// import Input from 'src/components/FormFields/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import Modal from 'src/components/Modal';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import classes from './CreateLocationModal.module.scss';

const formSchema = yup.object().shape({
  name: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  address_1: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  city: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  state: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  zip: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
});

const CreateLocationModal = ({ isOpen, onClose, onSubmit, loading }) => {
  const nameRef = useRef(null);
  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      zip: '',
    },
  });

  useEffect(() => {
    setTimeout(() => {
      if (nameRef && nameRef.current) {
        nameRef.current.focus();
      }
    }, 100);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Create Location"
      hideClose
      centered
      wrapClassName={classes.modalWrapper}
    >
      <div className={classes.description}>
        <span>
          Locations for categorizing the different physical locations of your
          company. Sub-systems, equipment and documents are generally tied to a
          specific location and can be added at a later time.
        </span>
      </div>
      <form
        role="form"
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={classes.formInner}>
          <FormGroup>
            <FormInput
              label="Location Name*"
              name="name"
              placeholder="Enter location name"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
              innerRef={nameRef}
            />
          </FormGroup>
          <div className={classes.addressWrapper}>
            <FormGroup>
              <FormInput
                label="Location Address*"
                name="address_1"
                placeholder="Street Address"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
              />
            </FormGroup>
            <FormGroup>
              <FormInput
                name="address_2"
                placeholder="Ste, Apt, Bld"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
              />
            </FormGroup>
            <div className={classes.addressLastCol}>
              <FormGroup>
                <FormInput
                  name="city"
                  placeholder="City"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                />
              </FormGroup>
              <div>
                <FormGroup>
                  <FormInput
                    name="state"
                    placeholder="State"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    name="zip"
                    placeholder="ZIP Code"
                    control={control}
                    radius="md"
                    variant="primary"
                    type="number"
                    fontSize="sm"
                    maxLength={5}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.formFooter}>
          <Button variant="primary" isLink type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={loading}
            disabled={!formState.isValid}
          >
            Create Location
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateLocationModal;
