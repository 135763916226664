import React from 'react';
import TableActionDropdown from 'src/components/Table/ActionDropdown';
import StatusTag from 'src/components/Tag/Status';

export const getColumns = ({ onView, onEdit }) => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: (val, obj) => {
        return <span>Name</span>;
      },
      sortable: true,
      width: 317,
    },
    {
      key: 'status',
      label: 'Status',
      render: (val) => <StatusTag value="active" />,
      sortable: true,
    },
    {
      key: 'updatedAt',
      label: 'Updated',
      render: (val) => 'Nov 26, 2024',
      sortable: true,
      width: 200,
    },
    {
      key: 'updatedBy',
      label: 'Updated by',
      render: (val) => 'Shane McGonigal',
      sortable: true,
      width: 180,
    },
    {
      key: 'action',
      width: 72,
      render: (_, obj) => {
        return (
          <TableActionDropdown
            options={[
              {
                key: 'view',
                label: 'View',
                onClick: () => {
                  if (onView) onView(obj);
                },
              },
              {
                key: 'edit',
                label: 'Edit',
                onClick: () => {
                  if (onEdit) onEdit(obj);
                },
              },
            ]}
          />
        );
      },
    },
  ];
};
