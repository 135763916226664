import Request from './request';

export const apiGetWorkflows = async (params) => {
  return Request.call({
    url: '/api/workflows',
    method: 'GET',
    params,
  });
};
export const apiGetWorkflowDetails = async (id, locationId) => {
  return Request.call({
    url: `/api/workflow/${id}/${locationId}`,
    method: 'GET',
  });
};
export const apiGetWorkflowsDetails = async (id) => {
  return Request.call({
    url: `/api/workflows/${id}`,
    method: 'GET',
  });
};
export const apiCreateWorkflow = async (data) => {
  return Request.call({
    url: `/api/workflows/${data.slug}`,
    method: 'POST',
    data,
  });
};
export const apiDeleteWorkflow = async (id) => {
  return Request.call({
    url: `/api/workflows/${id}`,
    method: 'DELETE',
    data: {},
  });
};
