import cn from 'classnames';
import { useController } from 'react-hook-form';
import Select from 'src/components/FormFields/Select';
import useDynamicMenu from 'src/helper/hooks/useDynamicMenu';
import classes from './Select.module.scss';

function SelectLocation({
  className,
  onChange,
  control,
  name,
  required,
  label,
}) {
  const { scopeFilteredMenus } = useDynamicMenu();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const value = field.value;

  return (
    <Select
      required={required}
      className={cn(classes.selectLocationWrapper, className)}
      options={scopeFilteredMenus}
      placeholder="Select Locations"
      label={label}
      hideArrow
      isMulti
      selectedWithCheck
      value={value}
      closeMenuOnSelect={false}
      hasSelectAll
      checkedAll={
        value?.length > 0 && value?.length === scopeFilteredMenus.length
      }
      onCheckedAllChange={(tChecked) => {
        const isMin = value?.length == 1 && !tChecked;
        if (!isMin) {
          onChange(tChecked ? scopeFilteredMenus : [scopeFilteredMenus[0]]);
        }
      }}
      multiLabel="locations selected"
      onChange={(val) => {
        const isMin = value.length === 1 && !val?.length;
        if (!isMin) {
          onChange(val?.length === 0 ? [scopeFilteredMenus[0]] : val);
        }
      }}
    />
  );
}

export default SelectLocation;
