import React from 'react';
import classes from './MultipleAnswerQuestion.module.scss';

const MultipleAnswerQuestion = React.forwardRef((props, ref) => {
  const { disabled, read_only, name } = props;
  let classNames = 'custom-control custom-radio';
  const handleChange = (key, value) => {
    const tValue = ref?.current?.value || '{"score": "", "answer": ""}';
    const valueObj = JSON.parse(tValue);
    valueObj[key] = value;
    if (ref.current) {
      ref.current.value = JSON.stringify(valueObj);
    }
  };
  return (
    <div>
      <input type="text" style={{ display: 'none' }} ref={ref} name={name} />
      {(props.data.options || []).map((option, index) => {
        const this_key = `preview_${option.key}`;

        return (
          <div className={classNames} key={this_key}>
            <input
              id={`fid_${this_key}`}
              className="custom-control-input"
              name={props.name}
              type="radio"
              value={option.value}
              onChange={(e) => {
                handleChange('answer', option.value);
              }}
              disabled={disabled || read_only}
            />
            <label className="custom-control-label" htmlFor={`fid_${this_key}`}>
              {option.text}
            </label>
          </div>
        );
      })}
    </div>
  );
});

export default MultipleAnswerQuestion;
