import { apiGetDocument, apiGetDocuments, apiPostDocument } from 'src/api';
import Types from '../types/repository';

export const getDocuments = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_DOCUMENTS,
    asyncCall: () => {
      return apiGetDocuments(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

export const postDocument = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_DOCUMENT,
    asyncCall: () => {
      return apiPostDocument(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

export const getDocument = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_DOCUMENT_DETAILS,
    asyncCall: () => {
      return apiGetDocument(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
