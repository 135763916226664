import cn from 'classnames';
import { forwardRef, useState } from 'react';
import { ReactComponent as EyeSlashSvg } from 'src/assets/icons/eye-slash.svg';
import { ReactComponent as EyeSvg } from 'src/assets/icons/eye.svg';
import InputSuffix from 'src/components/InputSuffix';
import Input from '../Input';
import InputGroup from '../InputGroup';

import classes from './InputPassword.module.scss';

const InputPassword = forwardRef(({ className, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup className={cn(classes.wrapper, className)}>
      <Input type={showPassword ? 'text' : 'password'} ref={ref} {...props} />
      <InputSuffix
        className={cn(classes.suffix, {
          [classes.withLabel]: !!props.label,
        })}
      >
        <span
          onClick={() => setShowPassword(!showPassword)}
          className={classes.eyeIcon}
        >
          {!showPassword ? <EyeSlashSvg /> : <EyeSvg />}
        </span>
      </InputSuffix>
    </InputGroup>
  );
});

export default InputPassword;
