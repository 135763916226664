import * as executionActions from 'src/store/actions/execution';
import * as workflowActions from 'src/store/actions/workflow';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    executionDetails: useReducerData('execution', 'executionDetails.data', {}),
    workflowDetails: useReducerData('workflow', 'workflowDetails.data', {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...workflowActions,
    ...executionActions,
  });
};
