/* eslint-disable no-restricted-imports */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import PageTitle from 'src/components/Page/PageTitle';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import { useReducerData } from 'src/store/hooks';
import classes from '../../CreateWorkflow.module.scss';

const InformationForm = ({ workflowInfoChanged, workflow }) => {
  const menusData = useReducerData('auth', 'login.data.menus_data', []);
  const formSchema = yup.object().shape({
    description: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  });

  const scopeFilteredMenus = [];
  menusData.forEach((company) => {
    company.regions.forEach((region) => {
      region.locations.forEach((location) => {
        // TODO: check for pemissions
        // const menus = getLocationMenus(location);
        scopeFilteredMenus.push({
          value: location.id,
          label: `${company.name} ➡ ${region.name} ➡ ${location.name}`,
        });
      });
    });
  });
  // debugger
  const defaultValues = {
    description: workflow?.information?.description || '',
    taskDescription: workflow?.information?.taskDescription || '',
    taskTitle: workflow?.information?.taskTitle || '',
    workflowType: workflow?.information?.workflowType || 'test',
    requiresSupervisorReview:
      workflow?.information?.requiresSupervisorReview || false,
    locations: workflow?.information?.form?.locations
      ? scopeFilteredMenus.filter(
          (m) =>
            workflow?.information?.form?.locations
              .map((l) => l.value)
              .indexOf(m.value) > -1
        )
      : [],
  };

  const onSubmit = async (values) => {};

  const { handleSubmit, control, watch, setValue, formState, reset, register } =
    useForm({
      resolver: yupResolver(formSchema),
      defaultValues,
    });
  const allValues = watch();

  useEffect(() => {
    if (allValues) {
      workflowInfoChanged('form', allValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues]);

  useEffect(() => {
    if (workflow) {
      reset({
        description: workflow?.information?.description || '',
        taskDescription: workflow?.information?.taskDescription || '',
        taskTitle: workflow?.information?.taskTitle || '',
        requiresSupervisorReview:
          workflow?.information?.requiresSupervisorReview || false,
        workflowType: workflow?.information?.workflowType || 'test',
        locations: workflow?.information?.form?.locations
          ? scopeFilteredMenus.filter(
              (m) =>
                workflow?.information?.form?.locations
                  .map((l) => l.value)
                  .indexOf(m.value) > -1
            )
          : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow, reset]);

  return (
    <>
      {/* <PageTitle
        className={classes.pageTitle}
        subTitle={
          <>
            Workflows ...,
            <br />
            ...
          </>
        }
      >
        Edit Workflow
      </PageTitle> */}
      <form
        role="form"
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>Status: {workflow.status}</div>
        <div className={classes.formInner}>
          <FormGroup>
            Type:{' '}
            <Controller
              name="workflowType"
              control={control}
              defaultValue={workflow?.workflowType || 'test'} // Correct default value
              render={({ field }) => (
                <select
                  {...field}
                  className={classes.select}
                  onChange={(w) =>
                    workflowInfoChanged('workflowType', w.target.value)
                  }
                >
                  <option value="test">Test</option>
                  <option value="audit">Audit</option>
                  <option value="investigation">Investigation</option>
                </select>
              )}
            />
          </FormGroup>
          <br />
          <FormGroup>
            {/* <FormInput
              label="Locations"
              name="locations"
              placeholder="Currently comma separated location IDs to be replaced with a multiselect"
              control={control}
              type="text"
              radius="md"
              variant="primary"
              fontSize="sm"
              className={classes.descriptionInput}
              onChange={(w) =>
                workflowInfoChanged(
                  'locations',
                  w.target.value
                    .split(',')
                    .map((num) => parseInt(num.trim(), 10))
                    .filter((l) => !isNaN(l))
                )
              }
            /> */}
            <FormSelect
              options={scopeFilteredMenus}
              label="Select scope"
              name="locations"
              placeholder="Select Locations"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
              autoFocus
              isMulti
              multiLabel="locations selected"
              onChange={(w) => {
                setValue('locations', w);
                workflowInfoChanged(
                  'locations',
                  w.map((l) => parseInt(l.value))
                );
              }}
            />
          </FormGroup>
          <br />

          <FormGroup>
            <FormInput
              label="High Level Description"
              name="description"
              placeholder="Short description of the workflow, instructions, expectations, time frame, ..."
              control={control}
              type="textarea"
              radius="md"
              variant="primary"
              fontSize="sm"
              className={classes.descriptionInput}
              onChange={(w) =>
                workflowInfoChanged('description', w.target.value)
              }
            />
          </FormGroup>
          <br />
          <FormGroup>
            <Controller
              name="requiresSupervisorReview"
              control={control}
              defaultValue={
                workflow?.information?.requiresSupervisorReview || false
              } // Default to `false` if not provided
              render={({ field }) => (
                <label>
                  <input
                    type="checkbox"
                    {...field} // Connects the checkbox to `react-hook-form`
                    checked={!!field.value} // Ensures it's a controlled component
                    onChange={(w) => {
                      const isChecked = w.target.checked; // Use `checked` for checkboxes
                      workflowInfoChanged(
                        'requiresSupervisorReview',
                        isChecked
                      );
                      field.onChange(isChecked); // Update the field's value in `react-hook-form`
                    }}
                  />{' '}
                  Requires Supervisor Review on Completion
                </label>
              )}
            />
            <div className={classes.infoBox}>
              ℹ️ If checked, on completion, the supevisor of the completing user
              will be tasked with reviewing and approving this task
            </div>
          </FormGroup>

          <hr />
          <h2>Task meta</h2>
          <FormGroup>
            <FormInput
              label="Task Title"
              name="taskTitle"
              placeholder="Title to be shown on calendars and adjenda, e.g. take onboarding test, or audit system A1"
              control={control}
              type="text"
              radius="md"
              variant="primary"
              fontSize="sm"
              className={classes.descriptionInput}
              onChange={(w) => workflowInfoChanged('taskTitle', w.target.value)}
            />
          </FormGroup>
          <br />

          <FormGroup>
            <FormInput
              label="Task Description"
              name="taskDescription"
              placeholder="Enter task description"
              control={control}
              type="textarea"
              radius="md"
              variant="primary"
              fontSize="sm"
              className={classes.descriptionInput}
              onChange={(w) =>
                workflowInfoChanged('taskDescription', w.target.value)
              }
            />
          </FormGroup>
        </div>
        <br />
      </form>
    </>
  );
};

export default InformationForm;
