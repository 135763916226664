import pick from 'lodash/pick';
import { useEffect, useRef, useState } from 'react';
import NotificationAlert from 'react-notification-alert';
import { useParams } from 'react-router-dom';
import { ReactComponent as ChartSvg } from 'src/assets/icons/chart.svg';
import { ReactComponent as LocationSvg } from 'src/assets/icons/location.svg';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import SearchInput from 'src/components/FormFields/Input/SearchInput';
import Loading from 'src/components/Loading';
import Map from 'src/components/Map';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import Score from 'src/components/Score';
import ScoreChart from 'src/components/Score/Chart';
import Table from 'src/components/Table';
import Tabs from 'src/components/Tabs';
import Tag from 'src/components/Tag';
import useRegionOptions from 'src/helper/hooks/useRegionOptions';
import { getLocationPayload } from 'src/pages/CreateRegion/utils';
import RegionCard from 'src/pages/Regions/components/Card';
import { REGION_TAB_KEYS } from 'src/pages/Regions/constants';
import { notifyPrimary } from 'src/utils/notification';
import classes from './LocationDetails.module.scss';
import TabContent from './components/TabContent';
import { LOCATION_DETAILS_TABS, LOCATION_DETAILS_TAB_KEYS } from './constants';
import { useActions, useIndexData } from './selectorData';

const LocationDetails = () => {
  const { regionOptions } = useRegionOptions();
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const locationId = Number(params.id || 0);
  const { getLocationDetails } = useActions();
  const { locationDetails, locationDetailsLoading } = useIndexData();
  // temporary way to get region name, it should be at BE response
  const regionName = regionOptions.find(
    (obj) => obj.value === locationDetails?.region_id
  )?.label;
  const [activeTab, setActiveTab] = useState();
  const breadcrumbs = [
    {
      link: `/locations`,
      label: 'Locations',
    },
    {
      label: locationDetails.name,
    },
  ];
  const items = LOCATION_DETAILS_TABS.map((obj) => {
    return {
      key: obj.key,
      title: obj.title,
      content: <TabContent tabKey={obj.key} />,
    };
  });

  useEffect(() => {
    getLocationDetails(locationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {locationDetailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <div className={classes.pageTitleWrapper}>
          <PageTitle
            subTitle={
              <span className={classes.subTitle}>
                <span>
                  An Americold refrigeration facility specializes in
                  temperature-controlled storage and logistics, supporting the
                  distribution of perishable goods efficiently and reliably.
                </span>
                <span>
                  <span>Hour</span>
                  <span>Mon - Fri: 9:00 AM - 6:00 PM</span>
                </span>
              </span>
            }
          >
            {locationDetails.name}
            {!!regionName && <Tag>{regionName}</Tag>}
          </PageTitle>
        </div>
        <div className={classes.overviewWrapper}>
          <Card
            title="Omni Score"
            titleIcon={<ChartSvg />}
            headerRight="Last 30 Days"
            contentAlign="horizontal"
          >
            <Score
              score={90}
              width={226}
              activeColor={'#45B942'}
              issuesColor={'#C1FFC0'}
              color="#EEEEEE"
              title=" "
              className={classes.score}
              issues={5}
            />
            <div className={classes.scoreRight}>
              <ScoreChart
                scores={[
                  100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100, 100, 100,
                  100, 100, 100, 100, 100, 100, 100, 60, 60, 60, 30, 30, 100,
                  100, 100, 60, 60,
                ]}
              />
              <div>
                <div>
                  No Issues in the last <b>4</b> days
                </div>
                <div>
                  <span>
                    <b>Nov 17</b> - Ammonia Leak
                  </span>
                  <span>Details</span>
                </div>
                <div>
                  <span>
                    <b>Nov 6</b> - Ammonia Leak
                  </span>
                  <span>Details</span>
                </div>
                <div>
                  <span>
                    <b>Nov 5</b> - Ammonia Leak
                  </span>
                  <span>Details</span>
                </div>
                <div>
                  <span>
                    <b>Nov 4</b> - Ammonia Leak
                  </span>
                  <span>Details</span>
                </div>
              </div>
            </div>
          </Card>
          <Card
            title="Location"
            titleIcon={<LocationSvg width={24} height={24} />}
          >
            <Map className={classes.map} />
          </Card>
        </div>
        <Tabs
          items={items}
          activeTab={activeTab}
          onChange={(val) => {
            setActiveTab(val);
          }}
          headerFullWidth
          headerSize="sm"
        />
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default LocationDetails;
