import {
  apiCreateTask,
  apiDeleteTask,
  apiGetTasks,
  apiStartTask,
  apiUpdateTask,
} from 'src/api';
import Types from '../types/tasks.js';

export const getTasks = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_TASKS,
    asyncCall: () => {
      return apiGetTasks(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

export const startTask = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.START_TASK,
    asyncCall: () => {
      return apiStartTask(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

export const createTask = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_TASK,
    asyncCall: () => {
      return apiCreateTask(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

export const deleteTask = (taskId, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.DELETE_TASK,
    asyncCall: () => {
      return apiDeleteTask(taskId);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};

export const updateTask = (taskId, params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.UPDATE_TASK,
    asyncCall: () => {
      return apiUpdateTask(taskId, params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
