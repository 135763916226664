import cn from 'classnames';
import { Doughnut as ChartDoughnut } from 'react-chartjs-2';
import classes from './Chart.module.scss';

const chartData = {
  labels: ['Gas Leaks', 'Human Injury', 'Vapor Release'],
  datasets: [
    {
      data: [2, 2, 1],
      backgroundColor: ['#082A15', '#99A096', '#52565B'],
      borderColor: ['#082A15', '#99A096', '#52565B'],
    },
  ],
};
const plugins = [
  {
    beforeDraw: function (chart) {
      const width = chart.width;
      const height = chart.height;
      const ctx = chart.ctx;
      ctx.restore();
      ctx.font = '800 5em Inter';
      ctx.textBaseline = 'top';
      const text = '5';
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 - 60;
      ctx.fillText(text, textX, textY);
      ctx.font = '400 1.5em Inter';
      ctx.textBaseline = 'top';
      const text1 = 'Open incidents';
      const text1X = Math.round((width - ctx.measureText(text1).width) / 2);
      const text1Y = height / 2 + 20;
      ctx.fillText(text1, text1X, text1Y);

      ctx.save();
    },
  },
];

const DoughnutChart = ({ className }) => {
  return (
    <div className={cn(classes.doughnutChartWrapper, className)}>
      <ChartDoughnut
        data={chartData}
        width={100}
        height={100}
        options={{
          cutoutPercentage: 85,
          legend: false,
        }}
        plugins={plugins}
      />
      <div className={classes.doughnutLegends}>
        <span>
          <span style={{ background: '#082A15' }}></span>
          <span>Gas Leaks</span>
          <span>2</span>
        </span>
        <span>
          <span style={{ background: '#AB8A44' }}></span>
          <span>Explosions</span>
          <span>0</span>
        </span>
        <span>
          <span style={{ background: '#52565B' }}></span>
          <span>Vapor Release</span>
          <span>1</span>
        </span>
        <span>
          <span style={{ background: '#99A096' }}></span>
          <span>Human Injury</span>
          <span>2</span>
        </span>
        <span>
          <span style={{ background: '#D8D8C6' }}></span>
          <span>Other</span>
          <span>0</span>
        </span>
      </div>
    </div>
  );
};

export default DoughnutChart;
