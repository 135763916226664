import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/region';

const initialState = {
  regions: {
    ...defaultObj,
  },
  regionDetails: {
    ...defaultObj,
  },
  regionLocations: {
    ...defaultObj,
  },
  createRegion: {
    ...defaultObj,
  },
  updateRegion: {
    ...defaultObj,
  },
  deleteRegion: {
    ...defaultObj,
  },
};

const RegionReducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.SYNC_UPDATE_REGION_DETAILS: {
      return {
        ...state,
        regionDetails: {
          ...state.regionDetails,
          data: {
            ...state.regionDetails.data,
            ...payload,
          },
        },
      };
    }
    case Types.SYNC_REMOVE_REGIONS: {
      return {
        ...state,
        regions: {
          ...state.regions,
          data: {
            ...state.regions.data,
            data: state.regions.data.data.filter((obj) => obj.id !== payload),
            total: state.regions.data.total - 1,
          },
        },
      };
    }
    case Types.GET_REGIONS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          regions: {
            ...prevState.regions,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          regions: {
            loading: false,
            data: meta?.payload?.isMore
              ? {
                  ...prevState.regions.data,
                  ...payload,
                  data: [...prevState.regions.data.data, ...payload.data],
                }
              : payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          regions: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_REGION_DETAILS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          regionDetails: {
            ...prevState.regionDetails,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          regionDetails: {
            loading: false,
            data: payload.data,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          regionDetails: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_REGION_LOCATIONS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          regionLocations: {
            ...prevState.regionLocations,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          regionLocations: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          regionLocations: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CREATE_REGION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          createRegion: {
            ...prevState.createRegion,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          createRegion: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          createRegion: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_REGION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateRegion: {
            ...prevState.updateRegion,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateRegion: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateRegion: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_REGION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deleteRegion: {
            ...prevState.deleteRegion,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deleteRegion: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deleteRegion: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default RegionReducer;
