import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/workflow';

const initialState = {
  allWorkflows: {
    ...defaultObj,
  },
  workflows: {
    ...defaultObj,
  },
  workflowDetails: {
    ...defaultObj,
  },
  createWorkflow: {
    ...defaultObj,
  },
  updateWorkflow: {
    ...defaultObj,
  },
  deleteWorkflow: {
    ...defaultObj,
  },
};

const WorkflowReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_WORKFLOWS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          workflows: {
            ...prevState.workflows,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          workflows: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          workflows: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_WORKFLOW_DETAILS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          workflowDetails: {
            ...prevState.workflowDetails,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          workflowDetails: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          workflowDetails: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CREATE_WORKFLOW:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          createWorkflow: {
            ...prevState.createWorkflow,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          createWorkflow: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          createWorkflow: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_WORKFLOW:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateWorkflow: {
            ...prevState.updateWorkflow,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateWorkflow: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateWorkflow: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_WORKFLOW:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deleteWorkflow: {
            ...prevState.deleteWorkflow,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deleteWorkflow: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deleteWorkflow: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default WorkflowReducer;
