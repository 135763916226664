/* eslint-disable no-restricted-imports */
/* eslint-disable no-console */
import { highlight, languages } from 'prismjs/components/prism-core';
import { useCallback, useEffect, useState } from 'react';
import {
  FormBuilderPostData,
  ReactFormBuilder,
  ToolbarItem,
} from 'react-form-builder2';
import Editor from 'react-simple-code-editor';
import { FORM_BUILDER_DEFAULT_OPTIONS } from 'src/helper/constants';
import MultipleAnswerQuestion from './CustomFields/MultipleAnswerQuestion';
import FormElementsEdit from './FormElementsEdit';
import DynamicPreviewForm from './PreviewForm';
import Suggest from './suggest/index.js';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'react-form-builder2/dist/app.css';
import 'prismjs/themes/prism.css';

const items = [
  {
    key: 'TextInput',
  },
  {
    key: 'EmailInput',
  },
  {
    key: 'PhoneNumber',
    name: 'Phone',
  },
  {
    key: 'NumberInput',
  },
  {
    key: 'TextArea',
    name: 'Multi-line',
  },
  {
    key: 'Dropdown',
  },
  {
    key: 'Checkboxes',
  },
  {
    key: 'DatePicker',
  },
  {
    key: 'RadioButtons',
    name: 'Radio choice',
  },
  {
    key: 'FileUpload',
  },
  {
    key: 'Range',
  },
  {
    group_name: 'Advanced',
    key: 'Signature',
  },
  {
    group_name: 'Advanced',
    key: 'Rating',
  },
  {
    group_name: 'Advanced',
    key: 'Tags',
  },
  {
    group_name: 'Advanced',
    key: 'HyperLink',
  },
  {
    group_name: 'Advanced',
    key: 'Download',
  },
  {
    group_name: 'Advanced',
    key: 'Camera',
  },
  {
    group_name: 'Design',
    key: 'Header',
  },
  {
    group_name: 'Design',
    key: 'Image',
  },
  {
    group_name: 'Design',
    key: 'Paragraph',
  },
  {
    group_name: 'Design',
    key: 'Label',
  },
  {
    group_name: 'Design',
    key: 'LineBreak',
  },
  {
    group_name: 'Multi Column',
    key: 'TwoColumnRow',
  },
  {
    group_name: 'Multi Column',
    key: 'ThreeColumnRow',
  },
  {
    group_name: 'Multi Column',
    key: 'FourColumnRow',
    element: 'MultiColumnRow',
  },
  {
    group_name: 'Multi Column',
    key: 'FiveColumnRow',
    element: 'MultiColumnRow',
  },
  {
    group_name: 'Multi Column',
    key: 'SixColumnRow',
    element: 'MultiColumnRow',
  },
  {
    key: 'MultipleAnswerQuestion',
    element: 'CustomElement',
    component: MultipleAnswerQuestion,
    type: 'custom',
    forwardRef: true,
    field_name: 'multiple_answer_question_',
    name: 'Multiple Answer Question',
    icon: 'fa fa-cog',
    props: {},
    label: 'Placeholder label',
    options: FORM_BUILDER_DEFAULT_OPTIONS,
  },
];

const NodeInfo = ({
  focusedNode: initialFocusedNode,
  onNodeUpdate,
  nodes,
  counter,
}) => {
  const codeInstructions = `// edit this code using field values by name\n// perserving case, and replacing spaces with uderscores \n\nif(Case_sansitive_field_name == ´value´) {\n  \n}\n\n// use loops, conditions, and functions, e.g.\n\nfunction add(a, b) {\n  return a + b;\n}\n\n`;
  const [nodeTypeTab, setNodeTypeTab] = useState('form');
  const [code, _setCode] = useState(
    initialFocusedNode?.data?.js_code || codeInstructions
  );
  const [focusedNode, setFocusedNode] = useState(initialFocusedNode);
  useEffect(() => {
    console.log('initialFocusedNode 1:', focusedNode?.data?.ui_group);
    // Only update state if it's actually different
    if (focusedNode !== initialFocusedNode) {
      setFocusedNode({
        ...initialFocusedNode,
        // data: {
        //   ...initialFocusedNode.data,
        //   task_data: [],
        // },
      });
      _setCode(
        initialFocusedNode?.data?.js_code ||
          `function add(a, b) {\n  return a + b;\n}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFocusedNode, counter]); // Dependency array

  // Effect to log whenever focusedNode changes
  // useEffect(() => {
  //   console.log('focusedNode updated:', focusedNode?.data?.label);
  // }, [focusedNode]); // Dependency array

  const setCode = useCallback(
    (code) => {
      setFocusedNode((prevNode) => {
        const updatedNode = {
          ...prevNode,
          data: { ...prevNode.data, js_code: code },
        };
        onNodeUpdate(updatedNode); // Call the prop function
        _setCode(code);
        return updatedNode;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onNodeUpdate, counter]
  ); // FocusedNode is not needed as a dependency here

  const onSelectNodeType = useCallback(
    (type, subSubType) => {
      const updatedNode = {
        ...focusedNode,
        data: { ...focusedNode.data, subType: type, subSubType },
      };
      setFocusedNode(updatedNode); // Update local state
      onNodeUpdate(updatedNode);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [focusedNode, initialFocusedNode, counter]
  );

  const onChangeHandler = useCallback(
    (data) => {
      setFocusedNode((prevNode) => {
        const updatedNode = {
          ...prevNode,
          data: { ...prevNode.data, task_data: data.task_data },
        };
        onNodeUpdate(updatedNode); // Call the prop function
        return updatedNode;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onNodeUpdate, counter]
  ); // FocusedNode is not needed as a dependency here

  const onSharedValueChanged = useCallback(
    (key, value) => {
      const updatedNode = {
        ...focusedNode,
        data: { ...focusedNode.data, [key]: value },
      };
      setFocusedNode(updatedNode); // Update local state
      onNodeUpdate(updatedNode); // Call the prop function
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [focusedNode, initialFocusedNode, onNodeUpdate, counter]
  );

  return (
    <div>
      <h3>Node info</h3>
      <hr></hr>
      <br />

      {focusedNode && !focusedNode.data?.subType && (
        <div>
          {/* {focusedNode && JSON.stringify(focusedNode, null, 2)} */}
          <p>Select node type</p>
          <div className="note-types">
            <div className="card text-left">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                  <li className="nav-item">
                    <a
                      className={
                        'nav-link' + (nodeTypeTab === 'form' ? ' active' : '')
                      }
                      aria-current={nodeTypeTab === 'form'}
                      href="#"
                      onClick={() => setNodeTypeTab('form')}
                    >
                      Form
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        'nav-link' +
                        (nodeTypeTab === 'condition' ? ' active' : '')
                      }
                      aria-current={nodeTypeTab === 'condition'}
                      href="#"
                      onClick={() => setNodeTypeTab('condition')}
                    >
                      Condition
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        'nav-link' + (nodeTypeTab === 'action' ? ' active' : '')
                      }
                      aria-current={nodeTypeTab === 'action'}
                      href="#"
                      onClick={() => setNodeTypeTab('action')}
                    >
                      Action
                    </a>
                  </li>
                </ul>
              </div>
              {nodeTypeTab === 'form' && (
                <div className="card-body">
                  <h5 className="card-title">Create form node</h5>
                  <hr />
                  <br />
                  <p className="card-text">
                    Forms are where system users can enter data to record their
                    activity and progress.
                  </p>
                  <p className="card-text">
                    <a
                      href="#"
                      className="btn btn-primary"
                      onClick={() => onSelectNodeType('dnode_form')}
                    >
                      Define a new form
                    </a>
                  </p>
                  <p className="card-text">
                    or select one of the template forms:
                  </p>
                  <div className="card-header">Template forms</div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Approval form
                      <span className="btn btn-secondary">Select</span>
                    </li>
                  </ul>
                </div>
              )}
              {nodeTypeTab === 'condition' && (
                <div className="card-body">
                  <h5 className="card-title">Create condition node</h5>
                  <hr />
                  <br />
                  <p className="card-text">
                    Conditions are how the workflow is defined, for a form to be
                    presented to users or for an action to be performed, all the
                    conditions leading to it must be met.
                  </p>

                  <div className="card-header">Condition types</div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Check form field value
                      <span
                        className="btn btn-secondary"
                        onClick={() =>
                          onSelectNodeType('dnode_cond', 'field_value')
                        }
                      >
                        Select
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      When the form is submitted
                      <span
                        className="btn btn-secondary"
                        onClick={() =>
                          onSelectNodeType('dnode_cond', 'form_submitted')
                        }
                      >
                        Select
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Time elapsed
                      <span
                        className="btn btn-secondary"
                        onClick={() =>
                          onSelectNodeType('dnode_cond', 'time_elapsed')
                        }
                      >
                        Select
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Custom code
                      <span
                        className="btn btn-secondary"
                        onClick={() => onSelectNodeType('dnode_cond', 'code')}
                      >
                        Select
                      </span>
                    </li>
                  </ul>
                </div>
              )}
              {nodeTypeTab === 'action' && (
                <div className="card-body">
                  <h5 className="card-title">Create action node</h5>
                  <hr />
                  <br />
                  <p className="card-text">
                    Actions are commands that are executed when all preceeding
                    conditions are met.
                  </p>
                  <div className="card-header">Action types</div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Send email
                      <span
                        className="btn btn-secondary"
                        onClick={() =>
                          onSelectNodeType('dnode_action', 'email')
                        }
                      >
                        Select
                      </span>
                    </li>
                    {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                      Raise a flag
                      <span
                        className="btn btn-secondary"
                        onClick={() => onSelectNodeType('dnode_action', 'flag')}
                      >
                        Select
                      </span>
                    </li> */}
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Change field value
                      <span
                        className="btn btn-secondary"
                        onClick={() =>
                          onSelectNodeType('dnode_action', 'field_value')
                        }
                      >
                        Select
                      </span>
                    </li>
                    {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                      Re initialize form
                      <span
                        className="btn btn-secondary"
                        onClick={() =>
                          onSelectNodeType('dnode_action', 'initialize_form')
                        }
                      >
                        Select
                      </span>
                    </li> */}
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Custom code
                      <span
                        className="btn btn-secondary"
                        onClick={() => onSelectNodeType('dnode_action', 'code')}
                      >
                        Select
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {focusedNode && focusedNode.data?.subType && (
        <div className="card-body p-0 pb-3">
          <div className="form-floating mb-4">
            <input
              className="form-control"
              id="floatingInput"
              value={focusedNode?.data.label}
              onChange={(e) => onSharedValueChanged('label', e.target.value)}
              // focusedNode={focusedNode}
            />
            <label>Title</label>
          </div>
          <div className="form-floating w-50 d-inline-block">
            <Suggest
              className="form-control"
              hint=""
              nodes={nodes}
              allSuggestions={[
                {
                  text: 'Data entry',
                },
                {
                  text: 'Research',
                },
                {
                  text: 'Evaluation',
                },
                {
                  text: 'Contact contractors',
                },
                {
                  text: 'Exit',
                },
              ]}
              onChange={(v) => onSharedValueChanged('ui_group', v)}
              value={focusedNode.data.ui_group}
              focusedNode={focusedNode}
              counter={counter}
            />
            <label htmlFor="exampleFormControlInput1" className="form-label">
              UI Group
            </label>
          </div>
          <div className="form-floating w-50 d-inline-block">
            <input
              className="form-control"
              value={focusedNode?.data.description}
              onChange={(e) =>
                onSharedValueChanged('description', e.target.value)
              }
            />
            <label>Description</label>
          </div>
        </div>
      )}

      {focusedNode && focusedNode.data?.subType == 'dnode_form' && (
        <div>
          <DynamicPreviewForm data={focusedNode.data.task_data} />
          <br />
          <ReactFormBuilder
            focusedNode={focusedNode}
            toolbarItems={items}
            data={focusedNode.data.task_data}
            onPost={onChangeHandler}
            variables={{}}
            renderEditForm={(props) => <FormElementsEdit {...props} />}
          />
        </div>
      )}
      {focusedNode && focusedNode.data?.subType == 'dnode_cond' && (
        <div className="card-body p-0">
          <h5 className="card-title">Condition</h5>
          <hr />
          <br />
          <p className="card-text">
            Use form fields values in condition code using the field&apos;s name
            <br />
            Return TRUE or FALSE (also as a result of a comparison) to allow
            flow through the condition or to block it
          </p>
          <div className="card-header">Cheat sheet</div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Check form field value
              <span
                className="btn btn-secondary"
                onClick={() =>
                  setCode(
                    codeInstructions + 'Case_sensitive_field_name == ´value´'
                  )
                }
              >
                Select
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              When the form is submitted
              <span
                className="btn btn-secondary"
                onClick={() =>
                  setCode(
                    codeInstructions +
                      'Case_sensitive_form_name_form_status == ´submitted´'
                  )
                }
              >
                Select
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Time elapsed
              <span
                className="btn btn-secondary"
                onClick={() =>
                  setCode(
                    codeInstructions +
                      'Util.TimeElapsedSinceFirstEvaluation(number_of_days)'
                  )
                }
              >
                Select
              </span>
            </li>
          </ul>
          <br />

          <Editor
            value={code}
            onValueChange={(code) => setCode(code)}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              background: '#fff',
            }}
          />
        </div>
      )}
      {focusedNode && focusedNode.data?.subType == 'dnode_action' && (
        <div className="card-body p-0">
          <h5 className="card-title">Action</h5>
          <hr />
          <br />
          <p className="card-text">
            Use form fields values in action code using the field&apos;s name
          </p>
          <div className="card-header">Cheat sheet</div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Raise a flag
              <span
                className="btn btn-secondary"
                onClick={() =>
                  setCode(
                    codeInstructions +
                      'Util.RaiseFlag(´Warning´) // Overdue | Attention required'
                  )
                }
              >
                Select
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Change field value
              <span
                className="btn btn-secondary"
                onClick={() =>
                  setCode(
                    codeInstructions + 'Util.SetValue(´field name´, ´value´)'
                  )
                }
              >
                Select
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Send email
              <span
                className="btn btn-secondary"
                onClick={() =>
                  setCode(
                    codeInstructions +
                      'Util.SendEmail(´email@recepient.com´, ´subject´, ´message´)'
                  )
                }
              >
                Select
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Re initialize form
              <span
                className="btn btn-secondary"
                onClick={() =>
                  setCode(
                    codeInstructions + 'Util.ReinitializeForm(´form name´)'
                  )
                }
              >
                Select
              </span>
            </li>
          </ul>
          <br />

          <Editor
            value={code}
            onValueChange={(code) => setCode(code)}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              background: '#fff',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default NodeInfo;
