import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/menu';

const initialState = {
  menus: {
    ...defaultObj,
  },
  allMenus: {
    ...defaultObj,
  },
  menuDetails: {
    ...defaultObj,
  },
};

const MenuReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_MENUS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          menus: {
            ...prevState.menus,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          menus: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          menus: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_ALL_MENUS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          allMenus: {
            ...prevState.allMenus,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          allMenus: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          allMenus: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_MENU_DETAILS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          menuDetails: {
            ...prevState.menuDetails,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          menuDetails: {
            loading: false,
            data: payload.data,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          menuDetails: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default MenuReducer;
