import cn from 'classnames';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import classes from './Table.module.scss';

const TableActionDropdown = ({ options, className }) => {
  return (
    <Dropdown
      overlayClassName={cn(classes.actionOverlay, className)}
      items={options}
    >
      <MoreSvg />
    </Dropdown>
  );
};

export default TableActionDropdown;
