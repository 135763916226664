import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import NotificationAlert from 'react-notification-alert';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Dropdown from 'src/components/Dropdown';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import Table from 'src/components/Table';
import classes from './Workflow.module.scss';
import Flow from './components/Flow';
import { useActions, useIndexData } from './selectorData';

const Workflow = ({ className }) => {
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const workflowId = Number(params.id || 0);
  // const { getWorkflow } = useActions();
  const { workflow, workflowLoading } = useIndexData();
  const breadcrumbs = [
    {
      link: '/workflow',
      label: 'Workflow',
    },
    {
      label: workflow.name,
    },
  ];

  useEffect(() => {
    // getWorkflow(workflowId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumns = ({ onUnarchive }) => {
    return [
      {
        key: 'name',
        label: 'Workflow Name',
        render: (val, obj) => {
          return (
            <span className={classes.workflowColInner}>
              <span>Americold Logistics Boise</span>
              <span>123 S Main St. Boise, ID 83702</span>
            </span>
          );
        },
        sortable: true,
        width: 317,
      },
      {
        key: 'archiveReason',
        label: 'Archive reason',
        render: (val) =>
          'This workflow was shut down as we transferred all operaitions over to the new facility in meridian',
        sortable: true,
      },
      {
        key: 'archivedDate',
        label: 'Archived Date',
        render: (val) => 'Nov 26, 2024',
        sortable: true,
        width: 200,
      },
      {
        key: 'archivedBy',
        label: 'Archived by',
        render: (val) => 'Shane McGonigal',
        sortable: true,
        width: 180,
      },
      {
        key: 'action',
        width: 72,
        render: (_, obj) => {
          return (
            <Dropdown
              items={[
                {
                  key: 'unarchive',
                  label: 'Unarchive',
                  onClick: () => {
                    if (onUnarchive) onUnarchive(obj);
                  },
                },
              ]}
            >
              {/* <MoreSvg /> */}
            </Dropdown>
          );
        },
      },
    ];
  };

  const columns = getColumns({ onUnarchive: () => {} });
  const data = [{ key: 1, id: 1 }];
  const [checkedRows, setCheckedRows] = useState([]);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {workflowLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <div className={classes.workflows}>
          {/* <div className={cn(classes.wrapper, className)}>
            <Table
              columns={columns}
              data={data}
              checkable={{
                checkedRows,
                onChange: (cRows) => {
                  setCheckedRows(cRows);
                },
              }}
            />
          </div> */}
          <Flow />
        </div>
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default Workflow;
