import cn from 'classnames';
import PageTitle from 'src/components/Page/PageTitle';
import classes from './Title.module.scss';

const GenericCreateTitle = ({ className, children, ...other }) => {
  return (
    <PageTitle className={cn(classes.wrapper, className)} {...other}>
      {children}
    </PageTitle>
  );
};

export default GenericCreateTitle;
