import React from 'react';
import { Button } from 'reactstrap';
import TableActionDropdown from 'src/components/Table/ActionDropdown';
import StatusTag from 'src/components/Tag/Status';

export const getColumns = ({
  onView,
  onEdit,
  onTask,
  navigate,
  selectedLocationIds,
  allGroups,
}) => {
  return [
    {
      key: 'name',
      label: 'Name',
      render: (val, obj) => {
        return (
          <span>
            {/* {JSON.stringify(obj)} */}
            <i
              style={{
                border: '1px solid green',
                borderRadius: '4px',
                backgroundColor: 'lightgreen',
                color: 'white',
                fontWeight: 700,
                padding: '4px 0 4px 0',
                width: '26px',
                display: 'inline-block',
                textAlign: 'center',
              }}
            >
              {obj.slug.indexOf('xlsxpdf') > -1 ? (
                <>XL</>
              ) : obj.slug.indexOf('docxpdf') > -1 ? (
                <>W</>
              ) : (
                <>D</>
              )}
            </i>{' '}
            <a
              href={
                process.env.REACT_APP_API_BASE_URL +
                '/api/repository/document/' +
                obj._id +
                '/' +
                obj.slug
              }
              target="_blank"
              rel="noreferrer"
            >
              {obj.form?.title || val || 'Document Name'}
              {/* {JSON.stringify(obj)} */}
            </a>
          </span>
        );
      },
      sortable: true,
      width: 217,
      maxWidth: 217,
    },
    {
      key: 'locationIds',
      label: 'Locations',
      hidden: selectedLocationIds.length < 2,
      render: (val, obj) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          {/* {JSON.stringify(val)} */}
          {obj.form.locationIds?.map((p, tIndex) =>
            tIndex == 2 && obj.form.locationIds.length > 3 ? (
              <div key={tIndex}>
                +{obj.form.locationIds.length - 2} locations
              </div>
            ) : tIndex > 2 ? undefined : (
              <div key={tIndex}>{p.label}</div>
            )
          )}
        </div>
      ),
      sortable: true,
    },
    {
      key: 'subsystem',
      label: 'Sub Systems',
      render: (val, obj) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          {obj.form.subsystem?.map((p, tIndex) =>
            tIndex == 2 && obj.form.subsystem.length > 3 ? (
              <div key={tIndex}>+{obj.form.subsystem.length - 2} locations</div>
            ) : tIndex > 2 ? undefined : (
              <div key={tIndex}>{p.label}</div>
            )
          )}
        </div>
      ),
      sortable: true,
    },
    {
      key: 'parts',
      label: 'Parts',
      render: (val, obj) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          {obj.form.parts?.map((p, tIndex) =>
            tIndex == 2 && obj.form.parts.length > 3 ? (
              <div key={tIndex}>+{obj.form.parts.length - 2} locations</div>
            ) : tIndex > 2 ? undefined : (
              <div key={tIndex}>{p.label}</div>
            )
          )}
        </div>
      ),
      sortable: true,
    },
    {
      key: 'folder',
      label: 'Folder',
      render: (val) => val,
      sortable: true,
      hidden: !allGroups,
    },
    {
      key: 'updatedAt',
      label: 'Updated',
      render: (val) => val,
      sortable: true,
      width: 200,
      hidden: true,
    },
    {
      key: 'workflow',
      label: 'Execute',
      render: (val, obj) => (
        <>
          {val && (
            <Button
              onClick={() =>
                navigate('/workflows/execute/' + val, {
                  state: { docData: obj },
                })
              }
            >
              {' '}
              ▶️ Run {/* {val} */}
            </Button>
          )}
          <Button
            onClick={() => {
              onTask(obj);
            }}
          >
            ➡️ Task
          </Button>
          {/* {val} */}
        </>
      ),
      sortable: true,
      width: 180,
    },
    {
      key: 'action',
      width: 72,
      render: (_, obj) => {
        return (
          <TableActionDropdown
            options={[
              {
                key: 'versions',
                label: 'Versions',
                onClick: () => {
                  if (onView) onView(obj);
                },
              },
              {
                key: 'edit',
                label: 'Edit',
                onClick: () => {
                  if (onEdit) onEdit(obj);
                },
              },
            ]}
          />
        );
      },
    },
  ];
};
