import omit from 'lodash/omit';
import React from 'react';
import { MENU_ACTION_KEYS } from 'src/helper/constants/menu';
import { getRealLocationId } from 'src/helper/providers/utils';
import { getSelectValues } from 'src/utils/form';

export const getCreateRolePayload = (values) => {
  const returnObj = {
    ...omit(values, ['menu_items']),
    location_ids: getSelectValues(values?.location_ids).map((id) =>
      getRealLocationId(id)
    ),
  };
  return returnObj;
};
export const getAssignedMenuItemsPayload = (items) => {
  return (items || []).map((obj) => {
    const tActions = obj.actions || [];
    return {
      menu_item_id: obj.id,
      permissions: {
        view: tActions.includes(MENU_ACTION_KEYS.VIEW) ? 1 : 0,
        add: tActions.includes(MENU_ACTION_KEYS.ADD) ? 1 : 0,
        edit: tActions.includes(MENU_ACTION_KEYS.EDIT) ? 1 : 0,
        delete: tActions.includes(MENU_ACTION_KEYS.DELETE) ? 1 : 0,
        execute: 0,
      },
    };
  });
};

export const getTitle = ({ isViewDetails, isEdit, roleDetails }) => {
  if (isViewDetails) return `View Details - ${roleDetails?.role_name}`;
  return isEdit ? `Edit Role - ${roleDetails?.role_name}` : 'Create Role';
};
export const getSubTitle = ({ isEdit, roleDetails }) => {
  if (isEdit) return roleDetails?.description || '-';
  return (
    <>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum
      <br />
      has been the industry's standard dummy text ever since the 1500s, when an
      unknown printer
      <br />
      took a galley of type and scrambled it to make a type specimen book.
    </>
  );
};
export const getBreadcrumbText = ({ isViewDetails, isEdit }) => {
  if (isViewDetails) return 'Role View Details';
  return isEdit ? 'Role Edit' : 'Role Create';
};
