import cn from 'classnames';
import isArray from 'lodash/isArray';
import { Table as RsTable } from 'reactstrap';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import { ReactComponent as SortSvg } from 'src/assets/icons/sort.svg';
import Checkbox from 'src/components/FormFields/Checkbox';
import Loading from 'src/components/Loading';
import classes from './Table.module.scss';

const getWidth = (val) => {
  return val ? `${typeof val === 'string' ? val : `${val}px`}` : undefined;
};
const getWidthStyles = (obj) => {
  return {
    minWidth: getWidth(obj.width),
    maxWidth: getWidth(obj.maxWidth),
  };
};

const Table = ({
  className,
  columns = [],
  data = [],
  checkable,
  sort,
  onSortChange,
  loading,
}) => {
  if (!isArray(data)) return '';
  const tCheckedRows = checkable?.checkedRows || [];
  const handleCheckedChange = checkable.onChange
    ? checkable.onChange
    : () => {};
  return (
    <div className={cn(classes.wrapper, className)}>
      {loading && (
        <div className={classes.loading}>
          <div>
            <Loading />
          </div>
        </div>
      )}
      <RsTable className="align-items-center table-flush" responsive>
        <colgroup>
          {columns.map(({ width, maxWidth }, index) => {
            return (
              <col style={getWidthStyles({ width, maxWidth })} key={index} />
            );
          })}
        </colgroup>
        <thead className="thead-light">
          <tr>
            {columns.map(
              (
                { label, key, sortable, defaultSort, width, maxWidth },
                index
              ) => {
                const tSortMethod =
                  sort?.field === key ? sort?.method : defaultSort || '';
                if (key === 'action') {
                  return <th scope="col" key={key} />;
                }
                return (
                  <th
                    scope="col"
                    key={key}
                    style={getWidthStyles({ width, maxWidth })}
                  >
                    <div className={classes.columnHeaderInner}>
                      {index === 0 && checkable && (
                        <Checkbox
                          variant="primary"
                          id="table-check-all"
                          className={classes.checkbox}
                          checked={
                            tCheckedRows.length === data?.length &&
                            data?.length > 0
                          }
                          onChange={(e) => {
                            handleCheckedChange(e.target.checked ? data : []);
                          }}
                        />
                      )}
                      <span>{label}</span>
                      {sortable && (
                        <SortSvg
                          className={cn(classes.sortIcon, {
                            [classes.hasSort]: !sort?.field
                              ? !!defaultSort
                              : !!sort?.field && sort?.field === key,
                            [classes.sortASC]: tSortMethod === 'asc',
                            [classes.sortDESC]: tSortMethod === 'desc',
                          })}
                          onClick={() => {
                            if (onSortChange)
                              onSortChange(
                                key,
                                tSortMethod === 'asc' ? 'desc' : 'asc'
                              );
                          }}
                        />
                      )}
                    </div>
                  </th>
                );
              }
            )}
          </tr>
        </thead>
        <tbody className="list">
          {data.map((d) => {
            return (
              <tr key={d.id}>
                {columns.map((column, index) => {
                  const tVal = d[column.key] || '';
                  const tKey = `${column.key}_${d.id}`;
                  if (column.key === 'action') {
                    return (
                      <td key={tKey} style={getWidthStyles(column)}>
                        {column.render ? column.render('', d) : <MoreSvg />}
                      </td>
                    );
                  }
                  return (
                    <td key={tKey} style={getWidthStyles(column)}>
                      <div className={classes.tdInner}>
                        {index === 0 && checkable && (
                          <Checkbox
                            variant="primary"
                            id={`table-check-${d.id}`}
                            className={classes.checkbox}
                            checked={
                              !!tCheckedRows.find((obj) => obj.id === d.id)
                            }
                            onChange={(e) => {
                              handleCheckedChange(
                                e.target.checked
                                  ? [...tCheckedRows, d]
                                  : tCheckedRows.filter(
                                      (obj) => obj.id !== d.id
                                    )
                              );
                            }}
                          />
                        )}
                        {column.render ? column.render(tVal, d) : tVal}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </RsTable>
    </div>
  );
};

export default Table;
