import cn from 'classnames';
import React from 'react';
import { ReactComponent as Logo1Svg } from 'src/assets/icons/Logo/Logo1.svg';
import { ReactComponent as LogoAuthSvg } from 'src/assets/icons/Logo/LogoAuth.svg';
import classes from './Logo.module.scss';

const Logo = ({ className, isMini, size, variant }) => {
  const isAuth = variant === 'authentication';
  return (
    <span
      className={cn(
        classes.wrapper,
        {
          [classes.isMini]: isMini,
        },
        classes[`size_${size}`],
        classes[`variant_${variant}`],
        className
      )}
    >
      {isAuth ? <LogoAuthSvg /> : <Logo1Svg />}
    </span>
  );
};

export default Logo;
