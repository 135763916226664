import * as workflowActions from 'src/store/actions/workflow';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    workflowDetails: useReducerData('workflow', 'workflowDetails.data', {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...workflowActions,
  });
};
