import { apiCreateMenu, apiUpdateMenu } from 'src/api';
import * as menuActions from 'src/store/actions/menu';
import { useReducerData, useStoreActions } from 'src/store/hooks';
import { callAction } from 'src/utils/actions';

export const useIndexData = () => {
  return {
    menuDetails: useReducerData('menu', 'menuDetails.data', {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...menuActions,
  });
};

export const onCreateMenuItem = async (payload) => {
  const res = await callAction(() => {
    return apiCreateMenu(payload);
  });
  return res;
};
export const onEditMenuItem = async (id, payload) => {
  const res = await callAction(() => {
    return apiUpdateMenu(id, payload);
  });
  return res;
};
