import cn from 'classnames';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import Emergency from '../Emergency';
import LocationDetailsTable from '../LocationDetailsTable';
import classes from './TabContent.module.scss';
import {
  getColumns1,
  getColumns2,
  getColumns3,
  getColumns4,
} from './constants';

const TabContent = ({ className, tabKey }) => {
  const getContent = () => {
    switch (tabKey) {
      case LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS: {
        const columns = getColumns1();
        return (
          <LocationDetailsTable
            title="Sub-Systems"
            columns={columns}
            searchPlaceholder="Search Sub-Systems"
          />
        );
      }
      case LOCATION_DETAILS_TAB_KEYS.EQUIPMENT: {
        const columns = getColumns2();
        return (
          <LocationDetailsTable
            title="Equipments"
            columns={columns}
            searchPlaceholder="Search Equipment"
          />
        );
      }
      case LOCATION_DETAILS_TAB_KEYS.EMERGENCY:
        return <Emergency title="Emergency Contacts" />;
      case LOCATION_DETAILS_TAB_KEYS.STAFF_LIST: {
        const columns = getColumns3();
        return (
          <LocationDetailsTable
            title="Staff List"
            columns={columns}
            searchPlaceholder="Search Staff"
          />
        );
      }
      case LOCATION_DETAILS_TAB_KEYS.CHEMICALS: {
        const columns = getColumns4();
        return (
          <LocationDetailsTable
            title="Chemicals"
            columns={columns}
            searchPlaceholder="Search Chemicals"
          />
        );
      }
      default:
        return '';
    }
  };
  return <div className={cn(classes.wrapper, className)}>{getContent()}</div>;
};

export default TabContent;
