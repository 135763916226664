import cn from 'classnames';
import { Button as ReactstrapButton, Spinner } from 'reactstrap';
import classes from './Button.module.scss';

const Button = ({
  className,
  children,
  loading,
  disabled,
  variant,
  fontSize,
  rightIcon,
  isLink,
  size,
  weight,
  ...props
}) => {
  return (
    <ReactstrapButton
      className={cn(
        classes.wrapper,
        classes[`variant_${variant}`],
        classes[`fontSize_${fontSize}`],
        classes[`size_${size}`],
        classes[`weight_${weight}`],
        {
          [classes.isLink]: isLink,
          [classes.isVariant]: !!variant,
        },
        className
      )}
      disabled={loading || disabled}
      outline={isLink}
      {...props}
    >
      {loading && (
        <span className={classes.loading}>
          <Spinner />
        </span>
      )}
      {children}
      {!!rightIcon && <span className={classes.rightIcon}>{rightIcon}</span>}
    </ReactstrapButton>
  );
};

export default Button;
