import { getFormatDate } from 'src/utils/date';

export const renderDateColumn = (val) => {
  return getFormatDate(val, 'MMM DD, YYYY') || '-';
};
export const renderNormalColumn = (val) => {
  return val || '-';
};
export const renderNumberColumn = (val) => {
  return val || '0';
};
