import cn from 'classnames';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import Score from 'src/components/Score';
import ScoreChart from 'src/components/Score/Chart';
import { getFullAddress } from 'src/utils/address';
import classes from './LocationCard.module.scss';

const LocationCard = ({
  className,
  onEdit,
  onDelete,
  data,
  onView,
  hideView,
  hideActions,
  dropdowns,
}) => {
  const actionDropdown = dropdowns || [
    {
      key: 'edit',
      label: 'Edit Location',
      onClick: onEdit,
    },
    {
      key: 'delete',
      label: 'Archive Location',
      onClick: onDelete,
    },
  ];
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span>{data?.name}</span>
        {!hideActions && (
          <Dropdown items={actionDropdown}>
            <MoreSvg />
          </Dropdown>
        )}
      </div>
      <Score
        score={90}
        width={226}
        activeColor="#45B942"
        issuesColor="#C1FFC0"
        color="#EEEEEE"
        title=" "
        className={classes.score}
        issues={5}
      />
      <ScoreChart
        title=""
        scores={[
          100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100, 100, 100, 100, 100,
          100, 100, 100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100, 60, 60,
        ]}
      />
      <div className={classes.footer}>
        <span>{getFullAddress(data?.address, true)}</span>
        {hideView ? <span></span> : <span onClick={onView}>View Location</span>}
      </div>
    </div>
  );
};

export default LocationCard;
