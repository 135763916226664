import Request from './request';

export const apiGetExecutions = async (workflowId) => {
  return Request.call({
    url: `/api/executions/${workflowId}`,
    method: 'GET',
  });
};
export const apiGetExecutionDetails = async (id) => {
  return Request.call({
    url: `/api/execution/${id}`,
    method: 'GET',
  });
};
export const apiCreateExecution = async (data) => {
  return Request.call({
    url: `/api/execution/${data.slug}`,
    method: 'POST',
    data,
  });
};
export const apiUpdateExecution = async (data) => {
  return Request.call({
    url: `/api/execution/${data.slug}`,
    method: 'PUT',
    data,
  });
};
export const apiDeleteExecution = async (id) => {
  return Request.call({
    url: `/api/execution/${id}`,
    method: 'DELETE',
    data: {},
  });
};
