export function calculatePagination(currentPage, totalPages, maxVisiblePages) {
  if (totalPages <= 1) return [1]; // Single page case

  // Ensure current page is within valid range
  currentPage = Math.max(1, Math.min(currentPage, totalPages));

  const halfVisible = Math.floor(maxVisiblePages / 2);
  let startPage = Math.max(1, currentPage - halfVisible);
  let endPage = Math.min(totalPages, currentPage + halfVisible);

  // Adjust range when pages are at the extremes
  if (endPage - startPage + 1 < maxVisiblePages) {
    if (startPage === 1) {
      endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
    } else if (endPage === totalPages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }
  }

  // Generate the range of pages
  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return pages;
}
