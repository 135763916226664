import cn from 'classnames';
import { ReactComponent as ArrowRightSvg } from 'src/assets/icons/arrow-right.svg';
import classes from './Score.module.scss';

const ScoreReport = ({ title, className, description, onClick }) => {
  return (
    <div className={cn(classes.scoreReport, className)}>
      <div>
        <span>{title}</span>
        <span>{description}</span>
      </div>
      <ArrowRightSvg onClick={onClick} />
    </div>
  );
};

export default ScoreReport;
