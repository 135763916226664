import { useReducerData } from 'src/store/hooks';

const useOptions = () => {
  const allMenus = useReducerData('menu', 'allMenus.data.data', []);
  const menusData = useReducerData('auth', 'login.data.menus_data', []);

  const menuOptions = allMenus.map((obj) => ({
    value: obj.id,
    label: obj.item_name,
    rawObj: obj,
  }));
  const companyOptions = menusData.map((obj) => ({
    value: obj.id,
    label: obj.name,
    rawObj: obj,
  }));

  return { menuOptions, companyOptions };
};

export default useOptions;
