import React from 'react';
import TableActionDropdown from 'src/components/Table/ActionDropdown';
import StatusTag from 'src/components/Tag/Status';
import { capitalize } from 'src/utils/string';
import { renderDateColumn, renderNormalColumn } from 'src/utils/table';

export const getColumns = ({ onView, onEdit, onDelete }) => {
  return [
    {
      key: 'item_name',
      label: 'Name',
      render: renderNormalColumn,
      sortable: true,
      defaultSort: 'asc',
      width: 200,
    },
    {
      key: 'status',
      label: 'Status',
      render: (val) => <StatusTag value={val} />,
      sortable: true,
      width: 140,
      maxWidth: 140,
    },
    {
      key: 'parent_menu_item_name',
      label: 'Parent Name',
      render: renderNormalColumn,
      sortable: true,
      width: 180,
      maxWidth: 180,
    },
    {
      key: 'item_type',
      label: 'Type',
      render: (val) => renderNormalColumn(capitalize(val)),
      sortable: true,
      width: 120,
      maxWidth: 120,
    },
    {
      key: 'url',
      label: 'Path',
      render: renderNormalColumn,
      sortable: true,
      width: 200,
      maxWidth: 200,
    },
    {
      key: 'created_at',
      label: 'Created At',
      render: renderDateColumn,
      sortable: true,
      width: 165,
      maxWidth: 165,
    },
    {
      key: 'updated_at',
      label: 'Updated At',
      render: renderDateColumn,
      sortable: true,
      width: 165,
      maxWidth: 165,
    },
    {
      key: 'updated_by',
      label: 'Updated by',
      render: (val) => val?.name || '-',
      sortable: true,
      width: 165,
      maxWidth: 165,
    },
    {
      key: 'action',
      width: 52,
      maxWidth: 52,
      render: (_, obj) => {
        return (
          <TableActionDropdown
            options={[
              {
                key: 'view',
                label: 'View',
                onClick: () => {
                  if (onView) onView(obj);
                },
              },
              {
                key: 'edit',
                label: 'Edit',
                onClick: () => {
                  if (onEdit) onEdit(obj);
                },
              },
              {
                key: 'delete',
                label: 'Delete',
                onClick: () => {
                  if (onDelete) onDelete(obj);
                },
              },
            ]}
          />
        );
      },
    },
  ];
};
