// eslint-disable-next-line no-unused-vars
import cn from 'classnames';
import React, { useEffect } from 'react';
import Adjenda from 'src/components/Adjenda';
import Breadcrumb from 'src/components/Breadcrumb';
import Calender from 'src/components/Calendar/components/Calendar';
import { useEventContext } from 'src/components/Calendar/contexts/EventContext';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import classes from './Dashboard.module.scss';

const Dashboard = () => {
  const { init } = useEventContext();
  const breadcrumbs = [
    {
      link: '/',
      label: 'Dashboard',
    },
    // {
    //   link: '/',
    //   label: 'Previous Page',
    // },
    // {
    //   label: 'Current Page',
    // },
  ];
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PageWrapper className={classes.wrapper}>
      <Breadcrumb items={breadcrumbs} />
      {/* <PageTitle>Dashboard</PageTitle> */}
      {/* <div>...</div> */}
      <Adjenda />
      <Calender />
    </PageWrapper>
  );
};

export default Dashboard;
