import { getSelectValue } from 'src/utils/form';

export const getCreatePartPayload = (values) => {
  const returnObj = {
    ...values,
    quantity: Number(values.quantity || 0),
    sub_system_id: Number(getSelectValue(values.sub_system_id)),
  };
  return returnObj;
};
