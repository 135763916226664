import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import * as yup from 'yup';
import { useActions as useTaskActions } from 'src/components/Adjenda/selectorData';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import DateTimePicker from 'src/components/Calendar/components/fields/DateTimePicker';
import { useEventContext } from 'src/components/Calendar/contexts/EventContext';
import Dropdown from 'src/components/Dropdown';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import Table from 'src/components/Table';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import { useActions, useIndexData } from 'src/pages/Admin/Users/selectorData';
import SelectLocation from 'src/pages/CreateRegion/components/SelectLocation';
import { useActions as useWorkflowActions } from 'src/pages/Workflow/selectorData';
import { useReducerData } from 'src/store/hooks';
import { getMenusByItemType } from 'src/utils/menu';
import classes from './Tasks.module.scss';

const formSchema = yup.object().shape({
  publishDate: yup.string().required('publish date is required!'),
  startDate: yup.string().required('start date is required!'),
  users: yup.array().required('user is required!'),
  title: yup.string().required('title is required!'),
});
const Task = ({ props }) => {
  const { workflowId, task, onClose, title, formType } = props;
  const isEdit = formType == 'update';
  const [loading, setLoading] = useState(false);
  const menusData = useReducerData('auth', 'login.data.menus_data', []);
  const [usersOptions, setUsersOptions] = useState([]);
  const [usersSearchText, setUsersSearchText] = useState('');
  const [showCompleteInput, setShowCompleteInput] = useState(false);
  const [workflow, setWorkflow] = useState();
  const [debouncedUsersSearchText] = useDebounce(usersSearchText, 300);
  const { getResources } = useActions();
  const {
    saveEvent,
    editEvent,
    removeEvent,
    handleClose,
    selectedEvent,
    setFormType,
  } = useEventContext();
  const { getWorkflowDetails } = useWorkflowActions();
  const { availableMenus, selectedLocationIds } = useContext(MenuContext);
  const navigate = useNavigate();
  const { startTask } = useTaskActions();

  let workflows = [
    { id: 0, item_name: 'none', url: 'none' },
    ...getMenusByItemType(menusData, 'workflow'),
  ].map((w) => {
    return {
      value: w.url.replace('/executions/', ''),
      label: w.item_name,
    };
  });

  if (task?.sourceDocument && task.sourceDocument.workflow) {
    workflows = workflows.filter(
      (w) => w.value == task.sourceDocument.workflow || w.value == 'none'
    );
  }
  const scopeFilteredMenus = [];
  menusData.forEach((company) => {
    company.regions.forEach((region) => {
      region.locations.forEach((location) => {
        // TODO: check for pemissions
        // const menus = getLocationMenus(location);
        if (selectedLocationIds.indexOf(location.id) > -1) {
          scopeFilteredMenus.push({
            value: location.id,
            label: `${company.name} ➡ ${region.name} ➡ ${location.name}`,
          });
        }
      });
    });
  });

  useEffect(() => {
    if (debouncedUsersSearchText) {
      fetchUsers(debouncedUsersSearchText, 1, 10, 'name', 'asc');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUsersSearchText]);

  const fetchUsers = (tSearch, tPage, tSize, tSortField, tSortMethod) => {
    setLoading(true);
    getResources(
      RESOURCE_DATA_KEY.USERS,
      {
        page: tPage,
        per_page: tSize,
        search: tSearch,
        sort_by: tSortField,
        sort_order: tSortMethod,
      },
      ({ data }) => {
        setUsersOptions(
          data.map((user) => ({ label: user.name, value: user.id })) // Format the data for options
        );
        setLoading(false);
      }
    );
  };

  const startTaskClicked = (task) => {
    startTask(
      {
        ...task, //, startingUserId: userData.id
      },
      (res) => {
        const tExecution = res.result || [];
        navigate(`/workflows/execute/${task.workflow.slug}/${tExecution._id}`);
      }
    );
  };

  const now = moment(new Date()).format('YYYY-MM-DD');
  const tomorrow = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
  const start = moment(task?.startDate || task?.start || now).format(
    'YYYY-MM-DD'
  );
  const end = moment(task?.dueDate || task?.end || tomorrow).format(
    'YYYY-MM-DD'
  );

  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      publishDate: task?.publishDate ? task?.publishDate.split('T')[0] : now,
      allowedStartDate: task?.allowedStartDate
        ? task?.allowedStartDate.split('T')[0]
        : now,
      startDate: start,
      dueDate: end,
      title: task?.title || task?.workflow?.information.taskTitle,
      description:
        task?.description || task?.workflow?.information.taskDescription,
      location:
        scopeFilteredMenus.find((l) => l.value == task?.locationId) ||
        scopeFilteredMenus.find((m) => m.value == selectedLocationIds[0]),
      workflow:
        workflows.find(
          (l) => l.value == (task?.workflow?.slug || workflowId)
        ) || workflows[0],
      users: task?.assignedTo?.map((uid) => {
        return {
          value: uid,
          label: task?.usersInfo.find((u) => u.id === uid).name,
        };
      }),
      completeNote: task?.completeNote,
    },
  });

  const formData = watch();

  useEffect(() => {
    if (workflowId) {
      workflowChanged({ value: workflowId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowId]);

  const workflowChanged = async ({ value }) => {
    if (value !== 'none') {
      setLoading(true);
      var v = formData;
      getWorkflowDetails(value, formData.location.value, (res) => {
        const tWorkflow = res.result || {};
        if (tWorkflow._id) {
          setWorkflow(tWorkflow);
          if (!task?.sourceDocument) {
            setValue(
              'description',
              tWorkflow.information.taskDescription || ''
            );
          } else {
            setValue(
              'description',
              (tWorkflow.information.taskDescription || '') +
                '\n\nDocument: ' +
                task.sourceDocument.docUrl
            );
          }
          setValue('title', tWorkflow.information.taskTitle || '');
          setValue('workflow', tWorkflow);
          // setDocuments(res.documents);
        }
        setLoading(false);
      });
    } else {
      setWorkflow(undefined);
    }
  };

  const onSubmit = async (values) => {
    const payload = {
      start: values.startDate,
      end: values.dueDate,
      _id: task?._id,
      assignedTo: values.users.map((u) => u.value),
      locationId: values.location.value,
      startDate: moment(values.startDate).toISOString(),
      publishDate: moment(values.publishDate).toISOString(),
      allowedStartDate: moment(values.allowedStartDate).toISOString(),
      dueDate: moment(values.dueDate).toISOString(),
      title: values.title,
      description: values.description,
      workflow: workflow && {
        id: workflow._id,
        slug: workflow.slug,
        information: workflow.information,
      },
      completeNote: values.completeNote,
      status: values.status,
    };
    if (isEdit || !!task?.createdAt) {
      payload._id == task._id;
      editEvent(payload);
    } else {
      payload.status = 'Created';
      saveEvent(payload);
    }
    onClose();
  };
  const workflowHidden = () => {
    if (task?.sourceDocument) {
      return !task.sourceDocument.workflow || formType != 'add';
    } else return !!workflowId || formType != 'add';
  };
  return (
    <div>
      {/* {JSON.stringify(task)} */}
      {task?.prompt}
      <form
        role="form"
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={classes.formInner}>
          <span hidden={selectedLocationIds.length < 2}>
            <FormGroup>
              <FormSelect
                options={scopeFilteredMenus}
                label="Location"
                name="location"
                placeholder="Select location"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                autoFocus
                isDisabled={formType == 'show'}
              />
            </FormGroup>
            <br />
          </span>
          <span hidden={workflowHidden()}>
            <FormGroup>
              <FormSelect
                options={workflows}
                label="Workflow"
                name="workflow"
                placeholder="Select workflow"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                autoFocus
                onChange={workflowChanged}
                isDisabled={formType == 'show'}
              />
            </FormGroup>
            <br />
          </span>
          <FormGroup>
            <FormInput
              label="Title"
              name="title"
              placeholder="Task title"
              control={control}
              radius="md"
              variant="primary"
              fontSize="sm"
              readOnly={formType == 'show'}
            />
          </FormGroup>
          <br />
          <FormGroup>
            <FormInput
              label="Description"
              name="description"
              placeholder="Description"
              control={control}
              type="textarea"
              radius="md"
              variant="primary"
              fontSize="sm"
              readOnly={formType == 'show'}
            />
          </FormGroup>
          <br />
          <FormGroup
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '1rem',
            }}
          >
            {formType == 'show' && (
              <FormInput
                // label="Status"
                name="displayStatus"
                placeholder="Status"
                // control={control}
                control={control}
                value={'Status: ' + task.status}
                type="text"
                radius="md"
                variant="primary"
                fontSize="sm"
                readOnly
              />
            )}
            <span hidden={formType == 'show'}>
              <FormInput
                // label="Publish date"
                name="publishDate"
                placeholder="Publish date"
                control={control}
                type="date"
                radius="md"
                variant="primary"
                fontSize="sm"
                readOnly={formType == 'show'}
              />
            </span>
            <FormInput
              // label="Earliest start date"
              name="allowedStartDate"
              placeholder="Earliest start date"
              control={control}
              type="date"
              radius="md"
              variant="primary"
              fontSize="sm"
              readOnly={formType == 'show'}
            />
            <FormInput
              // label="Latest start date"
              name="startDate"
              placeholder="Start date"
              control={control}
              type="date"
              radius="md"
              variant="primary"
              fontSize="sm"
              readOnly={formType == 'show'}
            />
            <FormInput
              // label="Due date"
              name="dueDate"
              placeholder="Due date"
              control={control}
              type="date"
              radius="md"
              variant="primary"
              fontSize="sm"
              readOnly={formType == 'show'}
            />
          </FormGroup>
          <span hidden={formType != 'add'}>
            <br />
            <FormGroup>
              <FormSelect
                options={usersOptions}
                label="Users"
                name="users"
                placeholder="Select assigned users"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                autoFocus
                isMulti
                onInputChange={(text) => setUsersSearchText(text)} // Listen to input change
                readOnly={formType == 'show'}
              />
            </FormGroup>
          </span>
          <span
            hidden={
              formType == 'add' ||
              (!showCompleteInput && !formData.completeNote) ||
              (formType == 'update' && !formData.completeNote)
            }
          >
            <br />
            <FormGroup>
              <FormInput
                label="Complete note"
                name="completeNote"
                placeholder="Complete note"
                control={control}
                type="textarea"
                radius="md"
                variant="primary"
                fontSize="sm"
                readOnly={!showCompleteInput && formType == 'show'}
              />
            </FormGroup>
          </span>
        </div>
        <hr />
        {formType != 'show' ? (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '1rem',
            }}
          >
            <Button variant="primary" isLink type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!formState.isValid || loading}
              loading={loading}
            >
              {isEdit ? 'Save Changes' : 'Create Task '}
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '1rem',
            }}
          >
            <Button variant="primary" isLink type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" isLink onClick={() => removeEvent()}>
              Remove
            </Button>
            <Button
              variant="primary"
              isLink
              onClick={() => setFormType('update')}
            >
              Edit
            </Button>
            {!task.workflow ? (
              task.status != 'Completed' && (
                <Button
                  variant="primary"
                  disabled={showCompleteInput && !formData.completeNote}
                  onClick={() => {
                    if (showCompleteInput) {
                      setShowCompleteInput(false);
                      setValue('status', 'Completed');
                      onSubmit({ ...formData, status: 'Completed' });
                    } else setShowCompleteInput(true);
                  }}
                >
                  {showCompleteInput ? 'MARK COMPLETE' : 'COMPLETE...'}
                </Button>
              )
            ) : !task.executionId ? (
              <Button
                variant="primary"
                disabled={moment(task.allowedStartDate) >= new Date()}
                onClick={() => {
                  startTaskClicked(task);
                  onClose();
                }}
              >
                START
              </Button>
            ) : task.status != 'Completed' ? (
              <Button
                variant="primary"
                disabled={moment(task.allowedStartDate) >= new Date()}
                onClick={() => {
                  navigate(
                    `/workflows/execute/${task.workflow.slug}/${task.executionId}`
                  );
                  onClose();
                }}
              >
                RESUME
              </Button>
            ) : (
              <Button
                variant="primary"
                disabled={moment(task.allowedStartDate) >= new Date()}
                onClick={() => {
                  navigate(
                    `/workflows/execute/${task.workflow.slug}/${task.executionId}`
                  );
                  onClose();
                }}
              >
                VIEW
              </Button>
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default Task;
