/* eslint-disable no-restricted-imports */
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Button from 'src/components/Button';
import Dropdown from 'src/components/Dropdown';
import FormGroup from 'src/components/FormFields/FormGroup';
import Input from 'src/components/FormFields/Input';
import FormInput from 'src/components/FormFields/Input/FormInput';
import PDFViewer from 'src/components/PDFViewer';
import PageTitle from 'src/components/Page/PageTitle';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import classes from '../../ExecuteWorkflow.module.scss';
import { EXECUTION_TAB_KEYS } from '../../constants';

const InformationForm = ({
  onStartClick,
  workflow,
  executionId,
  execution,
  documents,
  setActiveTab,
}) => {
  const [documentData, setDocumentData] = useState({}); //documents.length > 0 ? documents[0] : {});

  const formSchema = yup.object().shape({
    name: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
    description: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
    address: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  });

  const defaultValues = {
    name: '',
    description: '',
    overview: '',
    address: '',
  };

  const onSubmit = async (values) => {};

  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      ...defaultValues,
    },
  });
  const notify = (type, title, description) => {
    // notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };

  return (
    <>
      <PageTitle
        className={classes.pageTitle}
        subTitle={
          <>
            Execute Workflow ...,
            <br />
            ...
          </>
        }
      >
        Execute Workflow
      </PageTitle>
      <form
        role="form"
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <br />
        <br />
        <div className={classes.formInner}>
          <div>
            {/* <FormGroup>
          <FormInput
            label="Workflow High Level Description"
            name="description"
            placeholder="Short description of the workflow that appears on the overview"
            control={control}
            type="textarea"
            radius="md"
            variant="primary"
            fontSize="sm"
            className={classes.descriptionInput}
          />
        </FormGroup> */}
            {workflow.information?.description}
          </div>
        </div>
        <br />
        {!executionId || execution?.status == 'Created' ? (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                // if (execution?.status == 'Created') setActiveTab(EXECUTION_TAB_KEYS.EXECUTION)
                // else
                onStartClick();
              }}
            >
              Start
            </Button>
          </div>
        ) : (
          <div>
            <Button
              variant="primary"
              onClick={() => setActiveTab(EXECUTION_TAB_KEYS.EXECUTION)}
            >
              Resume
            </Button>
          </div>
        )}
        {documents?.length > 0 && (
          <div style={{ maxHeight: '30vh', overflow: 'auto' }}>
            Documents:
            <br />
            {documents.map((v, i) => (
              <div
                key={i}
                style={{
                  backgroundColor:
                    v._id == documentData._id ? '#ddd' : 'transparent',
                  cursor: 'pointer',
                }}
                onClick={() => setDocumentData(v)}
              >
                {v.name} <hr />
              </div>
            ))}
          </div>
        )}
        {documentData._id && (
          <PDFViewer documentId={documentData._id} slug={documentData.slug} />
        )}
      </form>
    </>
  );
};

export default InformationForm;
