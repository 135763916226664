import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import LocationRoles from 'src/components/FormFields/LocationRoles';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import Loading from 'src/components/Loading';
import { LOCATIONS_SCOPE_ATTR_NAME } from 'src/helper/constants';
import { RESOURCE_DATA_KEY } from 'src/helper/constants/store';
import useResourceActions from 'src/helper/hooks/useResourceActions';
import { MenuContext } from 'src/helper/providers/MenuProvider';
import Field from 'src/pages/GenericCreate/components/Field';
import GenericCreateFooter from 'src/pages/GenericCreate/components/Footer';
import GenericCreateTitle from 'src/pages/GenericCreate/components/Title';
import GenericCreateWrapper from 'src/pages/GenericCreate/components/Wrapper';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import classes from './UsersCreate.module.scss';
import { useActions, useIndexData } from './selectorData';
import {
  getAssignedLocationsRolesPayload,
  getBreadcrumbText,
  getCreateUserPayload,
  getSubTitle,
  getTitle,
} from './utils';

const formSchema = yup.object().shape({
  name: yup.string().required('Name is required!'),
  email: yup
    .string()
    .required('Email is required!')
    .email('Email is not valid!'),
});

const UsersCreate = () => {
  const { selectedLocationIds } = useContext(MenuContext);
  const selectedLocationIdsString = selectedLocationIds.join(',');
  const {
    onCreateResource,
    onEditResource,
    onGetResources,
    onAssignResourceLocationsRoles,
    onGetResourceLocationsRoles,
  } = useResourceActions();
  const { getResourceDetails } = useActions();
  const { userDetails } = useIndexData();
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const navigate = useNavigate();
  const userId = Number(urlParams.id || 0);
  const isViewDetails = urlParams.view === 'true' && !!userId;
  const isEdit = urlParams.view !== 'true' && !!userId;
  const notificationAlertRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [assignedLRLoading, setAssignedLRLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const breadcrumbs = [
    {
      link: '/admin/users',
      label: 'Users',
    },
    {
      label: getBreadcrumbText({ isViewDetails, isEdit }),
    },
  ];
  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
      location_roles: [],
      supervisor_id: null,
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const reloadUserDetails = (cb) => {
    setDetailsLoading(true);
    getResourceDetails(RESOURCE_DATA_KEY.USERS, userId, (res) => {
      setDetailsLoading(false);
      if (cb) cb(res);
    });
  };
  const assignedLocationsRoles = async (tUserId, tLocationsRoles, cb) => {
    const tPayload = getAssignedLocationsRolesPayload(tLocationsRoles);
    const res = await onAssignResourceLocationsRoles(
      RESOURCE_DATA_KEY.USERS,
      tUserId,
      tPayload
    );
    setSubmitLoading(false);
    if (res.isSuccess) {
      if (cb) cb();
    } else {
      notify('error', 'Error', `Assign Locations Roles failed!`);
    }
  };
  const onSubmit = async ({ location_roles, ...values }) => {
    if (!submitLoading) {
      setSubmitLoading(true);
      const tPayload = getCreateUserPayload(values);
      let res = null;
      if (userId) {
        tPayload.status = userDetails?.status || 'active';
        res = await onEditResource(RESOURCE_DATA_KEY.USERS, userId, tPayload);
      } else {
        tPayload.status = 'active';
        res = await onCreateResource(RESOURCE_DATA_KEY.USERS, tPayload);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        assignedLocationsRoles(res.data?.id, location_roles, () => {
          navigate('/admin/users');
        });
      } else {
        notify('error', 'Error', `${userId ? 'Edit' : 'Create'} User failed!`);
      }
    }
  };
  const reloadAssignedLocationsRoles = async (cb) => {
    setAssignedLRLoading(true);
    const res = await onGetResourceLocationsRoles(
      RESOURCE_DATA_KEY.USERS,
      userId
    );
    if (cb) cb(res);
    setAssignedLRLoading(false);
  };
  const reloadRoles = async () => {
    setRolesLoading(true);
    const res = await onGetResources(RESOURCE_DATA_KEY.ROLES, {
      page: 1,
      per_page: 99999,
      [LOCATIONS_SCOPE_ATTR_NAME]: selectedLocationIdsString,
    });
    setRolesLoading(false);
    setRoleList(res?.data || []);
  };
  const reloadUsers = async () => {
    setUsersLoading(true);
    const res = await onGetResources(RESOURCE_DATA_KEY.USERS, {
      page: 1,
      per_page: 99999,
      [LOCATIONS_SCOPE_ATTR_NAME]: selectedLocationIdsString,
    });
    setUsersLoading(false);
    setUserOptions(
      (res?.data || [])
        .filter((obj) => obj.id !== userId)
        .map((obj) => ({
          value: obj.id,
          label: obj.name,
        }))
    );
  };

  useEffect(() => {
    if (userId) {
      reloadUserDetails((res) => {
        if (isEdit) {
          const editObj = res?.data;
          if (editObj?.id) {
            const resetObj = {
              name: editObj.name,
              email: editObj.email,
              supervisor_id: editObj.supervisor_id,
            };
            reloadAssignedLocationsRoles((res1) => {
              resetObj.location_roles = (res1?.data || []).map((obj) => {
                return {
                  id: obj.location_id,
                  roles: (obj.role_ids || []).map((v) => String(v)),
                };
              });
              reset(resetObj);
            });
          }
        }
      });
    }
    if (!isViewDetails) {
      reloadRoles();
      reloadUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <>
      <GenericCreateWrapper>
        {(detailsLoading ||
          rolesLoading ||
          usersLoading ||
          assignedLRLoading) && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <GenericCreateTitle subTitle={getSubTitle()}>
          <span>{getTitle({ isViewDetails, isEdit, userDetails })}</span>
        </GenericCreateTitle>
        <form role="form" className="s-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="s-form-inner">
            {isViewDetails ? (
              <>
                <Field label="Name" value={userDetails?.name || '-'} />
                <Field label="Email" value={userDetails?.email || '-'} />
                <Field
                  label="Supervisor"
                  value={userDetails?.supervisor_name || '-'}
                />
              </>
            ) : (
              <>
                <FormGroup>
                  <FormInput
                    required
                    label="Name"
                    name="name"
                    placeholder="Enter name"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    required
                    label="Email"
                    name="email"
                    placeholder="Enter email"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
                <FormGroup>
                  <FormSelect
                    options={userOptions}
                    label="Supervisor"
                    name="supervisor_id"
                    placeholder="Select supervisor"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </FormGroup>
              </>
            )}
          </div>
          {!isViewDetails && (
            <FormGroup className={classes.locationRolesWrapper}>
              <LocationRoles
                name="location_roles"
                control={control}
                roles={roleList}
                onChange={(val) => {
                  setValue('location_roles', val, {
                    shouldDirty: true,
                  });
                }}
              />
            </FormGroup>
          )}
          {!isViewDetails && (
            <GenericCreateFooter>
              <Button
                variant="primary"
                isLink
                type="button"
                onClick={() => {
                  navigate('/admin/users');
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!formState.isValid || !formState.isDirty}
                loading={submitLoading}
              >
                {isEdit ? 'Save Changes' : 'Create'}
              </Button>
            </GenericCreateFooter>
          )}
        </form>
      </GenericCreateWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default UsersCreate;
