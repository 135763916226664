import isEmpty from 'lodash/isEmpty';
import { useReducerData } from 'src/store/hooks';

const useProfile = () => {
  const currentUser = useReducerData('auth', 'currentUser.data.data', {});
  const loginData = useReducerData('auth', 'login.data', {});

  return isEmpty(currentUser) ? loginData : currentUser;
};

export default useProfile;
