import cn from 'classnames';
import classes from './Emergency.module.scss';

const EmergencyContact = ({ className, title, items }) => {
  return (
    <div className={cn(classes.emergencyContact, className)}>
      <div>
        <span>{title}</span>
      </div>
      <div>
        {items.map((item) => {
          return (
            <span key={item.id}>
              <b>{item.label}</b>
              <span>{item.value}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default EmergencyContact;
