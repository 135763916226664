import cn from 'classnames';
import FormCheckbox from 'src/components/FormFields/Checkbox/FormCheckbox';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSwitch from 'src/components/FormFields/Switch/FormSwitch';
import { WEEK_DAYS } from 'src/helper/constants/date';
import classes from './OperatingHours.module.scss';
import TimeInput from './TimeInput';

const OperatingHours = ({ className, control }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span>Operating Hours</span>
      </div>
      <div className={classes.content}>
        <ul>
          {WEEK_DAYS.map((day) => {
            const namePrefix = `${day}_`;
            return (
              <TimeInput
                namePrefix={namePrefix}
                control={control}
                day={day}
                key={day}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default OperatingHours;
