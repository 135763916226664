import Request from './request';

export const apiGetTasks = async (data) => {
  return Request.call({
    url: `/api/tasks`,
    method: 'POST',
    data,
  });
};

export const apiStartTask = async (data) => {
  return Request.call({
    url: `/api/tasks/start`,
    method: 'POST',
    data,
  });
};

export const apiCreateTask = async (data) => {
  return Request.call({
    url: `/api/tasks/create`,
    method: 'POST',
    data,
  });
};

export const apiDeleteTask = async (taskId) => {
  return Request.call({
    url: `/api/tasks/${taskId}`,
    method: 'DELETE',
    data: {},
  });
};

export const apiUpdateTask = async (taskId, data) => {
  return Request.call({
    url: `/api/tasks/${taskId}`,
    method: 'PUT',
    data,
  });
};
