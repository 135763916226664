/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
// @ts-nocheck

import { isSet } from 'immutable';
import { useCallback, useEffect, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import Switch from 'src/components/FormFields/Switch';
import { Tags } from './../Tags/Tags';
import { runFlowOnProcessId } from './FlowRunner';

const FlowRun = ({ nodes, edges, informExecutionChanged, execution }) => {
  nodes.map((n) => {
    if (n.data.subType == 'dnode_form' && n.data.task_data) {
      n.data.task_data.map((t) => {
        t.variableKey = t.label?.split('(')[0].trim().replace(/ /g, '_');
        // t.readOnly = true;
      });
    }
  });
  const [runData, setRunData] = useState(execution?.runData);
  const [forms, setForms] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [variables, setVariables] = useState({});
  const [currentForm, setCurrentForm] = useState(null);
  const [refreshKey, setRefreshKey] = useState(Math.random());
  const [viewMode, setViewMode] = useState('tabs');

  var savedFlowRunData = undefined;

  useEffect(() => {
    var initRunData;
    console.log('init');

    // if (!savedFlowRunData) {
    // savedFlowRunData =
    //   typeof localStorage !== 'undefined' &&
    //   localStorage.getItem('flow_run_data') &&
    //   localStorage.getItem('flow_run_data') !== 'undefined' &&
    //   JSON.parse(localStorage.getItem('flow_run_data'));
    // if (savedFlowRunData) {
    //   initRunData = savedFlowRunData;
    // }
    if (execution?.runData) {
      // debugger
      initRunData = execution.runData;

      // console.log('unfound savedFlowRunData')
      nodes.map((n) => {
        if (n?.data?.subType == 'dnode_form' && n.data.task_data) {
          n.data.task_data.map((t) => {
            const k = t.label?.split('(')[0].trim().replace(/ /g, '_');
            if (!isSet(initRunData[k]) && initRunData[k] === undefined)
              initRunData[k] = undefined;
            initRunData[k + '_question_def'] = t;
          });
        }
      });
      // console.log(savedFlowRunData)
      // setRunData(savedFlowRunData)
      // }

      console.log(initRunData);
      // console.log(runData)
      var { _forms, _runData } = runFlowOnProcessId(nodes, edges, initRunData);

      // console.log(initRunData)
      setForms(_forms);
      // if(JSON.stringify(_runData) != JSON.stringify(runData))
      setRunData(_runData);
      if (!currentForm) {
        setCurrentForm(
          _forms[0]?.data.label.split('(')[0].trim().replace(/ /g, '_')
        );
      }
      const _answers = calculateAnswers(_runData);
      saveState(_runData, _forms, _answers);
    } else {
      // initial run, no answers
      // just setup the form

      initRunData = {};
      // eslint-disable-next-line no-redeclare
      var { _forms, _runData } = runFlowOnProcessId(nodes, edges, initRunData);
      setForms(_forms);
      // const _answers = calculateAnswers(_runData);
      // saveState(_runData, _forms, _answers);
      // setCurrentForm(
      //   _forms[0]?.data.label.split('(')[0].trim().replace(/ /g, '_')
      // );
    }
  }, [nodes, edges, execution?.runData]);

  const saveState = useCallback(
    (runData, forms, answers) => {
      var missing = [];
      forms.map((f) =>
        f.data.task_data
          .filter(
            (t) =>
              t.required &&
              !answers.find((a) => a.name === t.field_name && a.value)
          )
          .map((t) => missing.push({ form: f, field: t }))
      );

      // console.log(forms, answers)
      if (informExecutionChanged) informExecutionChanged(runData, missing);
      else localStorage.setItem('flow_run_data', JSON.stringify(runData));
    },
    [runData, forms, answers]
  );

  const calculateAnswers = useCallback(
    (_runData) => {
      var _variables = {};
      var _answers = Object.keys(_runData)
        .filter((k) => k.indexOf('_question_def') === -1)
        .map((k) => {
          _variables[k] = _runData[k];
          const field = _runData[k + '_question_def'];
          if (!field) {
            console.log(k + ' MISSING');
            return null;
          }
          return {
            name: field.field_name,
            value:
              !_runData[k]?.length && field.field_name.includes('tags_')
                ? []
                : _runData[k],
          };
        })
        .filter((k) => k);
      console.log(_variables);
      setAnswers(_answers);
      setVariables(_variables);
      return _answers;
    },
    [nodes, edges]
  );

  const formChanged = useCallback(
    (info) => {
      var form; // = nodes.find(f => f.key === currentForm)
      var formRunData = {};

      if (info) {
        nodes.map(
          (_form) =>
            _form.data.task_data &&
            info.map &&
            info.map((i) => {
              const field = _form.data.task_data?.find((t) => t.id == i.id);
              if (!field) {
                // console.log('unfound field: ' + i.value + ' ' + i.id + ' - form: ' + currentForm)
                // formRunData[field_key] = i.value
              } else {
                form = _form;
                const field_key = field.label
                  .split('(')[0]
                  .trim()
                  .replace(/ /g, '_');
                if (field.element === 'RadioButtons') {
                  formRunData[field_key] = field.options.find(
                    (o) => o.key === i.value[0]
                  )?.value;
                } else {
                  // formRunData[field_key + '_question_def'] = field
                  formRunData[field_key] = i.value;
                }
              }
            })
        );
      }
      if (!form) {
        return;
      }
      if (info && typeof info === 'object' && !info.map) {
        // debugger
        Object.keys(info).map((key) => {
          formRunData[key] = info[key];
        });
      }
      console.log('saved form');
      var updatedRunData = { ...runData, ...formRunData };
      console.log(info);
      // if (!info) {
      var { _forms, _runData } = runFlowOnProcessId(
        nodes,
        edges,
        updatedRunData
      );
      setForms(_forms);
      setRunData(_runData);
      const _answers = calculateAnswers(_runData);
      saveState(_runData, _forms, _answers);
      if (!info) setRefreshKey(Math.random());
      // } else {
      //     setRunData(updatedRunData)
      //     calculateAnswers(updatedRunData)
      //     saveState(updatedRunData)
      // }
    },
    [nodes, edges, runData, currentForm]
  );

  const renderForm = (form, runData, variables, singleForm) => {
    switch (form.data.subSubType) {
      case null || '' || undefined: {
        const tData =
          form?.data?.task_data?.length > 0 ? [...form.data.task_data] : [];
        return (
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <ReactFormGenerator
              key={currentForm + refreshKey}
              // download_path={currentForm + Math.random()}
              back_action="#"
              back_name="Cancel"
              // answer_data={form.data?.task_data?.map(f => { return { name: f.field_name, value: '123' } }) || []}
              answer_data={answers}
              action_name="Save"
              form_action="/"
              form_method="POST"
              // onSubmit={this.submit}
              variables={variables}
              // submitButton={<button type="submit" className="btn btn-primary" onClick={()=>formChanged(false)}>Save</button>}
              // submitButton={<button type="submit" className="btn btn-primary" onClick={()=>formChanged(false)}>Submit123{JSON.stringify(variables) + refreshKey}</button>}
              onChange={formChanged}
              hide_actions={true}
              data={tData}
            />
            <hr />
            {/* {runData[currentForm + '_FLAGS'] ? runData[currentForm + '_FLAGS'].map(f=>{
                            return {id:f, text:f, className: 'bg-primary pl-3 rounded-2'}
                        }) : 'none ' + currentForm + '_FLAGS' + ' - ' + runData[currentForm + '_FLAGS'] + JSON.stringify(runData, null, 2)} */}
            {!singleForm && !informExecutionChanged && (
              <>
                {/* <label
                  className="form-label"
                  style={{ fontWeight: 'bold', padding: '0 10px' }}
                >
                  <span>Flags </span>
                </label>
                <Tags
                  form={form}
                  currentForm={currentForm}
                  key={'tags_' + currentForm + refreshKey}
                  runData={runData}
                  formTags={
                    runData[currentForm + '_FLAGS']
                      ? runData[currentForm + '_FLAGS'].map((f) => {
                          return {
                            id: f,
                            text: f,
                            className:
                              'tag-bg-' +
                              f.replace(/ /gi, '-').toLowerCase() +
                              ' pl-3 rounded-2',
                          };
                        })
                      : []
                  }
                  formChanged={formChanged}
                />
                <hr />

                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => formChanged(false)}
                >
                  Save
                </button> */}
              </>
            )}
          </div>
        );
      }
      default:
        return <div>undefined form type: {form.data.subSubType}</div>;
    }
  };
  // var flags=node.data[node.data?.label?.trim().replace(/ /g, '_') + '_FLAGS'].length})
  return (
    <div>
      {/* {JSON.stringify(execution)} */}
      <table
        style={{ right: '20px', position: 'absolute', marginTop: '-12px' }}
        cellPadding={'10px'}
      >
        <tr>
          <td>Single form</td>
          <td>
            <Switch
              checked={viewMode === 'tabs'}
              onChange={(e) => {
                if (!e.target.checked) setViewMode('single form');
                else setViewMode('tabs');
              }}
            />
          </td>
          <td>Tabs</td>
        </tr>
      </table>
      <br />
      {viewMode == 'single form' ? (
        <div className="card border-top-0 card-body shadow-sm">
          {forms
            .filter(
              (node) => node.data.subType === 'dnode_form' // && (node?.data.label.split('(')[0].trim().replace(/ /g, '_') === currentForm)
            )
            .map((node, ind) => {
              return (
                <div key={ind}>
                  <h1>{node.data.label}</h1>
                  {renderForm(node, runData, variables, true)}
                  <br />
                </div>
              );
            })}
          {/* <Tags form={forms[0]}
                    currentForm={currentForm}
                    key={'tags_' + currentForm + refreshKey}
                    runData={runData}
                    formTags={runData[currentForm + '_FLAGS'] ? runData[currentForm + '_FLAGS'].map(f => {
                        return { id: f, text: f, className: 'tag-bg-' + f.replace(/ /gi, '-').toLowerCase() + ' pl-3 rounded-2' }
                    }) : []}
                    formChanged={formChanged} /> 
                <hr />

          {!informExecutionChanged && <button
            type="submit"
            className="btn btn-primary"
            onClick={() => formChanged(false)}
          >
            Save
          </button>} */}
        </div>
      ) : (
        <div>
          <ul className="nav nav-tabs" id="flow-tabs" role="tablist">
            {forms.map((node, ind) => (
              <li key={ind} className="nav-item" role="presentation">
                <button
                  className={
                    (currentForm === node.key ? 'active' : '') + ' nav-link'
                  }
                  onClick={() => setCurrentForm(node.key)}
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  {node.data?.label?.split('(')[0]}
                  {runData &&
                    runData[
                      node.data.label.trim().replace(/ /g, '_') + '_FLAGS'
                    ] &&
                    runData[
                      node.data.label.trim().replace(/ /g, '_') + '_FLAGS'
                    ].map((f, i) => (
                      <span
                        key={i}
                        className={
                          'tab-tag tag-bg-' +
                          f.replace(/ /gi, '-').toLowerCase()
                        }
                      >
                        ⚑
                      </span>
                    ))}
                </button>
              </li>
            ))}
          </ul>
          <div className="card border-top-0 card-body shadow-sm">
            {nodes
              .filter(
                (node, ind) =>
                  node.data.subType === 'dnode_form' &&
                  (node?.data.label.split('(')[0].trim().replace(/ /g, '_') ===
                    currentForm ||
                    (!currentForm && ind == 0))
              )
              .map((node, ind) => (
                <div
                  key={ind}
                  style={{
                    display:
                      node?.data.label
                        .split('(')[0]
                        .trim()
                        .replace(/ /g, '_') === currentForm ||
                      (!currentForm && ind == 0)
                        ? 'block'
                        : 'none',
                  }}
                >
                  {renderForm(node, runData, variables)}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FlowRun;
