import Request from './request';

export const apiGetDocuments = async (data) => {
  return Request.call({
    url: `/api/repository`,
    method: 'POST',
    data,
  });
};

export const apiGetDocument = async (data) => {
  return Request.call({
    url: `/api/repository/${data._id}`,
    method: 'POST',
    data,
  });
};

export const apiPostDocument = async (data) => {
  return Request.call({
    url: `/api/document`,
    method: 'POST',
    data,
  });
};
