import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/execution';

const initialState = {
  allExecutions: {
    ...defaultObj,
  },
  executions: {
    ...defaultObj,
  },
  executionDetails: {
    ...defaultObj,
  },
  createExecution: {
    ...defaultObj,
  },
  updateExecution: {
    ...defaultObj,
  },
  deleteExecution: {
    ...defaultObj,
  },
};

const ExecutionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_EXECUTIONS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          executions: {
            ...prevState.executions,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          executions: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          executions: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_EXECUTION_DETAILS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          executionDetails: {
            ...prevState.executionDetails,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          executionDetails: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          executionDetails: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CREATE_EXECUTION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          createExecution: {
            ...prevState.createExecution,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          createExecution: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          createExecution: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_EXECUTION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateExecution: {
            ...prevState.updateExecution,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateExecution: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateExecution: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_EXECUTION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deleteExecution: {
            ...prevState.deleteExecution,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deleteExecution: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deleteExecution: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default ExecutionReducer;
