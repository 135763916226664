export const defaultObj = {
  loading: false,
  data: {},
  error: '',
};
export const defaultResourceObj = {
  loading: {},
  data: {},
  error: {},
};
