/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */
// context/todoContext.tsx
import moment from 'moment';
import * as React from 'react';
// import {
//   createEvent,
//   fetchEvents,
//   deleteEvent,
//   updateEvent
// } from "../utils/api";
import { useActions } from 'src/components/Adjenda/selectorData.js';
import { useReducerData } from 'src/store/hooks';

export const EventContext = React.createContext();

const reformatItems = (events) => {
  return events.map(function (event) {
    var newObj = { ...event };
    newObj['_id'] = event._id;
    newObj['status'] = event.status;
    newObj['title'] = event.title || event.workflow.information.taskTitle;
    newObj['description'] =
      event.description || event.workflow?.information.taskDescription;
    newObj['hours'] = [1]; // event.hours;
    newObj['start'] = new Date(Date.parse(event.startDate)); // .toISOString().slice(0, 16);
    newObj['end'] = new Date(Date.parse(event.startDate)); // .toISOString().slice(0, 16);
    newObj['flags'] = getTaskFlags(event);
    newObj['style'] = getStyle(newObj);
    newObj['prompt'] = getPrompt(newObj);
    newObj['bgColor'] = getCalendarColor(newObj);
    return newObj;
  });
};

const getTaskFlags = (task) => {
  // TODO: move to util to be accessed
  // by BE and FE
  if (!task.flags) task.flags = [];
  const now = new Date().setHours(0, 0, 0, 0);
  if (
    new Date(task.dueDate) < now &&
    task.status != 'Completed' &&
    task.flags.indexOf('overdue') == -1
  ) {
    task.flags.push('overdue');
  }
  if (new Date(task.publishDate) > now && task.flags.indexOf('hidden') == -1)
    task.flags.push('hidden');
  if (
    new Date(task.startDate) < now &&
    ['Created', 'Queued'].includes(task.status) &&
    task.flags.indexOf('late to start') == -1
  )
    task.flags.push('late to start');
  if (
    ['In Progress', 'Started'].includes(task.status) &&
    task.flags.indexOf('in progress') == -1
  )
    task.flags.push('in progress');
  if (task.status == 'Completed' && task.flags.indexOf('completed') == -1)
    task.flags.push('completed');
  return task.flags;
};

const getCalendarColor = (task) => {
  const disabled = moment(task.allowedStartDate) >= new Date();
  if (disabled) return '#aaa';
  if (task.flags.indexOf('overdue') > -1) return '#ff0000';
  if (task.flags.indexOf('late to start') > -1) return '#ff0000';
  if (task.flags.indexOf('completed') > -1) return '#55aa55';

  switch (task.status) {
    case 'Queued':
      return '#ff7f50';
    case 'Started':
    case 'In Progress':
      return '#55aa55';
    case 'Completed':
      return '#00ff00';
    default:
      return '#ff7f50';
  }
};

const getStyle = (task) => {
  const base = { borderRadius: '13px 0 0 13px' };
  if (task.flags.indexOf('overdue') > -1)
    return { ...base, backgroundColor: '#ff000040' };
  if (task.flags.indexOf('late to start') > -1)
    return { ...base, backgroundColor: '#ff000020' };
  if (task.flags.indexOf('completed') > -1)
    return { ...base, backgroundColor: '#00ff0040' };
  return {};
};
const getPrompt = (task) => {
  const base = {
    borderRadius: '7px',
    padding: '5px',
    marginBottom: '10px',
    fontWeight: 'bold',
    textAlign: 'center',
  };
  if (task.flags.indexOf('completed') > -1)
    return (
      <div style={{ ...base, backgroundColor: '#00ff0040' }}>Completed</div>
    );
  if (task.flags.indexOf('overdue') > -1)
    return <div style={{ ...base, backgroundColor: '#ff000040' }}>Overdue</div>;
  if (task.flags.indexOf('late to start') > -1)
    return (
      <div style={{ ...base, backgroundColor: '#ff000020' }}>Late to start</div>
    );
  if (isDueInDays(task.dueDate, 0))
    return (
      <div style={{ ...base, backgroundColor: '#ff000040' }}>Due Today</div>
    );
  if (isDueInDays(task.dueDate, 1))
    return (
      <div style={{ ...base, backgroundColor: '#ff000040' }}>Due Tomorrow</div>
    );
  if (isDueInDays(task.dueDate, 2))
    return (
      <div style={{ ...base, backgroundColor: '#dd000040' }}>Due in 2 Days</div>
    );
  if (isDueInDays(task.dueDate, 3))
    return (
      <div style={{ ...base, backgroundColor: '#bb000040' }}>Due in 2 Days</div>
    );
  return undefined;
};

function isDueInDays(dateString, days) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today's date to 00:00:00

  const targetDate = new Date(today);
  targetDate.setDate(targetDate.getDate() + days); // Add specified number of days

  const inputDate = new Date(dateString);
  inputDate.setHours(0, 0, 0, 0); // Ignore time part of input date

  return inputDate.getTime() === targetDate.getTime();
}
const fixDatesAsIso = (event) => {
  const starter = new Date(Date.parse(event.start));
  starter.setMinutes(starter.getMinutes() - starter.getTimezoneOffset());
  const ender = new Date(Date.parse(event.end));
  ender.setMinutes(ender.getMinutes() - ender.getTimezoneOffset());

  return {
    start: starter.toISOString().slice(0, 16),
    end: ender.toISOString().slice(0, 16),
  };
};

const fixDatesAsTimestamps = (event) => {
  const starter = new Date(Date.parse(event.start));
  starter.setMinutes(starter.getMinutes() - starter.getTimezoneOffset());
  const ender = new Date(Date.parse(event.end));
  ender.setMinutes(ender.getMinutes() - ender.getTimezoneOffset());

  return {
    start: Date.parse(starter),
    end: Date.parse(ender),
  };
};

const EventProvider = ({ children }) => {
  const [events, setEvents] = React.useState([]);
  const [selectedEvent, setSelectedEvent] = React.useState({
    _id: '',
    title: '',
    description: '',
    start: null,
    end: null,
    bgColor: '',
  });
  const [open, setOpen] = React.useState(false);
  const [requestPayload, setRequestPayload] = React.useState({
    mode: 'attention',
    view: 'month',
    startDate: moment(new Date()).startOf('month').toDate(),
  });
  const [formType, setFormType] = React.useState('');

  const userData = useReducerData('auth', 'login.data', []);
  const { getTasks, startTask, createTask, deleteTask, updateTask } =
    useActions();

  const handleClickOpen = (event = null) => {
    try {
      setOpen(true);
      if (event === null) {
        // Create New Event
        setFormType('add');
      } else if (
        event.hasOwnProperty('start') &&
        event.hasOwnProperty('end') &&
        !event.hasOwnProperty('title') &&
        !event.hasOwnProperty('description')
      ) {
        // Timeslot Select
        setFormType('add');
        const stamps = fixDatesAsIso(event);
        setSelectedEvent(stamps);
        console.log('timeslot', stamps);
      } else {
        // Select Existing
        const stamps = fixDatesAsTimestamps(event);
        setSelectedEvent({ ...stamps, ...event });
        console.log('selectExisting', selectedEvent);
        setFormType('show');
      }
    } catch (e) {
      alert('handleClickOpen: ', e);
    }
  };

  const handleClose = () => {
    setFormType('');
    setSelectedEvent(null);
    setOpen(false);
  };

  const init = async (payload) => {
    try {
      if (!payload) {
        payload = requestPayload;
      } else {
        setRequestPayload(payload);
      }
      getTasks({ userId: userData.id, ...payload }, (res) => {
        const events = res.result || [];
        const newItems = reformatItems(events);
        console.log('init: ', newItems);
        setEvents(newItems);
      });
    } catch (e) {
      alert(e);
    }
  };

  React.useEffect(() => {
    // init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveEvent = async (event) => {
    const payload = { ...event };
    const adjustPayload = fixDatesAsTimestamps(payload);
    const newEvent = await createTask({ ...adjustPayload, ...payload });
    if (newEvent.isSuccess) {
      const reformatItem = reformatItems([newEvent.result.task]);
      setEvents([
        ...events,
        {
          ...reformatItem[0],
        },
      ]);
      handleClose();
    }
    return newEvent;
  };

  const editEvent = async (data) => {
    const res = await updateTask(data._id || selectedEvent._id, data);

    const reformatItem = fixDatesAsTimestamps(res.result.task);
    const foundEvent = events.filter((event) => {
      if (event._id === data._id) {
        const newObject = { ...reformatItem, ...data };
        newObject.flags = getTaskFlags(newObject);
        const index = events.indexOf(event);
        events.splice(index, 1);
        setEvents([...events, newObject]);
      }
    });
    if (foundEvent) {
      handleClose();
      init();
    }
    return reformatItem;
  };

  const removeEvent = async () => {
    const newEvent = await deleteTask(selectedEvent._id);
    if (newEvent.isSuccess) {
      handleClose();
      init();
    }
    return newEvent;
  };

  return (
    <EventContext.Provider
      value={{
        events,
        open,
        saveEvent,
        editEvent,
        removeEvent,
        handleClickOpen,
        handleClose,
        selectedEvent,
        setSelectedEvent,
        formType,
        setFormType,
        init,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventProvider;

export function useEventContext() {
  return React.useContext(EventContext);
}
