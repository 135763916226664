import cn from 'classnames';
import classes from './Field.module.scss';

const Field = ({ className, label, value }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

export default Field;
